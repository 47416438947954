import React from 'react'

function patientHPI() {
  return (
    <>
    <h1>
        HPI
    </h1>
    </>
  )
}

export default patientHPI