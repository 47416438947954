 import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

function CancelConfirmation({openCancel , closeCancel , Routes }) {

  
    


    const navigate = useNavigate();

    const  handleCloseCancel =() => {
        closeCancel()
    }

    const handleCancel =(e)=> {
      closeCancel()
      navigate(e)
    }



  return (

    <Modal show={openCancel} onHide={handleCloseCancel}  size='lg' centered>
    <Modal.Header className='fs-2 fw-bold px-4 py-1 dialoge' >
     
    Cancel Confirmation
    
    </Modal.Header>
    <Modal.Body className='fs-4 px-3 py-1 fw-bold dialoge' >
      <div className='card p-4 '>
      Are you sure you want to cancel this ? 
      </div>
    </Modal.Body>

    <Modal.Footer className='p-2 dialoge'>
      <div className='d-flex gap-2 h-40px '>

      <button onClick={() => handleCancel(Routes) }  className='bigBluePrimaryButton' >
      Yes
      </button>
      <button onClick={handleCloseCancel} className='biggrayButton' >
      Cancel
      </button>
      </div>
    </Modal.Footer>
  </Modal>
  )
}

export default CancelConfirmation