import { useEffect, useState } from 'react';
import Select from 'react-select'



function SerchableDropdown(props) {

  const [data, setData] = useState(null); // Initialize data state to null




  useEffect(() => {
    // Set the initial value when props.InitialValue changes
    if (props.InitialValue !== undefined && props.InitialValue !== null && data === null) {

      console.log();

      setData(props.InitialValue);
    }
  }, [props.InitialValue, data]);










  const elsepart = []

  const option = props.options ? props.options.map((i) => {
    return {
      value: i.value,
      label: <span style={{ textOverflow: 'ellipsis', overflow: "hidden" }} title={i.label}>{i.label}</span>,
      id: i.id
    }
  }) : elsepart


  useEffect(() => {

    // Check if there's only one option and set it as default value

    if (option.length === 1) {
      setData(option[0].id)
      props.gettingData(option[0].id);
    }

  }, [option, props]);


  function handelChange(e) {
    setData(e.id)
    props.gettingData(e.id)
  }



  return (

    <div className='w-100'>
      {
        props.label &&
        <label
          className={props.required ? 'patient-info-title required ' : 'patient-info-title'}
          htmlFor="">{props.label}</label>

      }
      <Select
        className=''
        options={option}
        placeholder={props.placeHolder}
        maxMenuHeight={150}
        onChange={handelChange}
        isDisabled={props.disabled ? true : false}
        value={data && props.InitialValue ? (props.options && props.options.filter((option) => option.id == data)) : null}

        styles={{
          control: (provided, state) => ({
            ...provided,
            borderRadius: "5px",
            background: '#fff',
            borderColor: '#dbdfe9',
            minHeight: '30px',
            height: '30px',
            fontWeight: "500",
            color: "#99a1b7 !important",
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
          }),

          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
          option: (provided, state) => ({
            ...provided,

            whiteSpace: 'nowrap', // Prevents wrapping of text
            overflow: 'hidden', // Hides overflow text
            textOverflow: 'ellipsis', // Adds ellipsis when text overflows
            maxWidth: '100%', // Ensures option doesn't exceed container width
            fontSize: '12px',
            padding: '1px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            color: 'black',
            fontWeight: '600',
            paddingLeft: '4px',
          })
        }}

      />
    </div>
  )
}

export default SerchableDropdown
