import React, { useState } from 'react'
import AppointmentPageHeader from '../ReusablePages/ReusableAppointment/AppointmentPageHeader'
import PatientIdCard from '../ReusablePages/ReusableAppointment/PatientIdCard'
// import Allergies from '../EHRIcon/AllergiesWhiteIcon.png'

import DownArrow from '../EHRIcon/DownArrow.png'
import UpArrow from '../EHRIcon/UpArrow.png'


function PatientAllergies() {

    const [allergiesDown, setAllergiesDown] = useState(false)
    

    return (
        <div>
            <AppointmentPageHeader name="Allergies" />
            <PatientIdCard />
            <div>

                <div className='card mt-2  '>
                    <div className={allergiesDown ? "PatientChartCardHeader " : "PatientChartCardHeadercondition"}>
                        <div className='d-flex'>

                            <div >
                                {/* <img src={Allergies} alt="" className='PatientChartCardHeaderIcon' /> */}
                            </div>
                            <div>
                                <span>Allergies</span>
                            </div>
                        </div>
                        <div>
                            <div className='PatientChartCardHeaderIcon'
                                onClick={() => setAllergiesDown(!allergiesDown)}
                            >
                                {
                                    allergiesDown ?
                                        <img src={UpArrow} alt="" /> :
                                        <img src={DownArrow} alt="" />
                                }
                            </div>
                        </div>
                    </div>
                    {
                        allergiesDown &&
                        <div>

                            <div className='  row align-items-center  p-2  w-100 ' >
                                <div className='col-md-1' >
                                    <span className='form-label d-flex'>Allergies Notes:</span>
                                </div>
                                <div className='col-md-10' >
                                    <input type="text" id='AllergiesNotes' className='xlargeInput100 form-control' />
                                </div>
                                <div className='col-md-1 d-flex justify-content-end'>

                                    <button className='btn btn-primary btn-sm fw-bold h-31px '>Save</button>
                                </div>
                            </div>

                            <div className='d-flex align-items-center vitalsCard1st p-2 ' >
                                <div className='d-flex gap-1'>

                                    <div>
                                        <button className='switchViewbtnLeft  switchViewbtnActive'>
                                            Active
                                        </button>
                                        <button className='switchViewbtnRight  '>
                                            Inactive

                                        </button>
                                    </div>

                                    <div>
                                        <button type="button" className='blueButton'>
                                            Review
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex gap-1'>
                                    <div className='form-check form-check-custom  form-check-sm'>
                                        <input type="checkbox" className='mx-1 form-check-input' />
                                        <label className='form-check-label mx-1 ' style={{ color: "black", fontWeight: '600' }} htmlFor="">no known allergies</label>
                                    </div>
                                    <div>
                                        <span>
                                            icon
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            icon
                                        </span>
                                    </div>
                                    <div>
                                        <button type="button" className='blueButton'>
                                            <span >
                                            <i className="fa-solid fa-plus " ></i>
                                                Add Allergies
                                            </span>
                                        </button>
                                    </div>

                                </div>



                            </div>

                            <div className=''>
                                <table className='w-100'>
                                    <thead>
                                        <tr className='tableHead'>

                                            <th>
                                                Reported on
                                            </th>
                                            <th>
                                                Allergen
                                            </th>
                                            <th>
                                                Reaction
                                            </th>
                                            <th>
                                                Severity
                                            </th>
                                            <th>
                                                Allergy Type
                                            </th>
                                            <th>
                                                Allergy / Intolerance
                                            </th>
                                            <th>
                                                Added By
                                            </th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {/* <tr className='tableBody'>
                                        <td>
                                            
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr> */}
                                    </tbody>
                                </table>
                            </div>

                            <div className='PatientChartCardFooter'>
                                <div>
                                    <span className='form-label p-2'>
                                        No Record(s) found.
                                    </span>
                                </div>

                                <div>
                                    pagination ...
                                </div>
                            </div>
                        </div>
                    }

                </div>
             
            </div>
        </div>
    )
}

export default PatientAllergies
