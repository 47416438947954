import React from 'react'
import { EmailRegExp, SSNRegExp, ZipRegEx, extNoRegExp, nameRegEx, phoneNoRegexp } from '../../../ReUsableComponent/regEx';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'

function UserValidation(value: any): { [key: string]: string } {


    const errors: { [key: string]: string } = {};


    if (!value.lastName || value.lastName.trim() === '') {
        errors.lastName = inputText.lastName.ErrMsg;
      }
      // else{
      //   // if(!nameRegEx.test(value.lastName)){
      //   //     errors.lastName = 'Please enter valid last name';
      //   // }
      //   // else
      //    if(value.lastName.length >= 51){
      //       errors.lastName = 'Please enter last Name less then or equal to 10 characters'
      //   }
       
      // }
    
        
    //   if (value.lastName && value.lastName.length >= 51) {
    //     errors.lastName = 'Please enter a last name less than or equal to 50 characters';
    //   }
    

    // if(value.lastName && !nameRegEx.test(value.lastName)){
    //     errors.lastName = 'Please enter valid last name';

    // }
      




          if (!value.firstName || value.firstName.trim() === '') {
            errors.firstName = inputText.firstName.ErrMsg ;
          }
          else{
            // if(!nameRegEx.test(value.firstName)){
            //     errors.firstName = 'Please enter valid first name';
            // }
            // else 
            // if(value.firstName.length >= 51){
            //     errors.firstName = 'Please enter first Name less then or equal to 50 characters'
            // }
          }
        


          // if (value.middleName && value.middleName.length >= 51) {
          //   errors.middleName = 'Please enter a middle name less than or equal to 50 characters';
          // }
        
    
        // if(value.middleName && !nameRegEx.test(value.middleName)){
        //     errors.middleName = 'Please enter valid middle name';
    
        // }


        
          
        
    
        
         
        
          
        
          if (!value.addressLine1 || value.addressLine1.trim() === '') {
            errors.addressLine1 = inputText.Address1.ErrMsg ;
          }
          // else{
          // if(value.addressLine1.length >= 101){
          //       errors.addressLine1 = 'Please enter address line 1 less then or equal to 100 characters'
          //   }
          // }
        

          // if (value.addressLine2 && value.addressLine2.length >= 101) {
          //   errors.addressLine2 = 'Please enter address line 2 less than or equal to 100 characters';
          // }
        
        
          if (!value.countryId) {
            errors.countryId = inputText.country.ErrMsg ;
        }
          if (!value.stateId) {
            errors.stateId = inputText.state.ErrMsg ;
        }
          if (!value.cityId) {
            errors.cityId = inputText.city.ErrMsg ; 
        }
        
        
        
          
        if(!value.zipcodeId ){
            errors.zipcodeId = inputText.zip.ErrMsg ;
          }
          else if(value.zipcodeId.length <= 4){
            errors.zipcodeId = inputText.zip.validErrMsg ;
            }
          
          
        if(!value.npiId ){
            errors.npiId = inputText.NpiID.ErrMsg ;
          }
          else if(value.npiId.length < 10){
            errors.npiId = inputText.NpiID.validErrMsg ;
            }
          
        
    
    
    
          
        if(!value.email || value.email.trim() === '' ){
            errors.email = inputText.email.ErrMsg ;
          }
          else{
            if(!EmailRegExp.test(value.email)){
            errors.email = inputText.email.validErrMsg ;
            }
            // else if(value.email.length >= 251){
            //     errors.email = 'Please enter email less than or equal to 250 characters';
            // }
          }
        
        
        
        
        
        if(!value.contactNumber1 ){
            errors.contactNumber1 = inputText.cellNo.ErrMsg ;
          }
          else{
            if(!phoneNoRegexp.test(`${value.countryCode1}${value.contactNumber1}`)){
            errors.contactNumber1 = inputText.cellNo.validErrMsg ;
            }
           
          }
        
        
        
        
          if (value.contactNumber2 && !phoneNoRegexp.test(`${value.countryCode2}${value.contactNumber2}`)) {
            errors.contactNumber2 = inputText.WorkPhone.validErrMsg ;
            }
           
        //   if (value.workPhoneNo && !phoneNoRegexp.test(`${value.countryCode2}${value.contactNumber2}`)) {
        //     errors.contactNumber2 = 'Please enter valid work phone ';
        //     }
       
          
          // if (value.extID && value.extID <= 2) {
          //   errors.extID = inputText.ex.ErrMsg ;
          //   }

            
          if (!value.clinicId) {
            errors.clinicId = inputText.ClinicName.ErrMsg
        }
 
          if (!value.designationId) {
            errors.designationId = inputText.designation.ErrMsg
        }
        
          if (!value.roleId) {
            errors.roleId = inputText.role.ErrMsg
        }

        const maxSizeInBytes = 5 * 1024 * 1024;

        if (value.userProfilePicDetails) {
          if (value.userProfilePicDetails.size > maxSizeInBytes) {
            errors.profilePicErr = "Please upload profile picture less than or equal to 5 mb"
          }
          else {
              const allowedExtensions = ['.jpeg','.jpg','.png'];
              const fileExtension = value.userProfilePicDetails.name.toLowerCase().slice(value.userProfilePicDetails.name.lastIndexOf('.'));
              console.log(!allowedExtensions.includes(fileExtension));
              
              if (!allowedExtensions.includes(fileExtension)) {
                errors.profilePicErr = 'Please upload a valid profile picture type (.jpeg, .jpg, or .png)';
              }
          }
      }
        

    return errors;
    
 
}

export default UserValidation