import React, {useEffect, useState} from 'react'

import CryptoJS from 'crypto-js'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link, useLocation, useParams} from 'react-router-dom'
import {Dropdown1} from '../../../_metronic/partials'
import PatientInformation from './PatientInformation'
import PatientAdditionalInfo from './PatientAdditionalInfo'
import PatientDocuments from './PatientDocuments'
import PatientAppointmentDetails from './PatientAppointmentDetails'
import axios from 'axios'
import PatientInsurance from './PatientInsurance'
import stickyNotes from '../EHRIcon/StickyNotes.svg'
import PatientTabHistory from './PatientTabHistory'
import PatientTabFinancials from './PatientTabFinancials'

function PatientDetails() {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''

  const {tab, id} = useParams()

  const [patientdata, setPatientdata] = useState<any>()

  const [selectedTab, setSelectedTab] = useState('patientInformation')

  const [changedData, setChangesData] = useState(false)

  useEffect(() => {
    const getallPatientList = async (id, tab): Promise<void> => {
      try {
        setSelectedTab(tab)
        const getloginInfo: string | null = localStorage.getItem('appLoginInfo')

        if (getloginInfo !== null) {
          const Token = JSON.parse(getloginInfo).data.accessToken
          const header = {
            headers: {'x-access-token': Token},
          }

          const response = await axios.get(`${APIkey}/api/admin/getPatientById/${id}`, header)

          setPatientdata(response.data.data)
        } else {
          console.log('No login info found in localStorage')
        }
      } catch (err) {
        console.log(err)
      }
    }

    getallPatientList(id, tab)
  }, [changedData])

  const firstChangeTab = (tabData) => {
    setSelectedTab(tabData)
  }
  const secondChangeTab = (tabData) => {
    setSelectedTab(tabData)
  }

  const decrypted = (value) =>
    value ? CryptoJS.AES.decrypt(value, 'reyEhr').toString(CryptoJS.enc.Utf8) : ''

  console.log(patientdata?.homeNo)

  return (
    <>
      <div id='kt_app_toolbar_container' className='app-container d-flex  container-fluid '>
        <div className='title-details-page' id='header'>
          <span className=' title-header'>Patient Demographic</span>
          {/* <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0'>
            <li className='breadcrumb-item text-muted'>
              <a className='text-muted text-hover-primary' href='/dashboard'>
                Patient Detail
              </a>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-dark'>View Details </li>
          </ul> */}
        </div>
      </div>

      <div className='card mb-2' id='patientIDcard'>
        <div className='px-2 pt-3 pb-0'>
          <div className='row  '>
            <div className='col-md-6 col-lg-3 d-flex gap-2 '>
              <div>
                <img
                  src={toAbsoluteUrl('/media/avatars/300-1.jpg')}
                  alt=''
                  className='rounded h-80px'
                />
              </div>

              <div className=' mt-2'>
                <div className='d-flex gap-2 align-items-center mb-4  '>
                  <span
                    className=' title-header elipsisText'
                    title={`${patientdata?.firstName} ${patientdata?.lastName}`}
                  >
                    {patientdata?.firstName} {patientdata?.lastName}
                  </span>

                  <span>
                    <img src={stickyNotes} alt='Sticky Notes' title='Sticky Notes' />
                  </span>
                </div>
                <div>
                  <span className=' patientIDText'>MRN:</span>
                  <span className='patientIDText elipsisText' title={patientdata?.mrn}>
                    {patientdata?.mrn}
                  </span>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-3 '>
              <div className='d-flex mt-2 gap-2 align-items-center mb-4 mx-2'>
                <span
                  className='patientIDText elipsisText'
                  title={`${patientdata?.mobileCountryCode} ${decrypted(patientdata?.mobileNo)}`}
                >
                  <i className='fa fa-phone' aria-hidden='true'></i> &nbsp;
                  {patientdata?.mobileCountryCode} {decrypted(patientdata?.mobileNo)}
                </span>
                <span
                  className='mx-1 patientIDText elipsisText'
                  title={decrypted(patientdata?.emailAddress)}
                >
                  <i className='fa fa-envelope' aria-hidden='true'></i> &nbsp;
                  {decrypted(patientdata?.emailAddress)}
                </span>
              </div>
              <div className='d-flex gap-3 align-items-center text-gray-500 fs-5 fw-bold mx-2'>
                {decrypted(patientdata?.homeNo).length !== 0 && (
                  <span
                    className='elipsisText patientIDText'
                    title={`${patientdata?.homeCountryCode} ${decrypted(patientdata?.homeNo)}`}
                  >
                    <i className='fa fa-phone' aria-hidden='true'></i> &nbsp;
                    {patientdata?.homeCountryCode} {decrypted(patientdata?.homeNo)}
                  </span>
                )}
                <span
                  className='elipsisText patientIDText'
                  title={` ${patientdata?.addressLine1} ${patientdata?.addressLine2}`}
                >
                  <i className='fa fa-map-marker' aria-hidden='true'></i> &nbsp;
                  {patientdata?.addressLine1}
                  {patientdata?.addressLine2 ? ',' : ''} {patientdata?.addressLine2}
                </span>
              </div>
              <div></div>
            </div>
            <div className='col-md-6 col-lg-2'>
              <div className='d-flex mt-2'>
                <span className='d-flex  align-items-center  mb-4 mx-1 patientIDText'>
                  Insurance co.:
                </span>
                <span
                  className='d-flex  align-items-center  mb-4 mx-1 elipsisText patientIDText'
                  title='ABC Health Insurance'
                >
                  {/* {patientdata?.PatientInsuranceId} */}
                  ABC Health Insurance
                </span>
              </div>
              <div className='d-flex'>
                <span className='d-flex  align-items-center patientIDText mb-4 mx-1'>
                  Group No:
                </span>
                <span
                  className='d-flex  align-items-center patientIDText mb-4 mx-1 elipsisText'
                  title=' ABC Health Insurance'
                >
                  {/* {patientdata?.PatientInsuranceId} */}
                  ABC Health Insurance
                </span>
              </div>
            </div>

            <div className='col-md-6 col-lg-2'>
              <div className='d-flex mt-2'>
                <span className='d-flex  align-items-center patientIDText mb-4 mx-1'>
                  Subscriber ID:
                </span>
                <span
                  className='d-flex  align-items-center  patientIDText mb-4 mx-1 elipsisText'
                  title=''
                >
                  {/* {patientdata?.PatientInsuranceId} */}
                  987654321
                </span>
              </div>
              <div className='d-flex'>
                <span className='d-flex  align-items-center patientIDText  mb-4 mx-1'>
                  Deductible:
                </span>
                <span className='d-flex  align-items-center patientIDText mb-4 mx-1 elipsisText'>
                  {/* {patientdata?.PatientInsuranceId} */}
                  $1,000
                </span>
              </div>
            </div>
            <div className='col-md-6 col-lg-2'>
              <div className='d-flex mt-2'>
                <span className='d-flex  align-items-center patientIDText  mb-4 mx-1 '>
                  Plan Name:
                </span>
                <span className='d-flex  align-items-center patientIDText mb-4 mx-1'>
                  {/* {patientdata?.PatientInsuranceId} */}
                  Comprehensive Health
                </span>
              </div>
              <div className='d-flex'>
                <span className='d-flex  align-items-center patientIDText mb-4 mx-1'>Copay:</span>
                <span className='d-flex  align-items-center patientIDText   mb-4 mx-1 elipsisText'>
                  {/* {patientdata?.PatientInsuranceId} */}
                  $1,000
                </span>
              </div>
            </div>
            <div className='col-md-6 col-lg-2 mt-2 '>
              <div className='d-flex   '>
                <div>
                  <label className='patientIDText mb-4 ' htmlFor='flexCheckDefault'>
                    Eligibility Activation:
                  </label>
                </div>
                <div>
                  <input className='' type='checkbox' value='' id='flexCheckDefault' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='d-flex h-40px mx-2'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap custom-nav'>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'patientInformation' && 'active'
                  }`}
                  onClick={() => setSelectedTab('patientInformation')}
                >
                  Patient Information
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'additionalInformation' && 'active'
                  }`}
                  onClick={() => setSelectedTab('additionalInformation')}
                >
                  Additional Information
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'insurance' && 'active'
                  }`}
                  onClick={() => setSelectedTab('insurance')}
                >
                  Insurance
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'history' && 'active'
                  }`}
                  onClick={() => setSelectedTab('history')}
                >
                  History
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'financials' && 'active'
                  }`}
                  onClick={() => setSelectedTab('financials')}
                >
                  Financials
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'documents' && 'active'
                  }`}
                  onClick={() => setSelectedTab('documents')}
                >
                  Documents
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'appDetails' && 'active'
                  }`}
                  onClick={() => setSelectedTab('appDetails')}
                >
                  Appointment Details
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`patientIDText nav-link text-active-primary me-6 ${
                    selectedTab === 'employerSchoolDetails' && 'active'
                  }`}
                  onClick={() => setSelectedTab('employerSchoolDetails')}
                >
                  Employer / School Detail
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {selectedTab === 'patientInformation' && (
        <>
          <PatientInformation
            changeTab={firstChangeTab}
            nextTab='additionalInformation'
            changedData={() => setChangesData(!changedData)}
          />
        </>
      )}

      {selectedTab === 'additionalInformation' && (
        <>
          <PatientAdditionalInfo changeTab={secondChangeTab} nextTab='documents' />
          {/* <div className='card mb-5 mb-xl-12'>
           <div className='card-body pt-9 pb-0 mb-5' style={{borderBottom: '1px solid lightgrey'}}>
             <h2>Alternate Address</h2>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address type:</label>
               <select
               className='form-select'
               data-control='select2'
               data-placeholder='Select an option'
             >
               <option value=''>Please Select</option>
               <option value='1'>Option1</option>
               <option value='2'>Option2</option>
               <option value='3'>Option3</option>
             </select>
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 1:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 2:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>City:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>State:</label>
               <select
               className='form-select'
               data-control='select2'
               data-placeholder='Select an option'
             >
               <option value=''>Please Select</option>
               <option value='1'>Option1</option>
               <option value='2'>Option2</option>
               <option value='3'>Option3</option>
             </select>
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Country:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Zip:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
            
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Cell Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
             <label className='patient-info-title'>Home Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Email:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Relation With Patient:</label>
               <select
               className='form-select'
               data-control='select2'
               data-placeholder='Select an option'
             >
               <option value=''>Please Select</option>
               <option value='1'>Option1</option>
               <option value='2'>Option2</option>
              
             </select>
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Preferred contact Method:</label>
               <select
               className='form-select'
               data-control='select2'
               data-placeholder='Select an option'
             >
               <option value=''>Please Select</option>
               <option value='1'>Option1</option>
               <option value='2'>Option2</option>
              
             </select>
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
             <label className='patient-info-title'>Notes:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

            
             
             
           </div>

           <div className='row col-md-12' style={{marginLeft:"2px"}}>
              <div className='col-md-2' style={{width: '28%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>I permit the healthcare facility to bill at the alternate address</label>
                     
                      
                    </div>
              </div>

              <div className='col-md-2' style={{width: '72%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>I understand that the alternative address might be utilized in case of an emergency</label>
                     
                      
                    </div>
              </div>
              
              
            </div>

            


           <div className='card-body pt-4 pb-0 mb-5' style={{borderBottom: '1px solid lightgrey'}}>
             <h2>Next Of Kin</h2>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Last Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>First Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Middle Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Relation With Patient:</label>
               <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Insurance Name'
                     >
                      <option value=''>Please Select</option>
                      <option value='1'>Option1</option>
                      <option value='2'>Option2</option>
                      
                    </select>
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Cell Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title required'>Home Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Email:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 1:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 2:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>City:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>State:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Country:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Zip:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Preferred contact Method:</label>
               <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Preferred contact Method'
                     >
                      <option value=''>Please Select</option>
                      <option value='1'>Option1</option>
                      <option value='2'>Option2</option>
                      
                    </select>
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Notes:</label>
               <input type='text' className='form-control textbox-height' placeholder='comment' />
             </div>

           
             
           </div>

           <div className='row col-md-12' style={{marginLeft:"2px"}}>
              <div className='col-md-2' style={{width: '28%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>I acknowledge that I have provided Next of Kin information</label>
                     
                      
                    </div>
              </div>

              <div className='col-md-2' style={{width: '72%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>I certify that the above information is accurate</label>
                     
                      
                    </div>
              </div>
              
              
            </div>


            <div className='card-body pt-4 pb-0 mb-5' style={{borderBottom: '1px solid lightgrey'}}>
             <h2>Grantor</h2>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Last Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>First Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Middle Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Relation With Patient:</label>
               <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Insurance Name'
                     >
                      <option value=''>Please Select</option>
                      <option value='1'>Option1</option>
                      <option value='2'>Option2</option>
                      
                    </select>
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Cell Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title required'>Home Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Email:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 1:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 2:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>City:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>State:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Country:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Zip:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Preferred contact Method:</label>
               <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Preferred contact Method'
                     >
                      <option value=''>Please Select</option>
                      <option value='1'>Option1</option>
                      <option value='2'>Option2</option>
                      
                    </select>
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Notes:</label>
               <input type='text' className='form-control textbox-height' placeholder='comment' />
             </div>

           
             
           </div>

           <div className='row col-md-12' style={{marginLeft:"2px"}}>
              <div className='col-md-2' style={{width: '33.33%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>I give authority to grantor to make healthcare decision</label>
                     
                      
                    </div>
              </div>

              <div className='col-md-2' style={{width: '33.33%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>I give allowance to access the medical records</label>
                     
                      
                    </div>
              </div>

              <div className='col-md-2' style={{width: '33.33%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>If applicable, specify any financial Powers granted to grantor </label>
                     
                      
                    </div>
              </div>
              
              
            </div>



            <div className='card-body pt-4 pb-0 mb-5' style={{borderBottom: '1px solid lightgrey'}}>
             <h2>Emergency Contact</h2>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Last Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>First Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Middle Name:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Relation With Patient:</label>
               <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Insurance Name'
                     >
                      <option value=''>Please Select</option>
                      <option value='1'>Option1</option>
                      <option value='2'>Option2</option>
                      
                    </select>
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Cell Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title required'>Home Phone:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Email:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 1:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Address 2:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>City:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>State:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Country:</label>
               <input type='text' className='form-control textbox-height' />
             </div>
             
           </div>

           <div className='row col-md-12 mb-4' style={{marginLeft:"2px"}}>
             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Zip:</label>
               <input type='text' className='form-control textbox-height' />
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Preferred contact Method:</label>
               <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Preferred contact Method'
                     >
                      <option value=''>Please Select</option>
                      <option value='1'>Option1</option>
                      <option value='2'>Option2</option>
                      
                    </select>
             </div>

             <div className='col-md-2' style={{width: '24%'}}>
               <label className='patient-info-title'>Notes:</label>
               <input type='text' className='form-control textbox-height' placeholder='comment' />
             </div>

           
             
           </div>

           <div className='row col-md-12' style={{marginLeft:"2px"}}>
              <div className='col-md-2' style={{width: '33.33%'}}>
              <div className='form-check'>
              <input
                        className='form-check-input mb-6'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                        checked
                      /> &nbsp; &nbsp;
                      <label className='form-check-label1 patient-info-title'>In the event of a medical emergency, call the authorized emergency contact</label>
                     
                      
                    </div>
              </div>

             

              
              
              
            </div>


            
            <div className='row col-md-12 mb-6'>
            <div className='col-md-2' style={{width: '100%'}}>
              <input
                type='button'
                className='btn btn-primary'
                value='Save'
                style={{float: 'right'}}
              ></input>
            </div>
          </div>
          

           
         </div> */}
        </>
      )}

      {selectedTab === 'documents' && <PatientDocuments patientData={patientdata} />}
      {selectedTab === 'appDetails' && <PatientAppointmentDetails patientData={patientdata} />}
      {selectedTab === 'insurance' && <PatientInsurance />}
      {selectedTab === 'history' && <PatientTabHistory />}
      {selectedTab === 'financials' && <PatientTabFinancials />}
    </>
  )
}

export default PatientDetails
