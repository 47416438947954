import { useEffect, useState } from 'react';
import Select from 'react-select'




function SearchableDropdown(props) {



  const [data, setData] = useState(null); // Initialize data state to null


  

  useEffect(() => {
    // Set the initial value when props.InitialValue changes
    if (props.InitialValue !== undefined && props.InitialValue !== null && data === null) {
      setData(props.InitialValue);
    }
  }, [props.InitialValue, data]);


  const elsepart = []

  const option = props.options ? props.options.map((i) => {
    return {
      value: i.value,
      label: <span title={i.label}>{i.label}</span>,
      id : i.id
    }
  }) : elsepart

  
  


  useEffect(() => {

    // Check if there's only one option and set it as default value
    if (option.length === 1 ) {
      setData(option[0].id);
      props.gettingData(option[0].id);
    }
  }, [option, props]);




  function handelChange(e) {


    setData(e.id)
    props.gettingData(e.id)

  
    
  }



  
  

  return (
    <div>
      <Select
        className='h-28px'
        options={option}
        placeholder= 'Select'
        maxMenuHeight={150}
        onChange={handelChange}
       
        value={
          data && props.InitialValue ?
            props.options && props.options.filter(option => option.id === data) :
          data === 0  &&  props.options && props.options.filter(option => option.id === data)
        }

        styles={{

          control: (provided, state) => ({
            ...provided,
            borderRadius:"5px" , 
            background: '#fff',
            borderColor: '#dbdfe9',
            minHeight: '30px',
            height: '30px',
            // fontWeight:"350",
            color: "#99a1b7 !important"

          }),

          valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            paddingLeft: '2px'
          }),

          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
        //   option: (provided, state) => ({
        //     ...provided,
        //     textOverflow:"ellipsis",
        
        //     fontSize: '12px' ,
        //     padding:'1px' ,
        //     height:'30px',// Set font size to 12px for options
        //     display :"flex", 
        //     alignItems: "center",
        //     color :'black',
        //     fontWeight:'600',
        //     paddingLeft:"4px"
        // })
        }
        }
      />
    </div>
  )
}

export default SearchableDropdown
