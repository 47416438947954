import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import patientDemo from '../EHRIcon/patientdemo.svg';
import axios from 'axios';
import AddNewPatient from '../Appointment/AddNewPatient';
import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown';
import GenderComponent from '../../../ReUsableComponent/GenderComponent';
import PaginationCompo from '../../../ReUsableComponent/PaginationCompo';
import CryptoConverter from '../../../ReUsableComponent/CryptoConverter';
import Datepicker from '../../../ReUsableComponent/Datepicker';
import CommonTable from '../../../ReUsableComponent/CommonTable';
import MaskedInput from 'react-text-mask';

interface Patient {
  mrn: string;
  lastName: string;
  firstName: string;
  mobileCountryCode: string;
  mobileNo: string;
  addressLine1: string;
  addressLine2?: string;
  dob: string;
  externalMrn: string;
  ssn: string;
  genderData: {gender: string};
  patientcategoryData: {patientCategory: string};
  _id: string;
}

const PatientSearch: React.FC = (): JSX.Element => {
  const APIkey = process.env.REACT_APP_SERVERAPI || '';
  const navigate = useNavigate();

  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [patientSearchList, setPatientSearchList] = useState<Patient[] | null>(null);
  const [patientCategoryList, setPatientCategoryList] = useState<any[]>([]);
  const [totalsearchPatient, setTotalsearchPatient] = useState<number>(0);
  const [patientInsuranceList, setPatientInsuranceList] = useState<any[]>([]);
  const [totalPatient, setTotalPatient] = useState<number>(0);
  const [showAddNewPatientClickPopup, setShowAddNewPatientClickPopup] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [mrn, setMrn] = useState<string | null>(null);
  const [ssn, setSsn] = useState<string | null>(null);
  const [genderId, setSex] = useState<string | null>(null);
  const [patientFullName, setPatientFullName] = useState<string | null>(null);
  const [mobileNo, setMobileNo] = useState<string | null>(null);
  const [dob, setDob] = useState<string | null>(null);
  const [patientCategory, setPatientCategory] = useState<string | null>(null);
  const [patientInsuranceId, setPatientInsuranceId] = useState<string | null>(null);

  const handleOpenAddPatient = (): void => {
    setShowAddNewPatientClickPopup(true);
  };

  const handleCloseAddNewPatientPopup = (): void => {
    setShowAddNewPatientClickPopup(false);
  };

  const getallPatientList = async (): Promise<void> => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.get(
          `${APIkey}/api/admin/getAllPatients?page=${currentPage}&limit=${perPage}&search=${searchQuery}`,
          header
        );
        setPatientList(response?.data?.data?.patient_list);
        setTotalPatient(response?.data?.data?.pagination?.totalRecords);
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPatientCategoryList = async (): Promise<void> => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const Id = JSON.parse(getloginInfo).data.clinicId;
        const header = {
          headers: {'x-access-token': Token},
        };

        const getPatientCategoryList = await axios.get(
          `${APIkey}/api/admin/getPatientCategoryList/${Id}`,
          header
        );

        setPatientCategoryList(
          getPatientCategoryList.data.data.map((i: any) => ({
            label: i.patientCategory,
            value: i.patientCategory,
            id: i._id,
          }))
        );
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPatientInsuranceList = async (): Promise<void> => {
    try {
      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const patientInsuranceData = await axios.get(
          `${APIkey}/api/admin/getPatientInsurance`,
          header
        );
        setPatientInsuranceList(
          patientInsuranceData.data.data.map((i: any) => ({
            label: i.insurancePlanName,
            value: i.insurancePlanName,
            id: i._id,
          }))
        );
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getallPatientList();
  }, [currentPage, perPage, showAddNewPatientClickPopup, searchQuery]);

  useEffect(() => {
    getPatientCategoryList();
    getPatientInsuranceList();
  }, []);

  // const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
  //   setPerPage(Number(e.target.value));
  //   setCurrentPage(1); // Reset to first page when changing items per page
  // };

  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   setSearchQuery(e.target.value);
  // };

  const getTheSSN = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setSsn(value);
  };

  const submitSearch = async (data: any) => {
    try {
      console.log('Search Data:', data);

      const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

      if (getloginInfo) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: {'x-access-token': Token},
        };

        const response = await axios.post(`${APIkey}/api/admin/searchPatient`, data, header);
        console.log('Search Response:', response.data);
        setPatientSearchList(response?.data?.data?.patient_list);
        setTotalsearchPatient(response?.data?.data?.pagination?.totalRecords);
      } else {
        console.log('No login info found in localStorage');
      }
    } catch (error) {
      console.log('Error submitting search:', error);
    }
  };

  const handleSearch = () => {
    const searchData = {
      mrn,
      ssn,
      genderId,
      patientFullName,
      mobileNo,
      dob,
      patientCategory,
      patientInsuranceId,
    };
    submitSearch(searchData);
  };

  const handleCancel = () => {
    setMrn(null);
    setSsn(null);
    setSex(null);
    setPatientFullName(null);
    setMobileNo(null);
    setDob(null);
    setPatientCategory(null);
    setPatientInsuranceId(null);
    setPatientSearchList(null); // Reset search results
    setSearchQuery(''); // Reset search query
  };

  const Component = ({id}) => {
    const action = () => {
      navigate(`/patient/Details/patientInformation/${id}/null`);
    };
    return (
      <span style={{cursor: 'pointer'}} onClick={action}>
        <img src={patientDemo} alt='' height={'15px'} />
      </span>
    );
  };

  const columns = [
    {key: ['mrn'], header: 'Mrn'},
    {key: ['lastName'], header: 'Last Name'},
    {key: ['firstName'], header: 'First Name'},
    {key: ['mobileNo'], header: 'Mobile No', isEncrypted: true},
    {key: ['addressLine1'], header: 'Address'},
    {key: ['dob'], header: 'DOB', isDate: true},
    {key: ['externalMrn'], header: 'External Mrn'},
    {key: ['ssn'], header: 'Ssn', isEncrypted: true},
    {key: ['genderData', 'gender'], header: 'Sex'},
    {key: ['patientcategoryData', 'patientCategory'], header: 'Patient Category'},
    {key: ['actions'], header: 'Action(s)', isAction: true, ActionComponent: Component},
  ];

  return (
    <>
      {showAddNewPatientClickPopup ? (
        <AddNewPatient closeAddNewPatient={handleCloseAddNewPatientPopup} open={undefined} />
      ) : (
        <>
          <div className='mt-2 pageHeader d-flex justify-content-between'>
            <div>
              <span className='PageHeaderTitle'>Patient</span>
            </div>
            <div>
              <button className='blueButton' onClick={handleOpenAddPatient}>
                Add New Patient
              </button>
            </div>
          </div>
          <div className='card py-3 gap-5'>
            <div className='row w-100 align-items-center px-3 '>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <input
                  type='text'
                  className='form-control SearchInputbox'
                  placeholder='Enter patient name'
                  value={patientFullName || ''}
                  onChange={(e) => setPatientFullName(e.target.value)}
                />
              </div>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  className='form-control SearchInputbox'
                  onChange={getTheSSN}
                  value={ssn || ''}
                  placeholder={'SSN'}
                />
              </div>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <input
                  type='text'
                  className='form-control SearchInputbox'
                  placeholder='Enter mobile no'
                  value={mobileNo || ''}
                  onChange={(e) => setMobileNo(e.target.value)}
                  maxLength={10}
                />
              </div>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <Datepicker date={dob || ''} getTheDate={setDob} placeHolder='Select dob' />
              </div>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <input
                  type='text'
                  className='form-control SearchInputbox'
                  placeholder='Enter mrn'
                  value={mrn || ''}
                  onChange={(e) => setMrn(e.target.value)}
                />
              </div>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <GenderComponent genderValue={genderId || ''} getTheGender={setSex} />
              </div>
            </div>

            <div className='row w-100 align-items-center px-4'>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <SearchableDropdown
                  selectedValue={patientCategory || ''}
                  optionList={patientCategoryList}
                  getSelectedValue={setPatientCategory}
                  placeholder='Select Patient Category'
                />
              </div>
              <div className='col-md-2 col-sm-4 col-6 patientinput '>
                <SearchableDropdown
                  selectedValue={patientInsuranceId || ''}
                  optionList={patientInsuranceList}
                  getSelectedValue={setPatientInsuranceId}
                  placeholder='Select Insurance'
                />
              </div>
              <div className=' col-md-8 col-sm-4 col-12 patientinput d-flex justify-content-end align-items-center gap-2'>
                <div>
                  <button className='blueButton' onClick={handleSearch}>
                    Search
                  </button>
                </div>
                <div className=''>
                  <button onClick={handleCancel} className='grayButton'>
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <CommonTable
              data={
                patientSearchList && patientSearchList.length > 0 ? patientSearchList : patientList
              }
              columns={columns}
              currentPage={currentPage}
              perPage={perPage}
              totalCount={totalsearchPatient || patientList?.length}
              setCurrentPage={setCurrentPage}
              setPerPage={setPerPage}
              searchQuery={searchQuery}
              setQuery={setSearchQuery}
              onRowClick={(id: string) => navigate(`/ehr/patientchart/${CryptoConverter(id)}`)}
              maskedInputClassName='ssn-disabled'
            />
          </div>

          {/* <div className='search-actions d-flex justify-content-between mt-3'>
        <button className='btn btn-primary' onClick={handleSearch}>
              Search
            </button>
            <button className='btn btn-secondary' onClick={handleCancel}>
              Cancel
            </button>

          </div> */}
        </>
      )}
    </>
  );
};

export default PatientSearch;
