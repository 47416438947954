import React from 'react'
import DownLoadIcon from '../../EHRIcon/VitalsdownloadIcon.png'
import Icon2 from '../../EHRIcon/VitalsPageIcon2.png'
import Icon3 from "../../EHRIcon/VitalsPageIcon3.png"
import Icon4 from "../../EHRIcon/VitalsPageIcon4.png"
import Icon5 from "../../EHRIcon/VitalsPageIcon5.png"
import Icon6 from "../../EHRIcon/VitalsPageIcon6.png"
import Icon7 from "../../EHRIcon/VitalsPageIcon7.png"
import Icon8 from "../../EHRIcon/VitalsPageIcon8.png"

function AppointmentPageHeader({name}) {

  return (
    <div>
       <div className='PageHeader' >
        <div>
          <span className='PageHeaderTitle'>
           {name}
          </span>
        </div>

        <div>
          <ul className='PageHeaderList' >
            <li className='cardHeaderElementMargin'>
              <img src={DownLoadIcon} alt="" />
            </li  >
            <li className='cardHeaderElementMargin'>
              <img src={Icon2} alt="" />
            </li>
            <li className='cardHeaderElementMargin' >
              <img src={Icon3} alt="" />

            </li >
            <li className='cardHeaderElementMargin'>
              <img src={Icon4} alt="" />
            </li>
            <li className='cardHeaderElementMargin'>
              <img src={Icon5} alt="" />
            </li>
            <li className='cardHeaderElementMargin'>
              <img src={Icon6} alt="" />
            </li>
            <li className='cardHeaderElementMargin'>
              <img src={Icon7} alt="" />
            </li>
            <li className='cardHeaderElementMargin'>
              <img src={Icon8} alt="" />
            </li>
          </ul>
        </div>
      </div>

      

    </div>
  )
}

export default AppointmentPageHeader
