import React from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import SuperLayout from '../../SuperAdmin/SuperLayout/SuperLayout'
import Dashboard from '../../SuperAdmin/Pages/Dashboard/Dashboard'
import Clinics from '../../SuperAdmin/Pages/Clinics/Clinics'
import User from '../../SuperAdmin/Pages/User/User'
import Billing from '../../SuperAdmin/Pages/Billing/Billing'
import AnalyticalReports from '../../SuperAdmin/Pages/AnalyticalReports/AnalyticalReports'
import AddClinic from '../../SuperAdmin/Pages/Clinics/AddClinic'
import AddUser from '../../SuperAdmin/Pages/User/AddUser'


function SuperPrivateRoutes() {
  return (
    <>
      <Routes> 
        <Route element={<SuperLayout />}>

          {/* <Route path="" element={<Dashboard/>}></Route> */}
          <Route path="/" element={<Dashboard/>} />
          
          {/* <Route path="/clinics" element={<Clinics />} /> */}
            
          
            <Route path='Clinics/AddNew' element={<AddClinic />}></Route>
          {/* <Route path="user" element={<User/>}></Route>
          <Route path='user/addNew' element={<AddUser />}></Route> */}

          <Route path="billing" element={<Billing />}></Route>
          <Route path="analyticalReports" element={<AnalyticalReports/>}></Route>
          

          <Route path='*' element={<Navigate to='AdminError/404' />} />
        </Route>
      </Routes>
    </>
  )
}

export default SuperPrivateRoutes
