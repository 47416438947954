import moment from 'moment';
import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function Datepicker(props) {

    
 
  let selectedDate = (date) => {
    let parsedDate = moment(date);
    
    // Check if the date is valid
    if (parsedDate.isValid()) {
        props.gettingDate(parsedDate.format('YYYY-MM-DD'));
    } else {
        console.error('Invalid date:', date);
    }
}

  return (
    
  <DatePicker
        selected={props.selectedDate}
        onChange={(date)=> selectedDate(date)}
        dateFormat="MM/dd/yyyy"
        placeholderText="mm/dd/yyyy"
        disabled={props.disabled}
        className="form-control SearchInputbox "
      />

  )
}

export default Datepicker