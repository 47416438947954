export const ZipRegEx : any  = /^[1-9]\d{5}$/;

export const nameRegEx : any = /^[a-zA-Z\s]+$/ ; 

export const clinicNameRegExp = /^[a-zA-Z\s]*$/ ; 

export const AddressRegExp = /^[0-9a-zA-Z\s]*$/ ; 

export const groupIdRegExp = /^[0-9a-zA-Z]*$/ ; 

export const phoneNoRegexp =/^(\+\d{1,2}-?)?(\d{1,2}-?)?\d{10}$/


export const  countryCodeRegexp = /^\+\d{1,3}$/;

export const EmailRegExp =  /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

export const npiNoRegExp  = /^\d{10}$/ ; 

export const extNoRegExp = /^\d{4}$/;

export const externalMrnRegExp = /^[0-9a-zA-Z]*$/

export const SSNRegExp  = /^\d{9}$/ ; 

export const webSiteLink = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
