import React, { useEffect, useState } from 'react'
import { MDBDataTable } from 'mdbreact'
import { useNavigate, Link } from 'react-router-dom';
import SerchableDropdown from '../ReausableComponent/SearchableDropdown';
import axios from 'axios';

import moment from 'moment'
import DeleteModel from '../../../ReUsableComponent/DeleteModel';
import { ToastContainer, toast } from 'react-toastify'




function User() {

  const APIkey = process.env.REACT_APP_SERVERAPI;


  const navigate =useNavigate()

  const [listData, setListData] = useState<any[]>([]);
  const [searchUserList, setSearchUserList] = useState<any>(null);

  const [listclinic, setListclinic] = useState<any>(null);
  const [designationList, setdesignationList] = useState<any>(null);

  const [npiId, setnpiId] = useState<any>(null)
  const [clinicName, setClinicName] = useState<string | null>(null)
  const [userName, setUseName] = useState<string | null>(null)

  const [clinicId, setClinicId] = useState<any>(null);
  const [designationId, setDesignationId] = useState<any>(null);
  const [statusCheck, setStatusCheck] = useState<any>(null);
  const [activationDate, setActivationDate] = useState<any>(null);



  const [downSearch, setDownSearch] = useState(false)


  const [openDelete, setopenDelete] = useState(false)
  const [deleteID, setdeleteID] = useState()


  const deletedEntity : any = deleteID && searchUserList?.filter(i => i._id === deleteID)

  // console.log("this is list data ..." , listData);
  

  // console.log(deletedEntity);
  

  



  // delete Functionality starts here ... 
  const userDelete = (id) => {
    setopenDelete(true)
    setdeleteID(id)
    console.log("User ID for delete ... ", id);

  }

  const handleClosedelete = () => {
    setopenDelete(false)
  }

  const deleteFunction = async (deleteId) => {

    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo')

      if (getloginInfo !== null) {

        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token }
        }

        const deleteUser = await axios.delete(`${APIkey}/api/admin/deleteUserById/${clinicId}/${deleteId}`, header);
        console.log(deleteUser);


        setopenDelete(false)


        const updatedList = searchUserList?.filter(item => item._id !== deleteId)

        setSearchUserList(updatedList)

      }
      else {
        console.log("No login info found in localStorage");
      }
    }
    catch (err) {
      console.log(err);
    }

  }


  const editFunction = ( clinicId , editId)=> {
    navigate(`/user/editUser/${clinicId}/${editId}`)
  }


  function Action(userId) {

    return (
      <div className='d-flex  gap-2 ' >
        <div>

          <button className='blueButtonSmall' >
            <i className="fa-solid fa-eye" ></i>
          </button>
        </div>
        <div>

          <button  onClick={() => editFunction( clinicId , userId)} className='blueButtonSmall'>
            <i className="fa-solid fa-pencil" ></i>
          </button>
        </div>
        <div>

          <button onClick={() => userDelete(userId)} className='blueButtonSmall'>
            <i className="fa-solid fa-trash" ></i>
          </button>
        </div>

      </div>
    );
  }






  useEffect(() => {

    const getClinicList = async () => {

      try {
        let getloginInfo: string | null = localStorage.getItem('loginInfo')

        if (getloginInfo !== null) {

          const Token = JSON.parse(getloginInfo).data.accessToken;
          const header = {
            headers: { 'x-access-token': Token }
          }

          const clinicList = await axios.get(`${APIkey}/api/admin/listClinic`, header);
          // console.log(clinicList.data.data);

          setListclinic(clinicList?.data?.data?.map(i => {

            return {
              label: i.clinicName,
              value: i.clinicName,
              id : i._id
            }
          }
          ))

        }
        else {
          console.log("No login info found in localStorage");
        }
      }
      catch (err) {
        console.log(err);

      }

    }
    getClinicList();

  }, [])


  const getTheClinicID = async (id) => {

    setClinicId(id);

    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo')

      if (getloginInfo !== null) {

        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token }
        }

        // const response = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${id}`, header)
        // setListData(response.data.data)

        const res = await axios.get(`${APIkey}/api/admin/getClinicById/${id}`, header)
        setClinicName(res.data.data.clinicName)
        //   //  setClinicId(res.data.data._id)

          const getTheDesignation = await axios.get(`${APIkey}/api/admin/getDesignationList/${id}`, header)
          setdesignationList(getTheDesignation.data.data.map((i) => (
              {
                  label: i.designation,
                  value: i.designation,
                  id: i._id
              }
          )))

      }
    }
    catch (err) {
      console.log(err);
    }

  }



  const getTheDesignationId = (e)=> {
    setDesignationId(e)
  }

  const getTheStatus = (e)=> {
    setStatusCheck(e)
  }


  // console.log(searchUserList);
  

  const rows = searchUserList ? searchUserList?.map((item, index) => {


    console.log(item.username);
    
    

    const activeationDate = (createDate) => {
      let date = moment(createDate).format('DD-MM-yyyy');
      return date
    }


    const status = (status) => {

      if (status === 1) {
        let active = "Active";
        return active;
      }
      else {
        let inactive = "Inactive";
        return inactive;
      }

    }


    return {

      SerialNo: index + 1,
      npiId: item.npiId,
      Userid: item.userCode,
      Name: `${item.firstName} ${ item.middleName !== null && item.middleName !== undefined  ? item.middleName : ''} ${item.lastName} `,
      Username: item.username,
      ClinicName: item?.clinicDetails?.clinicName ,
      Designation: item?.designation?.designation,
      Role: item?.role?.role,
      Activationdate: activeationDate(item.createdDate),
      Status: status(item.status),
      Action: Action(item._id)

    }
  }) : [];





  const data = {
    columns: [
      {
        label: 'Serial No',
        field: 'SerialNo',
        sort: 'asc',
        width: 50
      },
      {
        label: 'NPI',
        field: 'npiId',
        sort: 'asc',
        width: 50
      },
      {
        label: 'User Id',
        field: 'Userid',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'Name',
        sort: 'asc',
        width: 270
      },
      {
        label: 'User Name',
        field: 'Username',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Clinic Name',
        field: 'ClinicName',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Designation',
        field: 'Designation',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Role',
        field: 'Role',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Activation Date',
        field: 'Activationdate',
        sort: 'asc',
        width: 100
      },

      {
        label: 'Status',
        field: 'Status',
        width: 25
      },
      {
        label: 'Action(s)',
        field: 'Action',
        width: 25
      }
    ],
    // rows: [
    //   {
    //     SerialNo: '1',
    //     npiId:" 1437279569",
    //     Userid: 123,
    //     UserName: 'Edinburgh',
    //     ClinicName: '27, toranto ...',
    //     Designation: 'Roy ... ',
    //     Role: '$320' , 
    //     Activationdate :"",
    //     referby:"active" , 
    //     Status:"active" , 
    //     Action : Action()
    //   }]
    rows
  };

  const statusList = [
    {
        label: 'Active',
        value: 'Active',
        id: 1
    },
    {
        label: 'Inactive',
        value: 'Inactive',
        id: 0
    }
    // ,
    // {
    //     label: 'Deleted',
    //     value: 'Deleted',
    //     id: 2
    // },
]


const submitSearch = async(data)=> { 
  try {

    let getloginInfo: string | null = localStorage.getItem('loginInfo')

    if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo)?.data?.accessToken;
        const header = {

            headers: { 'x-access-token': Token }

        }

        const response = await axios.post(`${APIkey}/api/admin/listUsersSearch`, data, header);

        // console.log("harshang");
        
      
        setSearchUserList(response?.data?.data);
        
  
    } else {
        console.log('No access token found in localStorage');
    }
} catch (error) {
 
  // console.log("harshang1");

  setSearchUserList(null);
    console.error( error);
}
}


const handleSearch = () => {

  if (downSearch === false) {
    setDownSearch(true)
  }
  else {
   let data ={ 
    clinicId  ,
    npiId : Number(npiId),
    userName,
    designationId,
    activationDate,
    statusCheck 
   }

   console.log(data);
   
   submitSearch(data)
   

  }
}


const handelCancel =()=> {
  setDownSearch(false)
  // setSearchUserList([])
  // setnpiId(null)
  // setUseName(null)
  // setClinicId(null)
  // setDesignationId(null)
  // setActivationDate(null)
  // setStatusCheck(null)
}


const handelReset =()=> {
   setSearchUserList([])
  setnpiId(null)
  setUseName(null)
  setClinicId(null)
  setDesignationId(null)
  setActivationDate(null)
  setStatusCheck(null)
}

  

  return (
    <>
      <div className='px-5 fw-bold'>
        <div className='PageHeader '>
          <div className='PageHeaderTitle'>
            <span className='' >
              User
            </span>
          </div>
          <div>
            <Link to="/user/AddNew">
              <button type='button' className='btn btn-primary '>
                Add User
              </button>
            </Link>

          </div>
        </div>
        <div className='card mt-3 p-3'>
          {
            downSearch &&
            <>
              <div className='  d-flex justify-content-end'>
                <button className='grayButton' onClick={handelCancel}>
                  <i className="fa fa-close" ></i>
                </button>
              </div>
              <div className='row'>
                <div className='col-md-6 col-lg-3'>
                  <label htmlFor="" className='form-label'>NPI:</label>
                  <input
                   type="number"
                    value={npiId || ''}
                   onChange={(e)=> setnpiId(e.target.value)} 
                   className='form-control SearchInputbox' />
                </div>
                <div className='col-md-6 col-lg-3'>
                <label htmlFor="" className='form-label'>User Name:</label>
                  <input
                   type="text"
                   value={userName || ''}
                   onChange={(e)=> setUseName(e.target.value)}
                    className='form-control SearchInputbox' />
                </div>
                <div className='col-md-6 col-lg-3'>
                <label htmlFor="" className='form-label'>Clinic Name:</label>
                  <SerchableDropdown

                  InitialValue={clinicId}
                   gettingData={getTheClinicID}
                    options={listclinic}
                     />
                </div>
                <div className='col-md-6 col-lg-3'>

                <label htmlFor="" className='form-label'>Designation:</label>
                  <SerchableDropdown
                  InitialValue={designationId}
                  gettingData={getTheDesignationId}
                  options={designationList}
                  />

                </div>
              </div>
            </>

          }
          {
            downSearch &&
            <div className='row mt-2'>
              <div className='col-md-6 col-lg-3 '>
                <label htmlFor="" className='form-label'>Activation Date:</label>
                <input type="date"
                value={activationDate || ''}
                onChange={(e) => setActivationDate(e.target.value)}
                className='form-control SearchInputbox' 
                />

              </div>
              <div className='col-md-6 col-lg-3 '>
                <label htmlFor="" className='form-label'>Status:</label>
                <SerchableDropdown
                InitialValue={statusCheck}
                gettingData={getTheStatus}
                options={statusList}
                />
              </div>


              <div className={'col-md-6 col-lg-3 offset-lg-3  d-flex align-items-end justify-content-end  gap-2 '} >
                <button className='btn btn-secondary h-45px' onClick={handelReset}>
                  Reset
                </button>
                <button className='btn btn-primary h-45px' onClick={() => handleSearch()}>
                  <i className="fa-solid fa-magnifying-glass"></i> Search
                </button>
              </div>

            </div>

          }
          {
            !downSearch &&
            <div className={'col-md-2 offset-md-10 d-flex justify-content-end '} >
              <button className='btn btn-primary' onClick={() => handleSearch()}>
                <i className="fa-solid fa-magnifying-glass"></i> Search
              </button>
            </div>
          }

        </div>

        <div className='card mt-3 p-3'>
          <div className='PageHeader'>
            <div className='PageHeaderTitle'>
              <span>
                Search Result
              </span>
            </div>
          </div>
          <MDBDataTable
            striped
            bordered
            small
            data={data}
            className="custom-table"
          />
        </div>



      </div>
        <ToastContainer />

      {

        openDelete &&
        (
          <DeleteModel
            openDelete={openDelete}
            closedelete={handleClosedelete}
            deleteID={deleteID}
            deletedEntity={`${deletedEntity[0]?.firstName} ${deletedEntity[0]?.lastName}`}
            deleteFunction={deleteFunction}
          />
        )
      }

    </>
  )
}

export default User
