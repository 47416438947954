import React from 'react'
import PatientIdCard from '../ReusablePages/ReusableAppointment/PatientIdCard'
import { NavLink, Outlet } from 'react-router-dom'


function AppointmentNavBar() {



    return (
        <>
            <div className="PatientUl  " >

            <NavLink to={'/appointment/charts/'}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                    className='card patientLi' >
                    <span>
                        Chart
                    </span>
                </NavLink>

                <NavLink to={'/appointment/charts/hpi'}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                    className='card patientLi' >
                    <span>
                        Chief Complaints/HPI
                    </span>
                    </NavLink>
                    <NavLink to={'/appointment/charts/problemList'}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                    className='card patientLi' >
                    <span>

                        Problem List
                    </span>
                    </NavLink>
                    <NavLink to={'/appointment/charts/history'}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                    className='card patientLi' >
                    <span>

                        History
                    </span>
                    </NavLink>


                <NavLink to={'/appointment/charts/Allergies'}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                    className='card patientLi' >
                    <span>
                        Allergies
                    </span>
                </NavLink>



                <NavLink to={'/appointment/charts/Medications'}
                    className={'card patientLi'}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                >
                    <span>
                        Medications
                    </span>
                </NavLink>



                <NavLink to={'/appointment/charts/vitals'}
                    className={"card patientLi"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}

                >

                    <span>
                        Vitals
                    </span>
                </NavLink>

                <NavLink to={'/appointment/charts/ros'}
                    className={"card patientLi"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}

                >
                    <span>
                        ROS
                    </span>
                    </NavLink>

                    <NavLink to={'/appointment/charts/immunization'}
                    className={"card patientLi"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}

                >
                    <span>
                        Immunization
                    </span>
                    </NavLink>

                    <NavLink to={'/appointment/charts/healthMaintence'}
                    className={"card patientLi"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                        >
                    <span>
                        Health Maintenance
                    </span>
                    </NavLink>
                    <NavLink to={'/appointment/charts/physicalExam'}
                    className={"card patientLi"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                        >
                    <span>
                        Physical Exam
                    </span>
                </NavLink>

                <NavLink to={'/appointment/charts/labResult'}
                    className={"card patientLi"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                        >
                    <span>
                        Lab Result
                    </span>
              </NavLink>
                <NavLink to={'/appointment/Charts/reviewOfResult'}
                    className={"card patientLi"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                >
                    <span>
                        Review of Results
                    </span>
                </NavLink>

                <NavLink to={"/appointment/Charts/Diagnosis"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                    className={"card patientLi"}
                >
                    <span>
                        Diagnosis
                    </span>
                </NavLink>

                <NavLink to={"/appointment/Charts/treatmentPlan"}
                    style={({ isActive }) => isActive ? { backgroundColor: "#464E5F", color: 'white' } : {}}
                    className={"card patientLi"}
                >
                    <span>
                        Treatment Plan
                    </span>
               </NavLink>
            </div>




            <div>
                <Outlet />
            </div>

        </>
    )
}

export default AppointmentNavBar
