import React, { useEffect, useState } from 'react'
import SerchableDropdown from '../app/pages/ReusablePages/ReusableAppointment/SearchableDropdown'
import axios from 'axios'

function GenderComponent({getTheGender , genderValue }) {

    const [ GenderData , setGenderData ] = useState([]); 


    
    const APIkey = process.env.REACT_APP_SERVERAPI;

    useEffect(()=> {

        const getGenderList = async() => {
            try {
              let getloginInfo : string | null = localStorage.getItem('appLoginInfo')
        
              if (getloginInfo !== null) {
      
                const Token = JSON.parse(getloginInfo).data.accessToken;
                const Id =JSON.parse(getloginInfo).data.clinicId
                // console.log(Id);
                
                const header  = {
                  headers: {'x-access-token': Token }
                }
                
                const genderList = await axios.get(`${APIkey}/api/admin/getGenderList/${Id}`, header );
                // console.log(genderList);
        
        setGenderData(genderList.data.data.map(i => (
                        { label : i.gender , 
                            value : i.gender,
                            id: i._id  
                        })))          
              }
              else {
                console.log("No login info found in localStorage");
              }
            }
            catch (err) {
              console.log(err);
      
            }
      
          }

          getGenderList();
    },[])



    const getThegender = (e)=> {
getTheGender(e);

    }

  return (
    <div>
         <SerchableDropdown
         InitialValue={genderValue}
                    options={GenderData}
                    gettingData={getThegender}
                    placeHolder={'Sex'}
                  />
    </div>
  )
}

export default GenderComponent