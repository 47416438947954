import React from 'react'

function PatientLabOfResults() {
  return (
    <div>
        <h1>

       Lab Results
        </h1>
        </div>
  )
}

export default PatientLabOfResults