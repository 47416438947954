import React from 'react'

import { EmailRegExp, SSNRegExp, ZipRegEx, extNoRegExp, nameRegEx, phoneNoRegexp, webSiteLink } from '../../../ReUsableComponent/regEx';
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'

function ClinicValidation(value: any): { [key: string]: string }{

    const errors: { [key: string]: string } = {};



if (!value.clinicName || value.clinicName.trim() === '') {
    errors.clinicName = inputText.ClinicName.errMsgText ;
  }else{
    // if(!nameRegEx.test(value.clinicName)){
    //     errors.clinicName = 'Please enter valid clinic name';
    // }
    // else
    //  if(value.clinicName.length >= 101){
    //     errors.clinicName = 'Please enter clinic Name less then or equal to 100 characters'
    // }
   
  }


  if (!value.lastName || value.lastName.trim() === '') {
    errors.lastName = inputText.lastName.ErrMsg ;
  }
  else{
    // if(!nameRegEx.test(value.lastName)){
    //     errors.lastName = 'Please enter valid last name';
    // }
    // else 
    // if(value.lastName.length >= 51){
    //     errors.lastName = 'Please enter last Name less then or equal to 50 characters'
    // }
   
  }

        
//   if (value.lastName && value.lastName.length >= 51) {
//     errors.lastName = 'Please enter a last name less than or equal to 50 characters';
//   }


// if(value.lastName && !nameRegEx.test(value.lastName)){
//     errors.lastName = 'Please enter valid last name';

// }
  
    
      if (!value.firstName || value.firstName.trim() === '') {
        errors.firstName = inputText.firstName.ErrMsg ;
      }

      // else{
      //   // if(!nameRegEx.test(value.firstName)){
      //   //     errors.firstName = 'Please enter valid first name';
      //   // }
      //   // else 
      //   if(value.firstName.length >= 51){
      //       errors.firstName = 'Please enter first Name less then or equal to 50 characters'
      //   }
      // }
    
      // if (value.middleName && value.middleName.length >= 51) {
      //   errors.middleName = 'Please enter a middle name less than or equal to 50 characters';
      // }
    

    // if(value.middleName && !nameRegEx.test(value.middleName)){
    //     errors.middleName = 'Please enter valid middle name';

    // }
      

    
      
    

    
     
    
      
    
      if (!value.addressLine1 || value.addressLine1.trim() === '') {
        errors.addressLine1 = inputText.Address1.ErrMsg ;
      }
      // else{
      // if(value.addressLine1.length >= 101){
      //       errors.addressLine1 = 'Please enter address line 1 less then or equal to 100 characters'
      //   }
      // }
    
      // if (value.addressLine2 && value.addressLine2.length >= 101) {
      //   errors.addressLine2 = 'Please enter address line 2 less than or equal to 100 characters';
      // }
    
    
      if (!value.countryId) {
        errors.countryId = inputText.country.ErrMsg
    }
      if (!value.stateId) {
        errors.stateId = inputText.state.ErrMsg
    }
      if (!value.cityId) {
        errors.cityId = inputText.city.ErrMsg 
    }
    
    
    
      
    if(!value.zipcodeId ){
        errors.zipcodeId = inputText.zip.ErrMsg;
      }
      else if(value.zipcodeId.length <= 4){
        errors.zipcodeId = inputText.zip.validErrMsg ;
        }
      
    



      
    if(!value.email || value.email.trim() === '' ){
        errors.email = inputText.email.ErrMsg ;
      }
      else{
        if(!EmailRegExp.test(value.email)){
        errors.email =  inputText.email.validErrMsg ;
        }
        // else if(value.email.length >= 251){
        //     errors.email = 'Please enter email less than or equal to 251 characters';
        // }
      }
    
    
    
    
    
    if(!value.contactNumber1 ){
        errors.contactNumber1 = inputText.cellNo.ErrMsg ;
      }
      else{
        if(!phoneNoRegexp.test(`${value.countryCode1}${value.contactNumber1}`)){
        errors.contactNumber1 = inputText.cellNo.validErrMsg;
        }
       
      }
    
    
    
    
      if (value.contactNumber2 && !phoneNoRegexp.test(`${value.countryCode2}${value.contactNumber2}`)) {
        errors.contactNumber2 = inputText.WorkPhone.validErrMsg ;
        }
       
    //   if (value.workPhoneNo && !phoneNoRegexp.test(`${value.countryCode2}${value.contactNumber2}`)) {
    //     errors.contactNumber2 = 'Please enter valid work phone ';
    //     }
       
      
      // if (value.ext && value.ext <= 2 ) {
      //   errors.ext = inputText.ex.ErrMsg ;

      //   }



      
      const maxSizeInBytes = 5 * 1024 * 1024;
      console.log("Value : " , value);

      if (value.logoDetails) {
          if (value.logoDetails.size > maxSizeInBytes) {
            errors.logoErr = "Please upload clinic logo less than or equal to 5 mb"
          }
          else {
              const allowedExtensions = ['.jpeg', '.jpg', '.png'];
              const fileExtension = value.logoDetails.name.toLowerCase().slice(value.logoDetails.name.lastIndexOf('.'));
              if (!allowedExtensions.includes(fileExtension)) {
                errors.logoErr = 'Please upload a valid clinic type (.jpeg, .jpg, or .png)';
              }
          }
      }
      else{
        if(!value.editSide){
          errors.logoErr = "Please upload clinic logo"
          }
      }

       if(value.clinicWebsite){
        if(!webSiteLink.test(value.clinicWebsite)){
          errors.websiteErr = "Please enter valid clinic website"
          }
       }
      
    
    
    
      // Add more validation rules as needed
    
      return errors;

}

export default ClinicValidation