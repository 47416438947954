import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'


function PatientIdCard() {
  return (
    <>
       <div className="card   px-3  ">
                    <div className="row">

                        <div className="d-flex p-2 col-md-12 col-lg-4 ">
                            <div className="" >
                                <img style={{ height: "48px", width: "48px" }} src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metornic' />
                            </div>
                            <div className=" px-5 ">
                                <div className=" ">
                                    <span className="fw-bold fs-4">
                                        Smith John A, 03 / 23 / 1995, 36Y, M
                                    </span>

                                </div>

                                <div className="row ">
                                    <div className="d-flex col-md-12 col-lg-4 ">
                                        <div className=" ">
                                            <i className="fa fa-phone " aria-hidden="true"></i>
                                        </div>
                                        <div className='cardHeaderElementMargin'>
                                            <span>123 - 456 - 7890</span>
                                        </div>
                                    </div>
                                    <div className=" d-flex col-md-12 col-lg-8 ">
                                        <div className=" " >
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                        </div>
                                        <div>
                                            <span className='cardHeaderElementMargin'>
                                                smith@gmail.com
                                            </span>
                                        </div>
                                    </div>
                                    <div className=" d-flex col-md-12 col-lg-12 ">
                                        <div >
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </div>
                                        <div>
                                            <span className='cardHeaderElementMargin'>
                                                123 Elm Street, Apt 4B, Springfield - 62701
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex  col-md-12  col-lg-8 ">
                            <div className=" row   py-3 gap-2 ">
                                <div className='d-flex col-md-12 col-lg-12 gap-2'>

                                <div className="">
                                    <span className="fs-5  ">
                                        Appointment
                                    </span>
                                </div>
                                <div className="" style={{ paddingTop: "2px", listStyle: "none", display: "inline-flex" }} >
                                    <div className=" px-1" >

                                        <span className="fs-7 fw-semibold ">
                                            Date :

                                        </span>
                                        <span className="fs-7 fw-semibold  ">
                                            MM/DD/YYYY
                                        </span>

                                    </div>

                                </div>
                                    <div className="px-1">

                                        <span className="fs-7 fw-semibold  ">
                                            Time :

                                        </span>
                                        <span className="fs-7 fw-semibold  ">
                                            8:00 PM
                                        </span>

                                    </div>

                                    <div className="px-1">

                                        <span className="fs-7 fw-semibold  ">
                                            Provider :

                                        </span>
                                        <span className="fs-7  fw-semibold ">
                                            Pranay Patel
                                        </span>

                                    </div>

                                    <div className="px-1">

                                        <span className="fs-7 fw-semibold  ">
                                            Visit Status:

                                        </span>
                                        <span className="fs-7 fw-semibold ">
                                            Pending
                                        </span>

                                    </div>

                                </div>

                                <div className=' d-flex col-md-12 col-lg-12 gap-2'>

                                <div className="" >
                                    <span className="fs-6 ">
                                        Insurance Detail :
                                    </span>
                                    <span className="fs-7 fw-semibold ">
                                        ABC Health Insurance
                                    </span>
                                </div>

                                <div >
                                    <span className="fs-6  ">
                                        Eligibility Activation:
                                    </span>
                                    <input className="form-check-input" type="checkbox" />
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div >
    </>
  )
}

export default PatientIdCard
