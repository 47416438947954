import React from 'react'

function PatientImmunization() {
  return (
    <div>
        <h1>
        Immunization
        </h1>
        </div>
  )
}

export default PatientImmunization