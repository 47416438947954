import React, { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';

interface Option {
  value: string;
  label: string;
  id: string;
}

interface MultiSelectDropdownProps {
  options: Option[];
  placeHolder: string;
  getMultiselectData: (selectedIds: string[]) => void;
  InitialValue: string[] | null;
}

function MultiSelectDropdown({ options, placeHolder, getMultiselectData, InitialValue }: MultiSelectDropdownProps) {
  const [data, setData] = useState<string[]>([]);

  const handleMultiChange = (selectedOptions: MultiValue<{ value: string; label: JSX.Element; id: string }>) => {
    const selectedIds = selectedOptions ? selectedOptions.map(option => option.id) : [];
    getMultiselectData(selectedIds);
    setData(selectedIds);
  };

  useEffect(() => {
    if (InitialValue !== undefined) {
      setData(InitialValue ?? []);
    }
  }, [InitialValue]);

  const formattedOptions = options.map(option => ({
    value: option.value,
    label: <span title={option.label}>{option.label}</span>,
    id: option.id
  }));

  const selectedValues = data.length > 0
    ? formattedOptions.filter(option => data.includes(option.id))
    : [];

  return (
    <Select
      className='h-30px'
      options={formattedOptions}
      placeholder={placeHolder}
      isMulti
      maxMenuHeight={200}
      onChange={handleMultiChange}
      value={selectedValues}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "5px",
          background: '#fff',
          borderColor: '#dbdfe9',
          minHeight: '30px',
          height: '30px',
          fontWeight: "600"
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: '30px',
          padding: '0 6px'
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px',
        }),
        option: (provided) => ({
          ...provided,
          whiteSpace: 'nowrap', // Prevents wrapping of text
            overflow: 'hidden', // Hides overflow text
            textOverflow: 'ellipsis', // Adds ellipsis when text overflows
            maxWidth: '100%', // Ensures option doesn't exceed container width
            fontSize: '12px',
            padding: '1px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            color: 'black',
            fontWeight: '600',
            paddingLeft: '4px',
        })
      }}
    />
  );
}

export default MultiSelectDropdown;
