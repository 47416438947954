import React from 'react'

function PatientPhysicalExam() {
  return (
    <div>
        <h1>
         PhysicalExam
            </h1>
            </div>
  )
}

export default PatientPhysicalExam