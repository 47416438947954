import React from 'react'

function Billing() {
  return (
    <div>
      <h1>
        Billing
      </h1>
    </div>
  )
}

export default Billing
