/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


// import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AdminAuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])




  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 background-color-page'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-100 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-column flex-lg-row-fluid login-card'>
          {/* begin::Wrapper */}
          <div className='w-lg-650px p-10'>
            <Outlet />
          </div>
          
          <ToastContainer
            // position="bottom-left"
            />
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}

        {/* <div className='d-flex flex-center flex-wrap px-5'>
          
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
         
        </div> */}
        {/* end::Footer */}
      </div>
      
      {/* end::Body */}

      {/* begin::Aside */}
     
      {/* end::Aside */}
      
    </div>
    
  )
}

export {AdminAuthLayout}
