import React from 'react'

function ConvertTo12Hour(time24) {
    if(!time24){
        return;
    }
    
    const [hour, minute] = time24.split(':');
    let hour12 = hour % 12 || 12; // Convert hour to 12-hour format
    const amPm = hour < 12 || hour === 24 ? 'AM' : 'PM';
    
    return `${hour12}:${minute} ${amPm}`;
}

export default ConvertTo12Hour;