import React from 'react'

function PatientTabHistory() {
  return (
    <>
    <div className='card py-2 px-2'>
            <h1>
            History
            </h1>
    </div>
    
    </>
  )
}

export default PatientTabHistory ;