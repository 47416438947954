import React from 'react'

function PatientHistory() {
  return (
    <div>
        <h1>
        PatientHistory
        </h1>
        </div>
  )
}

export default PatientHistory