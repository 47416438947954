import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {InboxMenu} from '../pages/Inbox/InboxMenu'
import {AppointmentMenu} from '../pages/Appointment/AppointmentMenu'
import {PatientChartsMenu} from '../pages/Appointment/PatientChartsMenu'
import {DocumentCenterMenu} from '../pages/Document/DocumentCenterMenu'
import {ReferencesMenu} from '../pages/References/ReferencesMenu'
// import {ManageOffice} from '../pages/ManageOffice/ManageOfficeMenu'
import PatientDetails from '../pages/Patients/PatientDetails';
import PatientInformation from '../pages/Patients/PatientInformation';
// import PatientNav from '../pages/Patients/sideNav.tsx--'
import PatientVitals from '../pages/Appointment/PatientVitals'
import PatientSearch from '../pages/Patients/PatientSearch'
import AppointmentNavBar from '../pages/Appointment/AppointmentNavbar'
import PatientMedAllergies from '../pages/Appointment/PatientAllergies'
import PatientMedication from '../pages/Appointment/PatientMedication'
import PatientReviewOfResult from '../pages/Appointment/PatientReviewOfResult'
import PatientDiagnosis from '../pages/Appointment/PatientDiagnosis'
import PatientHpi from '../pages/Appointment/PatientHpi'
import PatientProblemlist from '../pages/Appointment/PatientProblemlist'
import PatientHistory from '../pages/Appointment/PatientHistory'
import PatientRos from '../pages/Appointment/PatientRos'
import PatientImmunization from '../pages/Appointment/PatientImmunization'
import PatientHealthMaintenance from '../pages/Appointment/PatientHealthMaintenance'
import PatientLabOfResults from '../pages/Appointment/PatientLabOfResults'
import PatientTreatmentPlan from '../pages/Appointment/PatientTreatmentPlan'
import PatientPhysicalExam from '../pages/Appointment/PatientPhysicalExam'


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='inbox' element={<InboxMenu />} />
        <Route path='appointment' element={<AppointmentMenu />} >
        </Route>
     
        <Route path='appointment/Charts' element={<AppointmentNavBar />} >
            <Route path='' element={<PatientChartsMenu />} ></Route>
            <Route path='hpi' element={<PatientHpi />} ></Route>
            <Route path='problemList' element={<PatientProblemlist />} ></Route>
            <Route path='history' element={<PatientHistory />} ></Route>
            
            <Route path='Allergies' element={<PatientMedAllergies />} />
            <Route path='Medications' element={<PatientMedication />} />
            <Route path='vitals/:id' element={<PatientVitals />} />
            <Route path='ros' element={<PatientRos />} />
            <Route path='immunization' element={<PatientImmunization />} />
            <Route path='healthMaintence' element={<PatientHealthMaintenance />} />
            <Route path='physicalExam' element={<PatientPhysicalExam />} />
            <Route path='labResult' element={<PatientLabOfResults />} />
            
            <Route path='reviewOfResult' element={<PatientReviewOfResult/>} />
            <Route path='Diagnosis' element={<PatientDiagnosis />} />
            <Route path='treatmentPlan' element={<PatientTreatmentPlan />} />
          </Route>

        <Route path='patientDetails/patientInformation' element={<PatientInformation />} />

       
      
        <Route path='patient' element={<PatientSearch />}/>
        <Route path='patient/Vitals/:id' element={<PatientVitals/>}/>
        <Route path='ClinicNotes' element={<h1>This is ClinicNotes ...  </h1>}/>
        <Route path='patient/Details/:tab/:id/:appointmentId' element={<PatientDetails />} />

        

        <Route path='documentCenter' element={<DocumentCenterMenu />} />
        <Route path='references' element={<ReferencesMenu />} />
        {/* <Route path='manageOffice' element={<ManageOffice />} /> */}


        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route >   
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
