import React from 'react'

function PatientRos() {
  return (
    <>
    <h1>
    PatientRos
    </h1>
    </>
  )
}

export default PatientRos