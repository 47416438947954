import React from 'react'
import { EmailRegExp, SSNRegExp, ZipRegEx, extNoRegExp, externalMrnRegExp, nameRegEx, phoneNoRegexp } from '../../../../ReUsableComponent/regEx';

import InputText from '../../../../ReUsableComponent/ReyClinicalinputs.json'



function PatientInfoValidation(value: any): { [key: string]: string } {


  const errors: { [key: string]: string } = {};






  if (!value.lastName || value.lastName.trim() === '') {
    errors.lastName = InputText.lastName.ErrMsg;
  }
  // else{
  //   // if(!nameRegEx.test(value.lastName)){
  //   //     errors.lastName = 'Please enter valid last name';
  //   // }
  //   // else 
  //   // if(value.lastName.length >= 51){
  //   //     errors.lastName = 'Please enter last name less then or equal to 50 characters'
  //   // }

  // }



  if (!value.firstName || value.firstName.trim() === '') {
    errors.firstName = InputText.firstName.ErrMsg;
  }
  // else{
  //   // if(!nameRegEx.test(value.firstName)){
  //   //     errors.firstName = 'Please enter valid first name';
  //   // }
  //   // else
  //    if(value.firstName.length >= 51){
  //       errors.firstName = 'Please enter first name less then or equal to 50 characters'
  //   }
  // }




  // if (value.middleName && value.middleName.length >= 51) {
  //   errors.middleName = 'Please enter  middle name less than or equal to 50 characters';
  // }


  // if(value.middleName && !nameRegEx.test(value.middleName)){
  //     errors.middleName = 'Please enter valid middle name';

  // }

  //   if (value.alternateName && value.alternateName.length >= 51) {
  //     errors.alternateName = 'Please enter  alternate name less than or equal to 50 characters';
  //   }


  // if(value.alternateName && !nameRegEx.test(value.alternateName)){
  //     errors.alternateName = 'Please enter valid alternate name';

  // }



  if (!externalMrnRegExp.test(value.externalId)) {
    errors.externalId = InputText.externalMrn.ErrMsg;
  }
  // else if(value.externalId && value.externalId.length >= 11){
  //     errors.externalId = 'Please enter external mrn less than or equal to 10 charactersmrn';
  // }




  if (!value.genderId) {
    errors.gender = InputText.Sex.ErrMsg
  }


  if (!value.dob) {
    errors.dob = InputText.dateOfBirth.ErrMsg
  }


  if (!value.ssn) {
    errors.ssn = InputText.SSN.ErrMsg;
  }
  else {
    if (!SSNRegExp.test(value.ssn)) {
      errors.ssn = InputText.SSN.validErrMsg;
    }
  }


  if (!value.addressLine1 || value.addressLine1.trim() === '') {
    errors.addressLine1 = InputText.Address1.ErrMsg;
  }

  // else{
  // if(value.addressLine1.length >= 101){
  //       errors.addressLine1 = 'Please enter address line 1 less then or equal to 100 characters'
  //   }
  // }

  // if (value.addressLine2 && value.addressLine2.length >= 101) {
  //   errors.addressLine2 = 'Please enter address line 2 less than or equal to 100 characters';
  // }


  if (!value.countryId) {
    errors.countryId = InputText.country.ErrMsg
  }
  if (!value.stateId) {
    errors.stateId = InputText.state.ErrMsg
  }
  if (!value.cityId) {
    errors.cityId = InputText.city.ErrMsg
  }




  // if (!value.zipcodeId) {
  //   errors.zipcodeId = InputText.zip.ErrMsg;
  // }
  // else if (!ZipRegEx.test(value.zipcodeId)) {
  //   errors.zipcodeId = InputText.zip.validErrMsg;
  // }


  if(!value.zipcodeId ){
    errors.zipcodeId = InputText.zip.ErrMsg;
  }
  else if(value.zipcodeId.length <= 4){
    errors.zipcodeId = InputText.zip.validErrMsg ;
    }
  


  if (!value.emailAddress || value.emailAddress.trim() === '') {
    errors.emailAddress = InputText.email.ErrMsg;
  }
  else {

    if (!EmailRegExp.test(value.emailAddress)) {
      errors.emailAddress = InputText.email.validErrMsg;
    }
    // else if(value.emailAddress.length >= 251){
    //     errors.emailAddress = 'Please enter email less than or equal to 250 characters';
    // }
  }





  if (!value.mobileNo) {
    errors.mobileNo = InputText.cellNo.ErrMsg;
  }
  else {
    if (!phoneNoRegexp.test(`${value.mobileCountryCode}${value.mobileNo}`)) {
      errors.mobileNo = InputText.cellNo.validErrMsg;
    }
  }


  if (value.homeNo && !phoneNoRegexp.test(`${value.homeCountryCode}${value.homeNo}`)) {
    errors.homeNo = InputText.homeNo.validErrMsg;
  }

  if (value.faxNo && !phoneNoRegexp.test(`${value.faxCountryCode}${value.faxNo}`)) {
    errors.faxNo = InputText.faxNo.validErrMsg;
  }

  // if (value.faxNo && value.faxNo.length >= 16) {
  //   errors.faxNo = 'Please enter fax less than or equal to 15 digits';
  //   }




  if (value.workPhoneNo && !phoneNoRegexp.test(`${value.workCountryCode}${value.workPhoneNo}`)) {
    errors.workPhoneNo = InputText.WorkPhone.validErrMsg;
  }


  // if (value.workExtNo && !extNoRegExp.test(value.workExtNo)) {
  //   errors.workExtNo = 'Please enter valid Ext';
  //   }



  return errors;

}

export default PatientInfoValidation