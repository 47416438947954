import React, { useEffect, useState } from 'react'
import { MDBDataTable } from 'mdbreact';
import { useNavigate, Link, useParams } from 'react-router-dom';
import SerchableDropdown from '../ReausableComponent/SearchableDropdown';
import axios from 'axios';
import inputText from "../../../ReUsableComponent/ReyClinicalinputs.json"
import { initOnLoad } from 'apexcharts';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


import moment from 'moment';
import DeleteModel from '../../../ReUsableComponent/DeleteModel';
import { ToastContainer } from 'react-toastify';

function Clinics() {

  const APIkey = process.env.REACT_APP_SERVERAPI;

  const navigate = useNavigate()

  // const {}  = useParams()


  const [listData, setListData] = useState<any[]>([]);
  const [listclinic, setListclinic] = useState<any[]>([]);
  const [searchCLinicList, setSearchCLinicList] = useState<any>(null);
  const [clinicNameData, setClinicNameData] = useState<Array<any> | null>(null);

  const [downSearch, setDownSearch] = useState(false)


  const [clinicId, setclinicId] = useState<string | null>('');
  const [clinicName, setClinicName] = useState<string | null>('');
  const [contactPerson, setcontactperson] = useState<string | null>('');
  const [activationDate, setActivationDate] = useState<any>('');
  const [searchstatus, setsearchStatus] = useState<any>(1);
  const [referBy, setReferBy] = useState<string | null>('');



  const [openDelete, setopenDelete] = useState(false)
  const [deleteID, setdeleteID] = useState()

  const deletedEntity: any = deleteID && listData.filter(i => i._id === deleteID)





  // delete Functionality starts here ... 
  const clinicdelete = (id) => {
    setopenDelete(true)
    setdeleteID(id)
    console.log("clinic ID for delete ... ", id);

  }

  const handleClosedelete = () => {
    setopenDelete(false)
  }

  const deleteFunction = async (deleteId) => {


    try {
      let getloginInfo: string | null = localStorage.getItem('loginInfo')

      if (getloginInfo !== null) {

        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token }
        }

        const deleteClinic = await axios.delete(`${APIkey}/api/admin/deleteClinic/${deleteId}`, header);
        console.log(deleteClinic);


        setopenDelete(false)


        const updatedList = listData.filter(item => item._id !== deleteId)

        setListData(updatedList)



      }
      else {
        console.log("No login info found in localStorage");
      }
    }
    catch (err) {
      console.log(err);

    }

  }



  // edit page redirect Functionality  
  const editFunction = (editId) => {
    navigate(`/clinics/editClinic/${editId}`)
  }




  function Action(id) {

    return (
      <div className='d-flex  gap-2 '>
        <div>

          <button className='blueButtonSmall'
            onClick={() => console.log(id)}
          >
            <i className="fa-solid fa-eye" ></i>
          </button>
        </div>
        <div>

          <button className='blueButtonSmall'
            onClick={() => editFunction(id)}
          >
            <i className="fa-solid fa-pencil" ></i>
          </button>
        </div>
        <div>

          <button className='blueButtonSmall '
            onClick={() => clinicdelete(id)}
          >
            <i className="fa-solid fa-trash" ></i>
          </button>
        </div>

      </div>

    );
  }






  useEffect(() => {

    const getClinicList = async () => {

      try {
        let getloginInfo: string | null = localStorage.getItem('loginInfo')

        if (getloginInfo !== null) {

          const Token = JSON.parse(getloginInfo).data.accessToken;
          const header = {
            headers: { 'x-access-token': Token }
          }



          const clinicList = await axios.get(`${APIkey}/api/admin/listClinic`, header);
          // console.log(clinicList.data.data);
          setListData(clinicList.data.data)

          // setListData(clinicList.data.data.filter(clinicitem => clinicitem.status !== 2));
          

          setListclinic(clinicList.data.data.map(i => (
            {
              label: i.clinicName,
              value: i.clinicName,
              id: i.clinicName
            })))

        }
        else {
          console.log("No login info found in localStorage");
        }
      }
      catch (err) {
        console.log(err);

      }
    }
    getClinicList();

  }, [])



  const submitSearch = async (data) => {
    try {

      console.log(data);


      let getloginInfo: string | null = localStorage.getItem('loginInfo')

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token }
        }

        const response = await axios.post(`${APIkey}/api/admin/listClinicSearch`, data, header);
        // console.log(response);

        setSearchCLinicList(response?.data?.data);

      } else {
        console.log('No access token found in localStorage');
      }
    } catch (error) {

      console.error('Error occurred while adding patient: ', error);

      setSearchCLinicList([])
    }
  }

  const handleSearch = () => {

    if (downSearch === false) {
      setDownSearch(true)
    }
    else {

      let data = {
        clinicCode: clinicId,
        clinicName,
        contactPerson,
        activationDate,
        statusCheck: searchstatus,
        referenceId: referBy
      }

      submitSearch(data)

    }
  }


  const getsearchClinicId = (e) => {
    setclinicId(e.target.value);
  }


  const getTheClinicName = (e) => {
    setClinicName(e);
  }


  const getsearchContactPerson = (e) => {
    setcontactperson(e.target.value)
  }
  const getsearchActivationDate = (e) => {
    let value = moment(e.target.value).format("yyyy-MM-DD")
    console.log(value);

    setActivationDate(value || '')
  }

  const getTheStatus = (e) => {
    setsearchStatus(e)
  }
  const getsearchReferBy = (e) => {
    setReferBy(e.target.value)
  }


  const statusList = [
    {
      label: 'Active',
      value: 'Active',
      id: 1
    },
    {
      label: 'Inactive',
      value: 'Inactive',
      id: 0
    }

  ]


  const rows = listData?.map((item, index) => {


    const activeationDate = (createDate) => {
      let date = moment(createDate).format('DD-MM-yyyy');
      return date
    }


    const status = (status) => {

      if (status === 1) {
        let active = "Active";
        return active;
      }
      else if(status === 0) {
        let inactive = "Inactive";
        return inactive;
      }
      else{
        return ;
      }

    }

    return {
      SerialNo: index + 1,
      ClinicId: item.clinicCode,
      ClinicName: item.clinicName,
      Address: `${item.addressLine1}${item.addressLine2 ? "," : ''} ${item.addressLine2 ? item.addressLine2 : ''}`,
      ContactPerson: `${item.firstName} ${item.middleName ? item?.middleName : ''}  ${item.lastName}`,
      ActivationDate: activeationDate(item.createdDate),
      Status: status(item.status),
      referby: "",
      Action: Action(item._id)
    }
  }) || [];

  const searchRow = searchCLinicList?.map((item, index) => {

    const activeationDate = (createDate) => {
      let date = moment(createDate).format('DD-MM-yyyy');
      return date
    }

    const status = (status) => {

      if (status === 1) {
        let active = "Active";
        return active;
      }
      else if(status === 0) {
        let inactive = "Inactive";
        return inactive;
      }
      else{
        return ;
      }
    }

    return {

      SerialNo: index + 1,
      ClinicId: item.clinicCode,
      ClinicName: item.clinicName,
      Address: `${item.addressLine1}${item.addressLine2 ? "," : ''} ${item.addressLine2 ? item.addressLine2 : ''}`,
      ContactPerson: `${item.firstName} ${item.middleName ? item?.middleName : ''}  ${item.lastName}`,
      ActivationDate: activeationDate(item.createdDate),
      Status: status(item.status),
      referby: "",
      Action: Action(item._id)
    }
  }) || [];



  const rowfun = (row, searchRow) => searchCLinicList === null ? row : searchRow;






  const data = {
    columns: [
      {
        label: 'Serial No',
        field: 'SerialNo',
        sort: 'asc',
        width: 50
      },
      {
        label: 'Clinic Id',
        field: 'ClinicId',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Clinic Name',
        field: 'ClinicName',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Address',
        field: 'Address',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Contact Person',
        field: 'ContactPerson',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Activation Date',
        field: 'ActivationDate',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Status',
        field: 'Status',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Refer By',
        field: 'referby',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Action(s)',
        field: 'Action',
        sort: '',
        width: 100
      }
    ],

    rows: rowfun(rows, searchRow)

  };

  const handelCancel = () => {
    setDownSearch(false)
    // setSearchCLinicList(null)
    // setclinicId(null)
    // setClinicName(null)
    // setcontactperson(null)
    // setActivationDate(null)
    // setsearchStatus(null)
    // setReferBy(null)
  }

  const handelReset =()=> {

    setSearchCLinicList(null)
    setclinicId(null)
    setClinicName(null)
    setcontactperson(null)
    setActivationDate(null)
    setsearchStatus(null)
    setReferBy(null)

  }
  return (
    <>

      <div className='px-5 fw-bold'>
        <div className='PageHeader '>
          <div className='PageHeaderTitle'>
            <span className='' >
              Clinics
            </span>
          </div>
          <div>
            <Link to="/clinics/AddNew">
              <button type='button' className='btn btn-primary '>
                Add Clinic
              </button>
            </Link>

          </div>
        </div>
        <div className='card mt-3 p-3'>
          {
            downSearch &&
            <>
              <div className='  d-flex justify-content-end'>
                <button className='grayButton' onClick={handelCancel}>
                  <i className="fa fa-close" ></i>
                </button>
              </div>
              <div className='row'>
                <div className='col-md-6 col-lg-3'>
                  <label htmlFor="" className='form-label'>{inputText.clinicId.label}</label>
                  <input
                    type="number"
                    className='form-control SearchInputbox'
                    onChange={getsearchClinicId}
                    value={clinicId || ''}
                  />
                </div>
                <div className='col-md-6 col-lg-3'>
                  <label htmlFor="" className='form-label'>{inputText.ClinicName.label}</label>
                  <SerchableDropdown
                    gettingData={getTheClinicName}
                    InitialValue={clinicName}
                    options={listclinic}
                  />

                </div>

                <div className='col-md-6 col-lg-3'>

                  <label htmlFor="" className='form-label'>{inputText.contactPerson.label}</label>
                  <input
                    type="text"
                    value={contactPerson || ''}
                    className='form-control SearchInputbox'
                    onChange={getsearchContactPerson}
                  />

                </div>
                <div className='col-md-6 col-lg-3 '>
                  <label htmlFor="" className='form-label'>{inputText.activationDate.label}</label>
                  <input
                    type="date"
                    value={activationDate || ''}
                    className='form-control SearchInputbox'
                    onChange={getsearchActivationDate}
                  />
                </div>
              </div>
            </>

          }
          {
            downSearch &&
            <div className='row mt-2'>

              <div className='col-md-6 col-lg-3'>
                <label htmlFor="" className='form-label'>{inputText.status.label}</label>
                <SerchableDropdown
                  InitialValue={searchstatus}
                  gettingData={getTheStatus}
                  options={statusList}
                />
              </div>
              <div className='col-md-6 col-lg-3'>
                <label htmlFor="" className='form-label'>{inputText.referby.label}</label>
                <input
                  type="text"
                  onChange={getsearchReferBy}
                  className='form-control SearchInputbox' />
              </div>

              <div className={'col-md-6 col-lg-3 '} >

              </div>

              <div className={'col-md-6 col-lg-3  d-flex align-items-end justify-content-end gap-2'} >
                <button className='btn btn-secondary h-45px' onClick={handelReset}>
              Reset
                </button>
                <button className='btn btn-primary h-45px' onClick={() => handleSearch()}>
                  <i className="fa-solid fa-magnifying-glass"></i> Search
                </button>
              </div>

            </div>

          }
          {
            !downSearch &&
            <div className={'col-md-2 offset-md-10 d-flex justify-content-end '} >
              <button className='btn btn-primary' onClick={() => handleSearch()}>
                <i className="fa-solid fa-magnifying-glass"></i> Search
              </button>
            </div>
          }

        </div>

        <div className='card mt-3 p-3'>
          <div className='PageHeader'>
            <div className='PageHeaderTitle'>
              <span>
                Search Result
              </span>
            </div>
          </div>
          <MDBDataTable
            striped
            bordered
            small
            data={data}
            className="custom-table"
          />

        </div>

        <ToastContainer />

      </div>
      {

        openDelete &&
        (
          <DeleteModel
            openDelete={openDelete}
            closedelete={handleClosedelete}
            deleteID={deleteID}
            deletedEntity={deletedEntity[0].clinicName}
            deleteFunction={deleteFunction}
          />
        )
      }


    </>
  )
}

export default Clinics
