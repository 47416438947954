import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import AddInsurance from '../Appointment/AddInsurance';
import { useParams } from 'react-router-dom';
import EditInsurance from './ReusablePatients/EditInsurance';
import DeleteModel from '../../../ReUsableComponent/DeleteModel';
import { ToastContainer } from 'react-toastify';

function PatientInsurance() {

    const APIkey = process.env.REACT_APP_SERVERAPI || '';


    const { id } = useParams();

    const [patientInsuarance, setPatientInsuarance] = useState<any>(null)

    const [openAddInsurance, setOpenAddInsurance] = useState(false)
    const [openEditInsurance, setOpenEditInsurance] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [insuranceId, setInsuranceId] = useState('')
    const [deleteID, setdeleteID] = useState()


    const deletedEntity: any = deleteID && patientInsuarance.filter((i: any) => i._id === deleteID)


    useEffect(() => {
        const getallInsuarance = async () => {
            try {
                const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

                if (getloginInfo !== null) {
                    const Token = JSON.parse(getloginInfo).data.accessToken;
                    const header = {
                        headers: { 'x-access-token': Token }
                    };

                    const response = await axios.get(`${APIkey}/api/admin/getPatientInsuranceByPatientId/${id}`, header);
                    // join remaining ... 
                    console.log(response);

                    setPatientInsuarance(response.data.data);
                    // setPatientdata(response.data.data);
                } else {
                    console.log('No login info found in localStorage');
                }
            } catch (err) {
                console.log(err);
            }
        }
        getallInsuarance()
    }, [openAddInsurance, openEditInsurance])


    const openAddInsurancePopUp = () => {
        setOpenAddInsurance(true)
    }

    const openEditInsurancePopUp = (id) => {
        setOpenEditInsurance(true)
        setInsuranceId(id)
    }



    const deleteFunction = async (deleteId) => {

        try {
            let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;
                const header = {
                    headers: { 'x-access-token': Token }
                }

                const deleteClinic = await axios.delete(`${APIkey}/api/admin/deletePatientInsurance/${deleteId}`, header);
                console.log(deleteClinic);

                setOpenDelete(false)

                const updatedList = patientInsuarance.filter((item: any) => item._id !== deleteId)

                setPatientInsuarance(updatedList)
            }
            else {
                console.log("No login info found in localStorage");
            }
        }
        catch (err) {
            console.log(err);

        }

    }



    const insuranceDelete = (id) => {
        setOpenDelete(true)
        setdeleteID(id)
        console.log("clinic ID for delete ... ", id);

    }

    const closeAddInsurancePopUp = () => {
        setOpenAddInsurance(false)
        setOpenEditInsurance(false)
        setOpenDelete(false)
    }




    return (
        <>
            <div className='card '>
                <div className='d-flex align-items-center p-2 gap-5'>
                    <span className='patient-info-title'>Add New Insurance</span>
                    <div>
                        <button className='blueButtonSmall gap-2' onClick={openAddInsurancePopUp}>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            <span>
                                Add New
                            </span>
                        </button>
                    </div>
                </div>
                <div className='p-2 lowerBorder'>
                    <span className='title-header'>Insurance List</span>
                </div>


                <div className='p-2'>

                    <table className='fullCurve w-100   '>
                        <thead className=''>
                            <tr className='tableHead'>

                                <th>
                                    &nbsp; Date
                                </th>
                                <th>
                                    Layer Of Coverage
                                </th>
                                <th>
                                    Insurance Type
                                </th>
                                <th>
                                    Insurance Plan Name
                                </th>
                                <th>
                                    Patient Insurance ID
                                </th>
                                <th>
                                    Insurance Effective Date
                                </th>
                                <th>
                                    Insurance End Date
                                </th>
                                <th>
                                    Payor/Plan Type
                                </th>
                                <th>
                                    Group ID
                                </th>
                                <th>

                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {

                                patientInsuarance?.map((i: any) => {

                                    const badge = "Tertiary"

                                    const Date = (date) => moment(date).format("DD/MM/yyyy");

                                    const badgecolor = (badge) => {
                                        console.log(badge);

                                        if (badge === "Primary") {
                                            return {
                                                width: "72px",
                                                height: "20px",
                                                backgroundColor: "#ebdcf9",
                                                color: '#B432F1',
                                                lineHeight: '14px',
                                                fontSize: '12px',
                                                borderRadius: '4px',
                                                border: '0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }
                                        } else if (badge === "Secondary") {
                                            return {
                                                width: "72px",
                                                height: "20px",
                                                backgroundColor: "#F6E3DF",
                                                color: '#FF6738',
                                                lineHeight: '14px',
                                                fontSize: '12px',
                                                borderRadius: '4px',
                                                border: '0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }
                                        }
                                        else if (badge === "Tertiary") {
                                            return {
                                                width: "72px",
                                                height: "20px",
                                                backgroundColor: "#D7EDEC",
                                                color: '#22AC95',
                                                lineHeight: '14px',
                                                fontSize: '12px',
                                                borderRadius: '4px',
                                                border: '0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }
                                        }
                                    }

                                    return (

                                        <tr className='tableBody'>

                                            <td style={{}} title={Date(i.createdDate)}>
                                                &nbsp; {Date(i.createdDate)}
                                            </td>
                                            <td title={i?.layerOfCoverageDetails?.layerOfCoverage}>
                                                <div className='' style={badgecolor(i?.layerOfCoverageDetails?.layerOfCoverage)}>
                                                    {i?.layerOfCoverageDetails?.layerOfCoverage}
                                                </div>
                                            </td>
                                            <td title={i?.patientInsuranceTypeDetails?.insuranceType}>
                                                {i?.patientInsuranceTypeDetails?.insuranceType}
                                            </td>
                                            <td title={i?.insurancePlanName}>
                                                {i?.insurancePlanName}
                                            </td>
                                            <td title={i?.patientInsuranceId}>
                                                {i?.patientInsuranceId}
                                            </td>
                                            <td title={Date(i?.insuranceEffectiveDate)}>
                                                {Date(i?.insuranceEffectiveDate)}
                                            </td>
                                            <td title={Date(i?.insuranceEndDate)}>
                                                {Date(i?.insuranceEndDate)}
                                            </td>
                                            <td title={i?.payorPlanDetails?.payorPlanType}>
                                                {i?.payorPlanDetails?.payorPlanType}
                                            </td>
                                            <td title={i?.groupId}>
                                                {i?.groupId}
                                            </td>

                                            <td className=' d-flex gap-2'>
                                                <button className='blueButtonSmall'>
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                </button>
                                                <button className='blueButtonSmall' onClick={() => openEditInsurancePopUp(i._id)}>
                                                    <i className="fa-solid fa-pen"></i></button>
                                                {/* <button className='blueButtonSmall' onClick={()=> insuranceDelete(i._id)} >
                                <i className="fa-solid fa-trash"></i>
                                </button> */}
                                            </td>
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </table>
                </div>
                {
                    patientInsuarance?.length == 0 && (

                        <div className='d-flex justify-content-center py-1 '>
                            <span className='patient-info-title'>
                                No records found
                            </span>
                        </div>
                    )
                }
            </div>

            <ToastContainer />

            {
                openAddInsurance && (
                    <AddInsurance
                        open={openAddInsurance}
                        closeInsurance={closeAddInsurancePopUp}
                        patientId={id} />
                )
            }

            {
                openEditInsurance && (
                    <EditInsurance
                        open={openEditInsurance}
                        closeInsurance={closeAddInsurancePopUp}
                        patientId={id}
                        insuranceId={insuranceId}
                    />
                )
            }
            {
                openDelete && (
                    <DeleteModel
                        openDelete={openDelete}
                        closedelete={closeAddInsurancePopUp}
                        deleteID={deleteID}
                        deletedEntity={deletedEntity[0].insurancePlanName}
                        deleteFunction={deleteFunction}
                    />
                )

            }
        </>
    )
}

export default PatientInsurance