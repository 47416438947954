import React from 'react'

function ErrorMsgShow({errorMsg}) {
    return (
        <>
        {

        errorMsg &&
            <span className='form-label errMsg'>
                <i className="fa-solid fa-triangle-exclamation"></i>
                &nbsp;
                {/* {inputText.InsurancePlaneName.ErrMsg} */}
                {errorMsg}
            </span>
        }

        </>
    )
}

export default ErrorMsgShow