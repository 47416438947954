import React, { useState } from "react";
import ReactPaginate from 'react-paginate'


import UpArrow from '../EHRIcon/UpArrow.png'
// import VitalWhite from '../EHRIcon/VitalsWhiteIcon.png'
// import DocumentWhite from '../EHRIcon/DocumentWhiteIcon.png'
// import ImmunizationWhite from '../EHRIcon/ImmunizationWhiteIcon.png'
// import ProblemWhite from '../EHRIcon/ProblemWhiteIcon.png'
// import RxWhite from '../EHRIcon/RxWhiteIcon.png'
// import Allergies from '../EHRIcon/AllergiesWhiteIcon.png'
import DownArrow from '../EHRIcon/DownArrow.png'
import PatientIdCard from "../ReusablePages/ReusableAppointment/PatientIdCard";


export function PatientChartsMenu() {

    const [vitalsData, setVitalsData] = useState([
        {
            date: "04/12/23 09:24",
            temp: "98.6 ",
            pulse: '72',
            ResRate: '16',
            bp: '120/80',
            weight: '150',
            height: `5'8"`,
            oxygenSat: '98%',
            bloodGlucose: '110'
        },
        {
            date: "04/12/23 09:24",
            temp: "98.6 ",
            pulse: '72',
            ResRate: '16',
            bp: '120/80',
            weight: '150',
            height: `5'8"`,
            oxygenSat: '98%',
            bloodGlucose: '110'
        },
        {
            date: "04/12/23 09:24",
            temp: "98.6 ",
            pulse: '72',
            ResRate: '16',
            bp: '120/80',
            weight: '150',
            height: `5'8"`,
            oxygenSat: '98%',
            bloodGlucose: '110'
        },
        {
            date: "04/12/23 09:24",
            temp: "98.6 ",
            pulse: '72',
            ResRate: '16',
            bp: '120/80',
            weight: '150',
            height: `5'8"`,
            oxygenSat: '98%',
            bloodGlucose: '110'
        },
        {
            date: "04/12/23 09:24",
            temp: "98.6 ",
            pulse: '72',
            ResRate: '16',
            bp: '120/80',
            weight: '150',
            height: `5'8"`,
            oxygenSat: '98%',
            bloodGlucose: '110'
        },
       
    ])
    const [downVitals, setDownVitals] = useState(false)
    const [DownDocument, setDownDocument] = useState(false)
    const [DownImmunization, setDownImmunization] = useState(false)
    const [DownProblem, setDownProblem] = useState(false)
    const [DownRx, setDownRx] = useState(false)
    const [DownAllergies, setDownAllergies] = useState(false)




    const [currentPage, setCurrentPage] = useState(0)

    const payerPerPage = 5

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected)

    }

    const indexOfLastPayer = (currentPage + 1) * payerPerPage
    const indexOfFirstPayer = indexOfLastPayer - payerPerPage
    const currentPayer = vitalsData.slice(indexOfFirstPayer, indexOfLastPayer)







    return (
        <>
            <div className="PatientChartPage ">
                {/* <div className="PatientUl" >
                    <div className=" card   patientLi ">
                        <span>
                            Chief Complaints/HPI
                        </span>
                    </div>

                    <div className=" card  patientLi">
                        <span>

                            Problem List
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                            History
                        </span>
                    </div>
                    <div className=" card patientLi">
                        <span>

                            Medications/Allergies
                        </span>
                    </div>
                    <div className=" card patientLi">
                        <span>

                            Vitals
                        </span>
                    </div>
                    <div className=" card patientLi">
                        <span>

                            ROS
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                            Immunization
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                            Health Maintenance
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                            Physical Exam
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                        Lab Result
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                            Review of Results
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                            Diagnosis
                        </span>
                    </div>
                    <div className="card patientLi">
                        <span>

                            Treatment Plan
                        </span>
                    </div>
                </div> */}
             {/* <PatientIdCard /> */}


                <div className=" row mt-2">
                    <div className=" col-xl-6  col-lg-12  col-md-12   " >

                        <div className=" card  ">
                            <div className={downVitals ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <div className="" >
                                        {/* <img className="PatientChartCardHeaderIcon" src={VitalWhite} alt="Vitals" /> */}
                                    </div>
                                    <div>
                                        <span className=" " >
                                            Vitals
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="px-2 PatientChartCardHeaderIcon  "  >
                                        <i style={{ color: 'white' }}
                                            className='   fa-solid fa-up-right-from-square '
                                        ></i>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setDownVitals(!downVitals)}
                                    >
                                        {
                                        downVitals ?
                                        <img src={UpArrow} alt="" /> :
                                        <img src={DownArrow} alt="" /> 
                                        
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>


                            </div>
                            {
                                downVitals &&
                                <div>

                                <div className="">
                                    <table className="" style={{ width: "100%" }}>
                                        <thead className="tableHead" >

                                            <tr>
                                                <th >Date</th>
                                                <th >Temperature</th>
                                                <th>Pulse</th>
                                                <th >Respiration Rate</th>
                                                <th >BP</th>
                                                <th >Weight</th>
                                                <th >height</th>
                                                <th >Oxygen Saturation</th>
                                                <th >Blood Glucose Level</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {
                                                currentPayer.map((item) => (

                                                    <tr className="tableBody">

                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            {item.temp}
                                                        </td>
                                                        <td>
                                                            {item.pulse}
                                                        </td>
                                                        <td>
                                                            {item.ResRate}
                                                        </td>
                                                        <td>
                                                            {item.bp}
                                                        </td>
                                                        <td>
                                                            {item.weight}
                                                        </td>
                                                        <td>
                                                            {item.height}
                                                        </td>
                                                        <td>
                                                            {item.oxygenSat}
                                                        </td>
                                                        <td>
                                                            {item.bloodGlucose}
                                                        </td>
                                                        <td>
                                                            icon
                                                        </td>
                                                        <td>
                                                            icon
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            <div className="PatientChartCardFooter">
                                <div>
                                    <span>
                                        Total Numbers of Records: {vitalsData.length}
                                    </span>
                                </div>
                                <div>

                                    <div className='pagination-container'>
                                        <ReactPaginate
                                            pageCount={Math.ceil(vitalsData.length / payerPerPage)}
                                            pageRangeDisplayed={5}
                                            marginPagesDisplayed={1}
                                            onPageChange={handlePageChange}
                                            containerClassName='pagination'
                                            activeClassName='active'
                                            previousLabel='<' // Set the label for the "Previous" button to "<"
                                            nextLabel='>'
                                        />
                                    </div>
                                </div>
                            </div>
                            </div>

}
                        </div>
                        <div className=" card mt-2 ">
                            <div className={ DownDocument ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <div className="" >
                                        {/* <img className="PatientChartCardHeaderIcon" src={DocumentWhite} alt="Vitals" /> */}
                                    </div>
                                    <div>
                                        <span className=" " >
                                            Document
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="px-2  PatientChartCardHeaderIcon "  >
                                        <i style={{ color: 'white' }}
                                            className='  fa-solid fa-up-right-from-square '
                                        ></i>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setDownDocument(!DownDocument)}
                                    >
                                        {
                                         DownDocument ?
                                         <img src={UpArrow} alt="" /> :
                                            <img src={DownArrow} alt="" /> 
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>


                            </div>
                            {
                                DownDocument &&
                                <div>

                                <div className="">
                                    <table className="" style={{ width: "100%" }}>
                                        <thead className="tableHead" >

                                            <tr>
                                                <th >Date</th>
                                                <th >Category Name</th>
                                                <th>Document Name</th>
                                                <th >Employ Name</th>

                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {
                                                vitalsData.map((item) => (

                                                    <tr className="tableBody">

                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            3
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            <div className="PatientChartCardFooter">
                                <div>
                                    <span>
                                        Total Numbers of Records: {vitalsData.length}
                                    </span>
                                </div>

                                <div className='pagination-container'>
                                    <ReactPaginate
                                        pageCount={Math.ceil(vitalsData.length / payerPerPage)}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={1}
                                        onPageChange={handlePageChange}
                                        containerClassName='pagination'
                                        activeClassName='active'
                                        previousLabel='<' // Set the label for the "Previous" button to "<"
                                        nextLabel='>'
                                    />
                                </div>
                            </div>
                            </div>

                            }

                        </div>
                        <div className=" card mt-2  ">
                            <div className={ DownImmunization ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <div className="" >
                                        {/* <img className="PatientChartCardHeaderIcon" src={ImmunizationWhite} alt="Vitals" /> */}
                                    </div>
                                    <div>
                                        <span className=" " >
                                            Immunization
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="px-2 PatientChartCardHeaderIcon  "  >
                                        <i style={{ color: 'white' }}
                                            className='  fa-solid fa-up-right-from-square '
                                        ></i>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setDownImmunization(!DownImmunization)}
                                    >
                                            {
                                         DownImmunization ?
                                         <img src={UpArrow} alt="" /> :
                                            <img src={DownArrow} alt="" /> 
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>


                            </div>
                            {
                                DownImmunization &&
                                <div>

                                <div className="">
                                    <table className="" style={{ width: "100%" }}>
                                        <thead className="tableHead" >

                                            <tr>
                                                <th >Vaccine</th>
                                                <th >Due on</th>
                                                <th>Performed On</th>

                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {
                                                vitalsData.map((item) => (

                                                    <tr className="tableBody">

                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            3
                                                        </td>


                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            <div className="PatientChartCardFooter">
                                <div>
                                    <span>
                                        Total Numbers of Records: {vitalsData.length}
                                    </span>
                                </div>
                                <div className='pagination-container'>
                                    <ReactPaginate
                                        pageCount={Math.ceil(vitalsData.length / payerPerPage)}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={1}
                                        onPageChange={handlePageChange}
                                        containerClassName='pagination'
                                        activeClassName='active'
                                        previousLabel='<' // Set the label for the "Previous" button to "<"
                                        nextLabel='>'
                                    />
                                </div>
                            </div>
                            </div>

                            }

                        </div>
                    </div>

                    <div className=" col-xl-6 col-lg-12 col-md-12    " >

                        <div className=" card  ">
                            <div className={ DownProblem ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <div className="" >
                                        {/* <img className="PatientChartCardHeaderIcon" src={ProblemWhite} alt="Vitals" /> */}
                                    </div>
                                    <div>
                                        <span className="" >
                                            Problems
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex  " >
                                    <div className="cardHeaderElementMargin" >
                                        <input  id="Review" type="checkbox" className=""  />
                                        <label htmlFor="Review" className="" >Review</label>
                                    </div>
                                    <div className="HL7 cardHeaderElementMargin">
                                        <span>
                                            HL7
                                        </span>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon  "  >
                                        <i style={{ color: 'white' }}
                                            className='  fa-solid fa-up-right-from-square '
                                        ></i>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setDownProblem(!DownProblem)}
                                    >
                                         {
                                         DownProblem ?
                                         <img src={UpArrow} alt="" /> :
                                            <img src={DownArrow} alt="" /> 
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>


                            </div>
                            {
                                DownProblem &&
                                <div>

                                <div className="">
                                    <table className="" style={{ width: "100%" }}>
                                        <thead className="tableHead" >

                                            <tr>
                                                <th >Date Onset</th>
                                                <th >Problem</th>
                                                <th>ICD-10</th>
                                                <th >Notes</th>

                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {
                                                vitalsData.map((item) => (

                                                    <tr className="tableBody">

                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            3
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            <div className="PatientChartCardFooter">
                                <div>
                                    <span>
                                        Total Numbers of Records: {vitalsData.length}
                                    </span>
                                </div>
                                <div className='pagination-container'>
                  <ReactPaginate
                    pageCount={Math.ceil(vitalsData.length / payerPerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName='pagination'
                    activeClassName='active'
                    previousLabel='<' // Set the label for the "Previous" button to "<"
                    nextLabel='>'
                  />
        </div>
                            </div>
                            </div>

}

                        </div>
                        <div className=" card  mt-2 ">
                            <div className={ DownRx ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <div className="" >
                                        {/* <img className="PatientChartCardHeaderIcon" src={RxWhite} alt="Vitals" /> */}
                                    </div>
                                    <div>
                                        <span className=" " >
                                            Rx
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="d-flex">
                                        <div className="cardHeaderElementMargin RxCardHeaderElement">
                                            <span>
                                            Prescribe
                                            </span>
                                        </div>
                                        <div className="cardHeaderElementMargin RxCardHeaderElement">
                                            <span>
                                            Manage
                                            </span>
                                        </div>
                                        <div className="cardHeaderElementMargin RxCardHeaderElement">
                                            <span>
                                            Upcoming
                                            </span>
                                        </div>
                                    </div>
                                <div className="cardHeaderElementMargin" >
                                        <input  id="Review" type="checkbox" className=""  />
                                        <label htmlFor="Review" className="" >Review</label>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon "  >
                                        <i style={{ color: 'white' }}
                                            className='  fa-solid fa-up-right-from-square '
                                        ></i>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setDownRx(!DownRx)}
                                    >
                                      {
                                         DownRx ?
                                         <img src={UpArrow} alt="" /> :
                                            <img src={DownArrow} alt="" /> 
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>


                            </div>
                            {
                                DownRx &&
                                <div>

                                <div className="">
                                    <table className="" style={{ width: "100%" }}>
                                        <thead className="tableHead" >

                                            <tr>
                                                <th >Date</th>
                                                <th >Medication</th>
                                                <th>Days</th>
                                                <th >Qty</th>
                                                <th >Last Refill on</th>
                                                <th >Refill</th>
                                                <th ></th>

                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {
                                                vitalsData.map((item) => (

                                                    <tr className="tableBody">

                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            3
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>
                                                        <td>
                                                            5
                                                        </td>
                                                        <td>
                                                            6  icon
                                                        </td>
                                                        <td>
                                                            icon
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            <div className="PatientChartCardFooter">
                                <div>
                                    <span>
                                        Total Numbers of Records: {vitalsData.length}
                                    </span>
                                </div>
                                <div className='pagination-container'>
                  <ReactPaginate
                    pageCount={Math.ceil(vitalsData.length / payerPerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName='pagination'
                    activeClassName='active'
                    previousLabel='<' // Set the label for the "Previous" button to "<"
                    nextLabel='>'
                  />
        </div>
                            </div>
                            </div>

}

                        </div>
                        <div className=" card  mt-2 ">
                            <div className={ DownAllergies ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <div className="" >
                                        {/* <img className="PatientChartCardHeaderIcon" src={Allergies} alt="Vitals" /> */}
                                    </div>
                                    <div>
                                        <span className=" " >
                                            Allergies
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex">
                                <div className="cardHeaderElementMargin" >
                                        <input  id="Review" type="checkbox" className=""  />
                                        <label htmlFor="Review" className="" >Review</label>
                                    </div>
                                    <div className="cardHeaderElementMargin" >
                                        <input  id="NoKnownAllergies" type="checkbox" className=""  />
                                        <label htmlFor="NoKnownAllergies" className="" >No Known Allergies</label>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon "  >
                                        <i style={{ color: 'white' }}
                                            className='  fa-solid fa-up-right-from-square '
                                        ></i>
                                    </div>
                                    <div className="px-2 PatientChartCardHeaderIcon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setDownAllergies(!DownAllergies)}
                                    >
                                       {
                                         DownAllergies ?
                                         <img src={UpArrow} alt="" /> :
                                            <img src={DownArrow} alt="" /> 
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>


                            </div>
                            {
                                DownAllergies &&(
                                    <div>

                                <div className="">
                                    <table className="" style={{ width: "100%" }}>
                                        <thead className="tableHead" >

                                            <tr>
                                                <th >Date</th>
                                                <th >Allergy / Intolerance</th>
                                                <th>Reaction</th>
                                                <th>Severity</th>
                                                <th>Status</th>

                                            </tr>
                                        </thead>
                                        <tbody className="">

                                            {
                                                vitalsData.map((item) => (

                                                    <tr className="tableBody">

                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            3
                                                        </td>
                                                        <td>
                                                            4
                                                        </td>
                                                        <td>
                                                            5
                                                        </td>


                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                               
                            <div className="PatientChartCardFooter">
                                <div>
                                    <span>
                                        Total Numbers of Records: {vitalsData.length}
                                    </span>
                                </div>
                                <div className='pagination-container'>
                  <ReactPaginate
                    pageCount={Math.ceil(vitalsData.length / payerPerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName='pagination'
                    activeClassName='active'
                    previousLabel='<' // Set the label for the "Previous" button to "<"
                    nextLabel='>'
                  />
        </div>
                            </div>
                            </div>

                             )
                            }
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}