import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  // const intl = useIntl()
  
  return (
    <>
 

      <MenuItem title='Dashboard' to='/dashboard' />
      {/* <MenuItem title='Inbox' to='/builder' /> */}
      <MenuItem title='Inbox' to='/inbox' />
      <MenuItem title='Appointment' to={'/appointment'} />
      <MenuItem title='Patient' to='/patient' />
      <MenuItem title='Document' to='/documentCenter' />
      <MenuItem title='References' to='/references' />
      {/* <MenuItem title='Manage Office' to='/manageOffice' /> */}
   
    </>
  )
}
