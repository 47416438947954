import React, { useEffect, useState } from 'react'
import CryptoJS from 'crypto-js';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactPaginate from 'react-paginate'
import AddNewPatient from './AddNewPatient'
import axios from 'axios'
import moment from 'moment'
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'
import MaskedInput from 'react-text-mask'
import PaginationCompo from '../../../ReUsableComponent/PaginationCompo'
import Datepicker from '../../../ReUsableComponent/Datepicker';


function PatientSearchInApp({ open, closePatientSearch, getThePatient }) {

  const APIkey = process.env.REACT_APP_SERVERAPI;


  const handleClose = () => {
    closePatientSearch()
  }

  const [showAddNewPatientClickPopup, setShowAddNewPatientClickPopup] = useState(false)

  const [patientSearchList, setPatientSearchList] = useState<any>(null);
  const [totalsearchPatient, setTotalsearchPatient] = useState<any>();






  const [rows, setRows] = useState<any>([{ searchVariable: '', searchMode: '', searchValue: '' }])
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);


  const [showTable, setShowTable] = useState(true)

  const handleAddRow = () => {

    setRows([...rows, { searchVariable: '', searchMode: '', searchValue: '' }])
  }
  const [patientData, setPatientData] = useState<any>([])
  const [totalPatient, setTotalPatient] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>('');




  const getPatientData = async () => {
    try {
      let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

      if (getloginInfo !== null) {

        const Token = JSON.parse(getloginInfo).data.accessToken;


        const header = {
          headers: { 'x-access-token': Token }
        }

        const patientList = await axios.get(`${APIkey}/api/admin/getAllPatients?page=${currentPage}&limit=${perPage}`, header);

        setPatientData(patientList.data.data.patient_list)
        setTotalPatient(patientList.data.data.pagination.totalRecords);
      }
      else {
        console.log("No login info found in localStorage");
      }
    }
    catch (err) {
      console.log(err);

    }

  }



  useEffect(() => {
    getPatientData();
  }, [currentPage, perPage, showAddNewPatientClickPopup]);


  const submitSearch = async (data) => {

    try {
      console.log(" search Data :", data);

      let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token }
        }

        const response = await axios.post(`${APIkey}/api/admin/getAllPatientsSearch`, data, header);

        console.log(response?.data?.data?.patient_list);

        setPatientSearchList(response?.data?.data?.patient_list);
        setTotalsearchPatient(response?.data?.data?.pagination?.totalRecords)

        if (response.data) {
          setShowTable(true)
        }


      } else {
        console.log('No access token found in localStorage');
      }
    } catch (error) {
      // Handle errors
      console.error('Error occurred while adding patient: ', error);
    }
  }


  const handleShowTable = () => {


    let dataRow = rows.map((i: any) => {
      if (i.searchVariable == 1) {
        return {
          lastName: i.searchValue,
          lastNameSearchMode: i.searchMode
        }
      }
      if (i.searchVariable == 2) {
        return {
          firstName: i.searchValue,
          firstNameSearchMode: i.searchMode
        }
      }
      if (i.searchVariable == 3) {

        const value = i?.searchValue?.replace(/\D/g, '');
        return {
          ssn: Number(value),
          ssnSearchMode: i.searchMode
        }
      }
      if (i.searchVariable == 4) {
        return {
          mobileNo: Number(i?.searchValue),
        }
      }
      if (i.searchVariable == 5) {
        return {
          dob: i.searchValue,
          dobSearchMode: i.searchMode
        }
      }
      if (i.searchVariable == 6) {
        return {
          mrn: Number(i.searchValue),
          mrnSearchMode: i.searchMode
        }
      }

    })


    const searchCriteriaObject = dataRow.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

    submitSearch(searchCriteriaObject)
  }



  const handleDeleteRow = (index) => {
    const updatedRows = [...rows]
    updatedRows.splice(index, 1)
    setRows(updatedRows)
  }



  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const { value } = e.target;

    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[index] = value;
    setSelectedOptions(updatedSelectedOptions);

    const updatedRows = [...rows];
    updatedRows[index].searchVariable = value;
    setRows(updatedRows);
  };

  const renderSelectOptions = (index: number) => {
    return (
      <>
        <option value='0' selected hidden>Please Select</option>
        <option value='3' disabled={selectedOptions.slice(0, index).includes('3')}>SSN</option>
        <option value='1' disabled={selectedOptions.slice(0, index).includes('1')}>Last Name</option>
        <option value='2' disabled={selectedOptions.slice(0, index).includes('2')}>First Name</option>
        <option value='4' disabled={selectedOptions.slice(0, index).includes('4')}>Mobile Number</option>
        <option value='5' disabled={selectedOptions.slice(0, index).includes('5')}>Date of Birth</option>
        <option value='6' disabled={selectedOptions.slice(0, index).includes('6')}>MRN</option>
      </>
    );
  };




  const getThePatientId = (id) => {
    console.log("patientId : ", id );
    
    const filteredArray = patientData.filter((item: any) => item._id === id);

console.log("Data : ", filteredArray);


    getThePatient(filteredArray[0])

    closePatientSearch()

  }





  const handleCloseAddNewPatientPopup = (): void => {
    setShowAddNewPatientClickPopup(false);
  };




  const handlePageChange = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);
  };
  const openAddNewPatient = () => {
    // setShowPopup(false)
    setShowAddNewPatientClickPopup(true)
  }

  const filteredPatients = patientData.filter((patient) =>
    Object.values(patient).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );





  const filteredSearchPatients = patientSearchList?.filter((patient) =>
    Object.values(patient).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const searchList = filteredSearchPatients?.slice(indexOfFirstItem, indexOfLastItem);


  const dob = (dob) => moment(dob).format('L')

  const handleCancel = () => {
    setPatientSearchList(null)
    setRows([{ searchVariable: '', searchMode: 'equals', searchValue: '' }])
  }


  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };


  return (
    <>
      <Modal show={open} onHide={handleClose} size='xl' centered>
        <Modal.Header className='dialoge px-5 py-1' closeButton>
          <Modal.Title>Patient Search</Modal.Title>
        </Modal.Header>
        <Modal.Body className='dialoge px-4 py-1'>
          <div className='card p-3 m-0'>

            <div className=''>
              {rows.map((row, index) => (
                <div key={index} className=' mb-2 row' >
                  <div className='col-md-6 col-lg-3'>
                    <select
                      className='form-select SearchInputbox p-2'

                      value={row.searchVariable}

                      onChange={(e) => handleSelectChange(e, index)}
                    >
                      {renderSelectOptions(index)}

                    </select>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <select
                      className='form-select SearchInputbox   p-2'

                      value={row.searchMode}
                      onChange={(e) => {
                        const updatedRows = [...rows]
                        updatedRows[index].searchMode = e.target.value
                        setRows(updatedRows)
                      }}
                    >
                      {
                        (row.searchVariable == 4) || (row.searchVariable == 3) || (row.searchVariable == 5) ? (
                          <>
                            <option value='' hidden selected>Please Select </option>
                            <option value='equals'   >Equals To</option>
                          </>
                        ) :
                          (
                            <>
                              <option value='' hidden selected>Please Select </option>
                              <option value='startsWith' >Starts With</option>
                              <option value='equals' >Equals To</option>
                              <option value='contains'>Contains</option>
                            </>
                          )
                      }
                    </select>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    {
                      (row.searchVariable == 1 || row.searchVariable == 2 || row.searchVariable == 4) &&
                      <input
                        type={row.searchVariable == 4 ? 'number' : 'text'}
                        value={row.searchValue}
                        onChange={(e) => {
                          const updatedRows = [...rows]
                          updatedRows[index].searchValue = e.target.value
                          setRows(updatedRows)
                        }}
                        className='form-control SearchInputbox'
                        name=''
                        placeholder=''
                      />
                    }
                    {
                      (row.searchVariable == 3) &&
                      <MaskedInput
                        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        className='form-control SearchInputbox'
                        onChange={(e) => {
                          const updatedRows = [...rows]
                          updatedRows[index].searchValue = e.target.value
                          setRows(updatedRows)
                        }}
                        value={row.searchValue}
                        placeholder={inputText.SSN.placeholder}
                      />
                    }
                    {
                      (row.searchVariable == 5) &&
                      <>
                      <input
                        type='date'
                        className='form-control SearchInputbox'
                        onChange={(e) => {
                          const updatedRows = [...rows]
                          updatedRows[index].searchValue = e.target.value
                          
                          setRows(updatedRows)
                        }}
                        value={row.searchValue}

                      />
                             {/* <Datepicker 
                      selectedDate={row.searchValue || ''}
                      gettingDate={(e) => {
                        const updatedRows = [...rows]
                        updatedRows[index].searchValue = e
                        setRows(updatedRows)
                      }}
                      disabled={false}
                      /> */}
                      </>

                    }
                    {
                      (row.searchVariable == 6) &&
                      <input
                        type='number'
                        className='form-control SearchInputbox'
                        onChange={(e) => {
                          const updatedRows = [...rows]
                          updatedRows[index].searchValue = e.target.value
                          setRows(updatedRows)
                        }}
                        value={row.searchValue}
                      />
                    }

                  </div>
                  <div className='col-md-6 col-lg-3 '>
                    {index === 0 ? (
                      <div className='d-flex gap-2'>

                        <button className='primarybtn' onClick={() => handleAddRow()}>
                          +
                        </button>

                        <button
                          className='primarybtn'
                          onClick={handleShowTable}
                        >
                          Search
                        </button>

                        <button
                          className='grayButton'
                          onClick={handleCancel}
                        >
                          Clear
                        </button>

                      </div>
                    ) : (
                      <button

                        className='grayButton fw-bold'
                        onClick={() => handleDeleteRow(index)}
                      >
                        -
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {showTable && ( // Conditionally render the table

              patientSearchList ?
                <div>
                  <div className="pb-2  d-flex justify-content-between">
                    <div className="input-group " style={{ width: '130px' }}>
                      <span className="input-group-text" style={{ height: '35px', width: '55px' }}>Show </span>
                      <select className="form-select " onChange={handlePerPageChange} defaultValue={perPage} style={{ height: '35px', width: '50px' }}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{ height: '35px', width: '200px' }}
                    />
                  </div>

                  <table className='  w-100 fullCurve  '>
                    <thead>
                      <tr className='tableHead'>
                        <th >
                        </th>
                        <th >MRN </th>
                        <th >Name</th>
                        <th >Phone</th>
                        <th>SSN</th>
                        <th>Date of Birth</th>
                        <th>Sex</th>
                        <th >
                          Primary insurance
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        searchList?.map((patient: any, index: number) => {

                          const dob = (Date) => {
                            let date = moment(Date).format('DD/MM/yyyy');
                            return date
                          }

                          let decrypted = (inputText) => {
                            try {
                                return inputText ? CryptoJS.AES.decrypt(inputText, 'reyEhr').toString(CryptoJS.enc.Utf8) : "";
                            } catch (error) {
                                console.error("Error decrypting:", error);
                                return ""; // or handle the error in a different way
                            }
                        };

                          return (

                            <tr key={index} className='tableBody ' >
                              <td className='px-2'><button className='blueButtonSmall' onClick={() => getThePatientId(patient._id)}><i className="fa-solid fa-arrow-pointer"></i>  Select</button></td>
                              <td className='' title={patient?.mrn}>{patient?.mrn}</td>
                              <td title={`${patient?.firstName} ${patient?.middleName !== null ? patient?.middleName : ''} ${patient?.lastName}`}>{patient.firstName} {patient?.middleName !== null ? patient?.middleName : ''} {patient.lastName}</td>
                              <td title={`${patient?.mobileCountryCode} ${decrypted(patient?.mobileNo)}`}>{patient?.mobileCountryCode} {decrypted(patient?.mobileNo)}</td>
                              <td title={decrypted(patient?.ssn)}>{decrypted(patient?.ssn)}</td>
                              <td title={`${dob(patient.dob)}`}>{dob(patient.dob)}</td>
                              <td title={patient?.genderData?.gender}>{patient?.genderData?.gender}</td>
                              <td></td>
                            </tr>
                          )
                        })}

                    </tbody>
                  </table>
                  <div className={totalsearchPatient ? 'd-flex justify-content-between align-item-center' : "d-flex justify-content-center"}>

                    <div className={'d-flex align-item-center py-2 '}>
                      <span className='patient-info-title'>
                        {totalsearchPatient ? `Total ${totalsearchPatient} entries` : 'No records found'}
                      </span>
                    </div>

                    {/* Pagination */}
                    <div className='d-flex'>

                      {totalsearchPatient > perPage && (

                        <nav className='py-2' aria-label="Page navigation example">
                          <ul className="pagination justify-content-center">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                              <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>
                            </li>
                            {Array.from(Array(Math.ceil(totalsearchPatient / perPage)).keys()).map((number) => (
                              <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                                  {number + 1}
                                </button>
                              </li>
                            ))}
                            <li className={`page-item ${currentPage === Math.ceil(totalsearchPatient / perPage) ? 'disabled' : ''}`}>
                              <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>
                            </li>
                          </ul>
                        </nav>
                      )}

                      {/* <PaginationCompo
                        totalData={totalsearchPatient}
                        perPage={perPage}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                      /> */}
                    </div>
                  </div>

                </div>
                :
                <div>
                  <div className="pb-2  d-flex justify-content-between">
                    <div className="input-group" style={{ width: '130px' }}>
                      <span className="input-group-text" style={{ height: '35px', width: '55px' }}>Show </span>
                      <select className="form-select" onChange={handlePerPageChange} defaultValue={perPage} style={{ height: '35px', width: '50px' }}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{ height: '35px', width: '200px' }}
                    />
                  </div>
                  <table className='  w-100 fullCurve  '>
                    <thead>
                      <tr className='tableHead'>
                        <th >
                        </th>
                        <th onClick={() => handleSort('mrn')}>MRN</th>
                        <th onClick={() => handleSort('firstName')}>Name</th>
                        <th onClick={() => handleSort('mobileNo')}>Phone</th>
                        <th onClick={() => handleSort('ssn')}>SSN</th>
                        <th onClick={() => handleSort('dob')}>Date of Birth</th>
                        <th onClick={() => handleSort('genderData.gender')}>Sex</th>
                        <th>Primary insurance</th>
                      </tr>
                    </thead>
                    <tbody>

                      {

                        filteredPatients?.map((patient: any, index: number) => {

                          const dob = (Date) => {
                            let date = moment(Date).format('DD/MM/yyyy');
                            return date
                          }

                          
                          let decrypted = (inputText) => inputText ? CryptoJS.AES.decrypt(inputText, 'reyEhr').toString(CryptoJS.enc.Utf8) : '';


                          return (

                            <tr key={index} className='tableBody ' >
                              <td className='px-2'><button className='blueButtonSmall' onClick={() => getThePatientId(patient._id)}><i className="fa-solid fa-arrow-pointer"></i>  Select</button></td>
                              <td className='' title={patient?.mrn}>{patient?.mrn}</td>
                              <td title={`${patient?.firstName} ${patient?.middleName !== null ? patient?.middleName : ''} ${patient?.lastName}`}>{patient.firstName} {patient?.middleName !== null ? patient?.middleName : ''} {patient.lastName}</td>
                              <td title={`${patient?.mobileCountryCode} ${decrypted(patient?.mobileNo)}`}>{patient?.mobileCountryCode} {decrypted(patient?.mobileNo)}</td>
                              <td title={decrypted(patient?.ssn)}>{decrypted(patient?.ssn)}</td>
                              <td title={`${dob(patient.dob)}`}>{dob(patient.dob)}</td>
                              <td title={patient?.genderData?.gender}>{patient?.genderData?.gender}</td>
                              <td></td>
                            </tr>
                          )
                        })

                      }

                    </tbody>
                  </table>

                  <div className='d-flex justify-content-between align-item-center'>

                    <div className='d-flex align-item-center py-2 '>
                      <span className='patient-info-title'>
                        {
                          totalPatient !== 0 ? `Total ${totalPatient} entries` : `No Record`
                        }
                      </span>

                    </div>
                    {/* Pagination */}
                    {/* {totalPatient > perPage && (
                      <nav className='py-2' aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&lt;</button>
                          </li>
                          {Array.from(Array(Math.ceil(totalPatient / perPage)).keys()).map((number) => (
                            <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                                {number + 1}
                              </button>
                            </li>
                          ))}
                          <li className={`page-item ${currentPage === Math.ceil(totalPatient / perPage) ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&gt;</button>
                          </li>
                        </ul>
                      </nav>
                    )} */}

                    <PaginationCompo
                      totalData={totalPatient}
                      perPage={perPage}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                    />
                  </div>

                </div>

            )}
          </div>

        </Modal.Body>

        <Modal.Footer className='model-footer-color'>
          <Button className='btn-sm btn-search-form'
            onClick={openAddNewPatient}
          >
            Add New Patient
          </Button>
          <Button className='btn-sm btn-search-form'
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      {showAddNewPatientClickPopup && (

        <AddNewPatient
          open={showAddNewPatientClickPopup}
          closeAddNewPatient={handleCloseAddNewPatientPopup}
        />

      )}
    </>
  )
}

export default PatientSearchInApp