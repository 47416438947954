import React, { useState } from 'react'
import DownArrow from '../EHRIcon/DownArrow.png'
import UpArrow from '../EHRIcon/UpArrow.png'
import AppointmentPageHeader from '../ReusablePages/ReusableAppointment/AppointmentPageHeader'
import PatientIdCard from '../ReusablePages/ReusableAppointment/PatientIdCard'
import { spacing } from 'react-select/dist/declarations/src/theme'

function PatientDiagnosis() {

    const [illnessDown, setIllnessDown] = useState(false)
    const [selectTable, setSelectTable] = useState(1);


    const [preferableListData, setPreferableData] = useState([
        {
            pld: "G62.9-Neuropathy"
        },
        {
            pld: "M15.9-Generalized osteoarthrosis, unspecified site"
        },
        {
            pld: "E78.5-Hyperlipidemia"
        },
        {
            pld: "110-Hypertension, essential"
        },
        {
            pld: "N18.9-Chronic kidney failure"
        },

    ])

    const [previousEnData, setPreviousEnData] = useState([
        {
            VisitDate: "03/09/2015",
            icd: "125.10",
            illness: "Atrial fibrillation",
            illnessStatus: ""
        },
        {
            VisitDate: "03/09/2015",
            icd: "125.10",
            illness: "Atrial fibrillation",
            illnessStatus: ""
        },
        {
            VisitDate: "03/09/2015",
            icd: "125.10",
            illness: "Atrial fibrillation",
            illnessStatus: ""
        },
    ])



    return (
        <div>
            <AppointmentPageHeader name='Diagnosis' />
            <PatientIdCard />

            <div className='card mt-2  p-2 border '>
                <div className='border-bottom'>
                    <h2>
                        General Notes
                    </h2>
                </div>

                <div className='row  p-2 ' >
                    <div className=' col-md-12 col-lg-6 justify-content-start d-flex gap-2 pt-2'>

                        <div>
                            <button
                                onClick={() => setSelectTable(1)}
                                className={selectTable === 1 ? 'switchViewbtnLeft switchViewbtnActive' : 'switchViewbtnLeft'}>
                                Preferable list
                            </button>
                            <button
                                onClick={() => setSelectTable(2)}
                                className={selectTable === 2 ? "switchViewbtn2nd switchViewbtnActive" : "switchViewbtn2nd"}>
                                Frequently Used

                            </button>
                            <button
                                onClick={() => setSelectTable(3)}
                                className={selectTable === 3 ? "switchViewbtn2nd switchViewbtnActive" : "switchViewbtn2nd"}>
                                Previous Encounter

                            </button>
                            <button
                                onClick={() => setSelectTable(4)}
                                className={selectTable === 4 ? "switchViewbtn2nd switchViewbtnActive" : "switchViewbtn2nd"}
                            >
                                Problem List

                            </button>

                            <button
                                onClick={() => setSelectTable(5)}
                                className={selectTable === 5 ? 'switchViewbtnRight switchViewbtnActive' : 'switchViewbtnRight'}
                            >
                                Category
                            </button>
                        </div>
                        <div>
                            <button type="button" className='blueButton'>
                                Review
                            </button>
                        </div>

                    </div>



                </div>

                <div className='card w-100'>
                    <div className={illnessDown ? 'PatientChartCardHeader ' : "PatientChartCardHeadercondition"}>
                        <div>
                            <span>
                                Illness
                            </span>
                        </div>
                        <div className='PatientChartCardHeaderIcon'
                            onClick={() => setIllnessDown(!illnessDown)}
                        >
                            {
                                illnessDown ?
                                    <img src={UpArrow} alt="" /> :
                                    <img src={DownArrow} alt="" />
                            }
                        </div>
                    </div>

                    {

                        illnessDown &&
                        <div>
                            {

                                selectTable === 1 &&
                                <div>

                                    <table className='w-100'>
                                        <tbody>

                                            {
                                                preferableListData.map((item) => (

                                                    <tr className='tableBody'>
                                                        <td>
                                                            <input type="checkbox" />
                                                        </td>

                                                        <td>
                                                            <span>{item.pld} </span>
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" />
                                                        </td>
                                                        <td>
                                                            <span> 2 </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>

                                    <div className='PatientChartCardFooter'>
                                        <div>
                                            {
                                                preferableListData.length === 0 ?
                                                    <span>
                                                        No Record(s) found.
                                                    </span> :
                                                    <span>
                                                        Total Numbers of Records : {preferableListData.length}
                                                    </span>
                                            }
                                        </div>
                                        <div>
                                            pagination ...
                                        </div>
                                    </div>
                                </div>

                            }
                            {

                                selectTable === 3 &&
                                <div>

                                    <table className='w-100'>
                                        <thead>
                                            <tr className='tableHead'>
                                                <th>
                                                </th>
                                                <th>
                                                    visit Date
                                                </th>
                                                <th>
                                                    ICD-10
                                                </th>
                                                <th>
                                                    illness
                                                </th>
                                                <th>
                                                    illness Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                previousEnData.map((item) => (

                                                    <tr className='tableBody'>
                                                        <td>
                                                            <input type="checkbox" />
                                                        </td>

                                                        <td>
                                                            {item.VisitDate}
                                                        </td>
                                                        <td>
                                                            {item.icd}
                                                        </td>
                                                        <td>
                                                            {item.illness}
                                                        </td>
                                                        <td>
                                                            {item.illnessStatus}
                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>

                                    <div className='PatientChartCardFooter'>
                                        <div>
                                            {
                                                previousEnData.length === 0 ?
                                                    <span>
                                                        No Record(s) found.
                                                    </span>
                                                    :
                                                    <span>
                                                        Total Numbers of Records : {previousEnData.length}
                                                    </span>
                                            }
                                        </div>
                                        <div>
                                            pagination ...
                                        </div>
                                    </div>
                                </div>

                            }
                            {

                                selectTable === 4 &&
                                <div>

                                    <table className='w-100'>
                                        <thead>
                                            <tr className='tableHead'>
                                                <th>
                                                </th>
                                                <th>
                                                    Problem From
                                                </th>
                                                <th>
                                                    Problem
                                                </th>
                                                <th>
                                                    ICD-10
                                                </th>
                                                <th>
                                                    Problem Status
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    Date Onset
                                                </th>
                                                <th>
                                                    Provider
                                                </th>
                                                <th>
                                                    Notes
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                previousEnData.map((item) => (

                                                    <tr className='tableBody'>
                                                        <td>
                                                            <input type="checkbox" />
                                                        </td>

                                                        <td>
                                                            {item.VisitDate}
                                                        </td>
                                                        <td>
                                                            {item.icd}
                                                        </td>
                                                        <td>
                                                            {item.illness}
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>

                                    <div className='PatientChartCardFooter'>
                                        <div>
                                            {
                                                previousEnData.length === 0 ?
                                                    <span>
                                                        No Record(s) found.
                                                    </span>
                                                    :
                                                    <span>
                                                        Total Numbers of Records : {previousEnData.length}
                                                    </span>
                                            }
                                        </div>
                                        <div>
                                            pagination ...
                                        </div>
                                    </div>
                                </div>

                            }
                            {

                                selectTable === 5 &&
                                <div>
                                    <div className='' style={{width: "400px" ,  position: 'relative' , padding: "5px"  }} >

                                   <input type="text" 
                                   className='form-control  SearchInputbox '
                                   placeholder='Categories'
                                   />
                                   <button 
                                    style={{   
                                        position: 'absolute',
                                        top: '50%',
                                        right: '6px',
                                        border: "none",
                                        borderRadius: "0px 5px 5px 0px",
                                        height: "29px",
                                        width: "29px",
                                        transform: 'translateY(-50%)',
                                        backgroundColor: "#DCDCDC" , 
                                        color : "black"
                                      }}
                                   >
                                   <i className="fa-solid fa-magnifying-glass"></i>
                                   </button>
                                   </div>
                                  
                                </div>

                            }
                        </div>
                    }

                </div>


            </div>
        </div>
    )
}

export default PatientDiagnosis
