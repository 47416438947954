import React from 'react'

function PatientTreatmentPlan() {
    return (
        <>
            <h1>
                PatientTreatmentPlan
            </h1>
        </>
    )
}

export default PatientTreatmentPlan