import React from 'react'

function AnalyticalReports() {
  return (
    <div>
      <h1>
      AnalyticalReports
      </h1>
    </div>
  )
}

export default AnalyticalReports
