import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AddNewDocument from "../Patients/ReusablePatients/AddNewDocument";
import openYellowFolder from '../EHRIcon/OpenFolderIcon.svg'
import closeYellowFolder from '../EHRIcon/CloseFolderIcon.svg'
import openBlueFolder from '../EHRIcon/OpenBlueFolder.svg'
import closeBlueFolder from '../EHRIcon/CloseBlueFolder.svg'

export function DocumentCenterMenu() {

    const APIkey = process.env.REACT_APP_SERVERAPI;

    const inputRef = useRef(null);

    const [folderList, setFolderList] = useState<any>([])

    const [addFolderfield, setAddFolderfield] = useState<any>(false)
    const [folderName, setFolderName] = useState<any>('New Folder')

    const [loadFolder, setLoadFolder] = useState<any>(false)
    const [folderNameErr, setFolderNameErr] = useState<any>(false)
    const [openFolder, setOpenFolder] = useState<any>()

    const [addDocumentsPopUp, setAddDocumentsPopUp] = useState<any>(false)

    useEffect(() => {
        const getTheFolder = async () => {
            try {
                let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

                if (getloginInfo !== null) {

                    const Token = JSON.parse(getloginInfo)?.data?.accessToken;

                    const Id = JSON.parse(getloginInfo).data.clinicId;

                    const userId = JSON.parse(getloginInfo).data.id;

                    const header = {
                        headers: { 'x-access-token': Token }
                    }

                    const getallFolders = await axios.get(`${APIkey}/api/admin/getFolderList/${Id}`, header)

                    console.log("getallFolder :", getallFolders?.data?.data);

                    setFolderList(getallFolders?.data?.data);

                }
                else {
                    console.log("No login info found in localStorage");
                }
            }
            catch (err) {
                console.log(err);
            }
        }

        getTheFolder();
    }, [addFolderfield, loadFolder])

    const addFolder = async (data) => {
        try {
            const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

            if (getloginInfo !== null) {
                const Token = JSON.parse(getloginInfo).data.accessToken;
                const header = {
                    headers: { 'x-access-token': Token },
                };

                const response = await axios.post(`${APIkey}/api/admin/createFolder`, data, header);
                console.log('Response : ', response);

                if (response) {
                    setLoadFolder(false);
                    toast.success('Folder added successfully!', {
                        position: 'top-right'
                    });
                }
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    const handleAddfield = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();

            let data = {
                folderName
            }
            setLoadFolder(true)

            if (!folderName || folderName.trim() === '' || folderName.trim() == 'New Folder') {

                setFolderNameErr(true);

                setLoadFolder(false)

            } else {

                addFolder(data);

                setFolderNameErr(false)
                setAddFolderfield(false);
                setFolderName('New Folder');
            }

        }
    }



    const enterTheFolder = useCallback((folderId) => {
        if (openFolder === folderId) {
            setOpenFolder(null);
        } else {
            setOpenFolder(folderId);

            

        }
    }, [openFolder]);

    const closeDocumentPopUp = () => {
        setAddDocumentsPopUp(false)
    }

    return (
        <>
            <div className="mt-2 PageHeader">
                <div>
                    <span className="PageHeaderTitle">Documents</span>
                </div>
                <div>
                    <button
                        onClick={() => setAddDocumentsPopUp(true)}
                        className="blueButton" >
                        Add Document
                    </button>
                </div>
            </div>




<div className="row">


            <div className="col-md-2" >

                <div className=" card py-2 px-1   ">
                    <div className="d-flex justify-content-end align-items-center my-1 ">
                        <button className="blueButtonSmall px-3" onClick={() => setAddFolderfield(true)}>
                            <i className="fa-solid fa-plus " ></i>
                        </button>
                    </div>
                    {
                        loadFolder ?
                            <div className="d-flex justify-content-center">
                                <div className='loader'></div>
                            </div>
                            :
                            <ul className="appointmentUl px-1">

                                {
                                    folderList?.map((i: any, index: any) => {
                                        return (
                                            <>
                                                <li key={index}
                                                    className="d-flex gap-4 mx-1 justify-content-start align-items-center "
                                                    onClick={() => enterTheFolder(i?._id)}
                                                >
                                                    <div>
                                                        {/* <img src={closeFolder} alt="" className="folderIcon" /> */}

                                                        {
                                                            i?.userGenerated === true ?
                                                                (openFolder == i?._id ?
                                                                    <img src={openBlueFolder} alt="" /> :
                                                                    <img src={closeBlueFolder} alt="" />)
                                                                : (openFolder == i?._id ?
                                                                    <img src={openYellowFolder} alt="" /> :
                                                                    <img src={closeYellowFolder} alt="" />)
                                                        }
                                                    </div>
                                                    <div className="elipsisText">
                                                        <span className="folderName ">
                                                            {i?.folderName}
                                                        </span>
                                                    </div>
                                                </li>
                                            </>

                                        )

                                    })

                                }


                                {
                                    addFolderfield && (

                                        <li
                                            className="d-flex gap-4 mx-1 justify-content-start align-items-center "
                                        >

                                            <img src={closeYellowFolder} alt="" />

                                            <input
                                                style={folderNameErr ? { borderColor: "red" } : { borderColor: "" }}
                                                className="form-control SearchInputbox"
                                                autoFocus
                                                ref={inputRef}
                                                onChange={(e) => setFolderName(e.target.value)}
                                                onBlur={() => [setAddFolderfield(false), setFolderNameErr(false), setFolderName("New Folder")]}
                                                onKeyDown={handleAddfield}
                                                onFocus={e => e.target.select()}
                                                value={folderName}
                                                type="text"
                                                name=""
                                                id=""
                                            />

                                        </li>
                                    )

                                }
                            </ul>
                    }
                </div>

            </div>

            <div className="col-md-10">


                <div className=" card p-2">
                    <div className="row my-1 align-items-center">


                        <div className="col-lg-2 ">
                            <input type="text"
                                className="form-control SearchInputbox"
                                placeholder="Category"
                            />
                        </div>
                        <div className="col-lg-2 ">
                            <input type="text"
                                className="form-control SearchInputbox"
                                placeholder="Patient ID"
                            />


                        </div>
                        <div className="col-lg-2 ">
                            <input type="text"
                                className="form-control SearchInputbox"
                                placeholder="Patient Name"
                            />
                        </div>
                        <div className="col-lg-2 ">
                            <input type="text"
                                placeholder="Description"
                                className="form-control SearchInputbox" />
                        </div>
                        <div className="col-lg-2  d-flex gap-2 ">
                            <button className="blueButton">
                                Search
                            </button>
                            <button className="grayButton">
                                Clear
                            </button>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-3 offset-md-9">
                            <input type="text" className=" form-control SearchInputbox" />
                        </div>

                    </div>

                    <div className="card mt-2 ">

                        <table className='w-100 fullCurve' >
                            <thead >
                                <tr className='tableHead'>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        Time
                                    </th>
                                    <th>
                                        Document Name
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Document Tags
                                    </th>
                                    <th>
                                        Document Type
                                    </th>
                                    <th>
                                        Uploaded On
                                    </th>
                                    <th>
                                        Uploaded By
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='tableBody'>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>

                                    <td>
                                        <div className='d-flex align-items-center gap-2 my-1'>
                                            <button className='blueButton'>
                                                <i className="fa-solid fa-pen"></i>
                                            </button>
                                            <button className='blueButton'>
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>




                </div>

            </div>
            </div>


            {
                addDocumentsPopUp &&
                <AddNewDocument
                    disabled={false}
                    open={addDocumentsPopUp}
                    closeDocumentPopUp={closeDocumentPopUp}
                    patientData={''}
                />
            }
            <ToastContainer />
        </>
    )
}