import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import UpArrow from '../../EHRIcon/UpArrow.png'
import DownArrow from '../../EHRIcon/DownArrow.png'
import axios from 'axios'

function PatientHistoryLogPopUp({ open, closePopUp, AppointmentId }) {

    const APIkey = process.env.REACT_APP_SERVERAPI;


    const [openAppointmentDetails, setOpenAppointmentDetails] = useState<any>(false)

    const [historyLog, setHistoryLog] = useState<any>([])



    const handelClose = () => {
        closePopUp()
    }


    const getHistoryList = async () => {
        try {
            let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;


                const Id = JSON.parse(getloginInfo).data.clinicId;

                const header = {
                    headers: { 'x-access-token': Token }
                }


                const patientHistoryLogRequest = await axios.get(`${APIkey}/api/admin/patientHistoryLog/${AppointmentId}`, header);

                let patientHistoryLogData = patientHistoryLogRequest?.data?.data;

                setHistoryLog(patientHistoryLogData);



            }
            else {
                console.log("No login info found in localStorage");
            }
        }
        catch (err) {
            console.log(err);
        }

    }


    useEffect(() => {



        getHistoryList();


    }, [])

    return (
        <>
            <Modal
                show={open}
                onHide={handelClose}
                className=''
                size='lg'
                centered
            >
                <Modal.Header className='dialoge px-6 py-3' closeButton>
                    <Modal.Title >
                        History
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='dialoge px-4 py-0'>

                    <div>
                        <div className='card'>
                            <div className={openAppointmentDetails ? 'PatientChartCardHeader' : 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <span className=" " >
                                        Patient Details
                                    </span>
                                </div>

                                <div className="d-flex">

                                    <div className="px-2 PatientChartCardHeaderIcon"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setOpenAppointmentDetails(!openAppointmentDetails)}
                                    >
                                        {
                                            openAppointmentDetails ?
                                                <img src={UpArrow} alt="" /> :
                                                <img src={DownArrow} alt="" />
                                        }
                                    </div>
                                    <div>

                                    </div>
                                </div>


                            </div>

                            {
                                openAppointmentDetails &&
                                (

                                    <table className=''>
                                        <thead>
                                            <tr className='tableHead'>
                                                <th>
                                                    &nbsp; Field Name
                                                </th>
                                                <th>
                                                    Old Value
                                                </th>
                                                <th>
                                                    Changed Value
                                                </th>
                                            </tr>
                                        </thead>
                                        {
                                            historyLog?.map((i) => {

                                                return (
                                                    <tr className='tableBody'>
                                                        <td>
                                                            &nbsp; {i?.variable}
                                                        </td>
                                                        <td>
                                                            {i.oldValue}
                                                        </td>
                                                        <td>
                                                            {i.newValue}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                        </tr>
                                    </table>
                                )

                            }

                        </div>

                        <div className='card mt-2'>
                            <div className={ 'PatientChartCardHeadercondition'}>
                                <div className="d-flex" >
                                    <span className=" " >
                                       {/* {createdBy} */}
                                    </span>
                                </div>

                           

                            </div>
                        </div>

                    </div>

                </Modal.Body>

                <Modal.Footer className='dialoge'>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PatientHistoryLogPopUp