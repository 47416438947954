import React, { useEffect, useState } from 'react'
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';



function ContactComponent(
    // { getTheCellNumber,
    //     getTheWorkNumber,
    //     getTheEmail,
    //     getTheExt,
    //     emailValue,
    //     contactno1Value,
    //     contactno2Value,
    //     contactno3Value, 
    //     extValue,
    //     faxValue,
    //     emailErr,
    //     cellNoErr,
    //     workNoErr,
    //     extErr,
    //     col
    // }
    props
) {





    const reversestr = (number, code) => {


        if (typeof number === 'string') {

            var splitString = number.split("");

            // var reverseArray = splitString.reverse();

            // var getreverse10 = reverseArray.slice(0, 10);
            var getThe10digit = splitString.slice(code.length)

            var getTheNumber = getThe10digit.join("")


            // var getfirst10 = getreverse10.reverse();
            // var getafter10 = getreversecode.reverse();

            // var getTheNumber = getfirst10.join("");

            return {
                getTheNumber,
                gettheCode: `+${code}`
            }
        }

    }



    const getcellNumber = (value, country) => {

        props.getTheCellNumber(reversestr(value, country.dialCode))

    }

    const getWorkNumber = (w, country) => {
        props.getTheWorkNumber(reversestr(w, country.dialCode))
    }

    const getcHomeNumber = (h, country) => {
        props.getTheHomeNumber(reversestr(h, country.dialCode))
    }

    const getEmail = (em) => {
        props.getTheEmail(em)
    }

    const getExt = (ex) => {
        const maxLength = 4; // Maximum number of characters allowed
        let value = ex.target.value; // Get the value entered by the user

        // If the length of the value exceeds the maximum length, trim it
        if (value.length > maxLength) {
            value = value.slice(0, maxLength);
        }
        props.getTheExt(value)
    }

    const getFax = (eF, country) => {

        props.getTheFax(reversestr(eF, country.dialCode))


    }









    return (
        <>
            <div className={`col-md-6  col-lg-${props.col} mb-4 `}>

                <label htmlFor="" className='patient-info-title required'>{inputText.email.label}</label>
                <input
                    type="email"
                    value={props.emailValue}
                    maxLength={250}
                    onChange={getEmail}
                    className='form-control SearchInputbox'
                />
                {
                    props.emailErr && (
                        <span className='form-label errMsg'>
                            <i className="fa-solid fa-triangle-exclamation"></i>
                            &nbsp;
                            {/* {inputText.email.ErrMsg} */}
                            {props.emailErr}
                        </span>
                    )
                }
            </div>

            <div className={`col-md-6  col-lg-${props.col} mb-4 `}>
                <div className='W-100'>
                    <label htmlFor="" className='patient-info-title required'>{inputText.cellNo.label}</label>
                    {/* <div className='d-flex gap-1 w-100'>
                <div className='w-25'>

                    <SearchableDropdown placeHolder="+1"  />
                </div>
                <input type="text" placeholder={inputText.cellNo.placeholder} className='form-control SearchInputbox' />
            </div> */}

                    
                    <PhoneInput
                    
                        country={'us'}
                        value={props.contactno1Value}
                        onChange={getcellNumber}
                    />

                    {
                        props.cellNoErr && (
                            <span className='form-label errMsg'>
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                &nbsp;
                                {/* {inputText.cellNo.ErrMsg} */}
                                {props.cellNoErr}
                            </span>
                        )
                    }
                </div>
            </div>
            {
                props.adminfield == 1 ?


                    <div className={`col-md-6  col-lg-${props.col} d-flex gap-1  mb-4 `}>
                        <div style={{ width: "80%" }} >

                            <label htmlFor="" className='patient-info-title '>{inputText.WorkPhone.label}</label>
                            <PhoneInput
                                // placeholder={inputText.WorkPhone.ErrMsg}
                                
                                country={'us'}
                                value={props.contactno2Value}
                                onChange={getWorkNumber}
                            />

                            <div className='d-flex gap-2'>
                                {
                                    props.workNoErr && (
                                        <span className='form-label errMsg'>
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {/* {inputText.WorkPhone.ErrMsg} */}
                                            {props.workNoErr}
                                        </span>
                                    )
                                }
                            </div>

                        </div>
                        <div className='' style={{ width: "20%" }}>

                            <label htmlFor="" className='patient-info-title '>{inputText.ex.label}</label>

                            <div className='w-100 d-flex gap-1 align-items-end'>

                                <input
                                    type="number"
                                    value={props.extValue}
                                    onChange={getExt}
                                    placeholder={inputText.ex.placeholder} className='form-control SearchInputbox' />

                            </div>
                            {
                                props.extErr && (
                                    <span className='form-label errMsg'>
                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                        &nbsp;
                                        {/* {inputText.WorkPhone.ErrMsg} */}
                                        {props.extErr}
                                    </span>
                                )
                            }
                        </div></div>

                    : <div className={`col-md-6  col-lg-${props.col} mb-4  `}>
                        <div className='w-100'>
                            <label htmlFor="" className='patient-info-title '>{inputText.homeNo.label}</label>
                            {/* <div className='d-flex gap-1 w-100'>
                <div className='w-25'>

                    <SearchableDropdown placeHolder="+1"  />
                </div>
                <input type="text" placeholder={inputText.cellNo.placeholder} className='form-control SearchInputbox' />
            </div> */}


                            <PhoneInput
                                country={'us'}
                                value={props.contactno3Value}
                                onChange={getcHomeNumber}
                            />

                            {
                                props.homeNoErr && (
                                    <span className='form-label errMsg'>
                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                        &nbsp;
                                        {/* {inputText.cellNo.ErrMsg} */}
                                        {props.homeNoErr}
                                    </span>
                                )
                            }
                        </div>
                    </div>
            }


            {/* faxErr */}  {
                props.col == 3 && (
                    <>
                        <div className={`col-md-6  col-lg-${props.col}  mb-4 `}>
                            <div className='w-100'>
                                <label htmlFor="" className='patient-info-title'>{inputText.faxNo.label}</label>
                                <PhoneInput
                                    country={'us'}
                                    onChange={getFax}
                                    value={props.faxValue}
                                //  className='form-control SearchInputbox'
                                />
                                {
                                    props.faxErr && (
                                        <span className='form-label errMsg'>
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {/* {inputText.cellNo.ErrMsg} */}
                                            {props.faxErr}
                                        </span>
                                    )
                                }
                            </div>
                        </div>




                        <div className={`col-md-6  col-lg-${props.col} d-flex gap-1  mb-4 `}>
                            <div style={{ width: "80%" }} >

                                <label htmlFor="" className='patient-info-title '>{inputText.WorkPhone.label}</label>
                                <PhoneInput
                                    // placeholder={inputText.WorkPhone.ErrMsg}
                                    country={'us'}
                                    value={props.contactno2Value}
                                    onChange={getWorkNumber}
                                />

                                <div className='d-flex gap-2'>

                                    {
                                        props.workNoErr && (
                                            <span className='form-label errMsg'>
                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                &nbsp;
                                                {/* {inputText.WorkPhone.ErrMsg} */}
                                                {props.workNoErr}
                                            </span>
                                        )
                                    }

                                </div>

                            </div>
                            <div className='' style={{ width: "20%" }}>

                                <label htmlFor="" className='patient-info-title '>{inputText.ex.label}</label>

                                <div className='w-100 d-flex gap-1 align-items-end'>

                                    <input
                                        type="number"
                                        value={props.extValue}
                                        onChange={getExt}
                                        placeholder={inputText.ex.placeholder} className='form-control SearchInputbox' />

                                </div>
                                {
                                    props.extErr && (
                                        <span className='form-label errMsg'>
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {/* {inputText.WorkPhone.ErrMsg} */}
                                            {props.extErr}
                                        </span>
                                    )
                                }
                            </div></div>

                    </>

                )

            }



        </>




    )
}

export default ContactComponent