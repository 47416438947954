import React, {useState, useEffect} from 'react'
import {Navigate, useParams} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment';
// import * as XLSX from 'xlsx';


import Icon9 from '../EHRIcon/VitalsPageIcon9.png'
import Icon10 from '../EHRIcon/VitalsPageIcon10.png'
// import CopyDiagnosis from '../EHRIcon/autoCopyDiagnosisIcon.png'

import PatientIdCard from '../ReusablePages/ReusableAppointment/PatientIdCard'
import AppointmentPageHeader from '../ReusablePages/ReusableAppointment/AppointmentPageHeader'
import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown'
import {toast} from 'react-toastify'
import MultiSelectDropdown from '../ReusablePages/ReusableAppointment/MultiSelectDropdown';

function PatientVitals() {
  const APIkey = process.env.REACT_APP_SERVERAPI || ''
  const {id} = useParams()

  const [vitalsTableData, SetVitalsTableData] = useState<any>([])

  const [ addData , setAddData ] = useState<any>(false)
  

  const [temperature, setTemperature] = useState('')
  const [bpLower, setBpLower] = useState('')
  const [bpUpper, setBpUpper] = useState('')
  const [o2Saturation, setO2Saturation] = useState('')
  const [respirationRate, setRespirationRate] = useState('')
  const [pulse, setPulse] = useState('')
  const [glucoseLevel, setglucoseLevel] = useState('')
  const [hipCircumference, sethipCircumference] = useState('')
  const [hgba1c, sethgba1c] = useState('')
  const [waistCircumference, setwaistCircumference] = useState('')
  const [height, setHeight] = useState<any>()
  const [heightPosition, setHeightPosition] = useState('')
  const [weight, setWeight] = useState<any>()
  const [bmi, setBmi] = useState<string | null>(null)
  const [bsa, setBsa] = useState<string | null>(null)
  const [patientId, setpatientId] = useState<any>('')
  const [visitDate, setVisitDate] = useState('')

  //list variables
  const [weightPosition, setWeightPosition] = useState('')
  const [temperatureUnit, setTempretureUnit] = useState<string | undefined>('')
  const [temperatureUnitList, setTempretureUnitList] = useState([])
  const [o2SaturationUnit, seto2saturationUnit] = useState<string | undefined>('')
  const [o2SaturationUnitList, seto2saturationUnitList] = useState([])
  const [waistCircumferenceUnit, setwaistCircumferenceUnit] = useState<string | undefined>('')
  const [waistCircumferenceUnitList, setwaistCircumferenceUnitList] = useState([])
  const [glucoseMeasureType, setglucoseMeasureType] = useState<string | undefined>('')
  const [glucoseMeasureTypeList, setglucoseMeasureTypeList] = useState([])
  const [hipCircumferenceUnit, sethipCircumferenceUnit] = useState<string | undefined>('')
  const [hipCircumferenceUnitList, sethipCircumferenceUnitList] = useState([])

  const [heightUnit, setheightUnit] = useState('')
  const [weightUnit, setweightUnit] = useState('')
  const [heightUnitList, setheightUnitList] = useState<any>([])
  const [HeightPositionList, setHeightPositionList] = useState([])
  const [weightUnitList, setweightUnitList] = useState<any>([])
  const [WeightPositionList, setWeightPositionList] = useState([])
  const [dxCodesPerBmiId, setdxCodesPerBmiId] = useState<string | undefined>('')
  const [dxCodesPerBmiIdList, setdxCodesPerBmiIdList] = useState([])
  const [bpScreeningId, setbpScreeningId] = useState<any>(null)
  const [bpScreeningIdList, setbpScreeningIdList] = useState([])
  const [bmiScreeningId, setbmiScreeningId] = useState<any>(null)
  const [bmiScreeningIdList, setbmiScreeningIdList] = useState([])
  const [providerId, setproviderId] = useState<string | undefined>('')
  const [providerIdList, setproviderIdList] = useState('')
  const [positionId, setpositionId] = useState('')
  const [positionIdList, setpositionIdList] = useState('')
  const [idealBodyWeight, setidealBodyWeight] = useState(false)
  const [diagnosis, setdiagnosis] = useState('')
  const [autoCopyToDiagnosis, setautoCopyToDiagnosis] = useState(false)
  const [notes, setnotes] = useState('')
  const [vitalDate, setvitalDate] = useState('')
  const [vitalTime, setvitalTime] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [bpUnit, setBpUnit] = useState('')
  const [bpUnitList, setBpUnitList] = useState('')
  const [sendToReview, setsendToReview] = useState(false)
 
  const [loading, setLoading] = useState(false)





  const [errors, setErrors] = useState<any>({
    temperatureUnit: '',
    bpUnit: '',
    o2SaturationUnit: '',
    waistCircumferenceUnit: '',
    heightUnit: '',
    heightPosition: '',
    weightUnit: '',
    weightPosition: '',
    glucoseMeasureType: '',
    hipCircumferenceUnit: '',
    dxCodesPerBmiId: '',
    bpScreeningId: '',
    bmiScreeningId: '',
    providerId: '',
    positionId: '',
  })

  const [msgerrors, setmsgErrors] = useState<any>({
    temperature: '',
    bpLower: '',
    bpUpper: '',
    o2Saturation: '',
    respirationRate: '',
    pulse: '',
    glucoseLevel: '',
    hgba1c: '',
    hipCircumference: '',
    waistCircumference: '',
  })

  

  const tempRangeFahrenheit = '97.8°F - 99.1°F'
  const tempRangeCelsius = '36.5°C - 37.3°C'
  const o2Range = '95% - 100%'
  const BpSystolic = '90/160 mmHg'
  const BpDiastolic = '120/80 mmHg'
  const respirationRange = '12-18 /min'
  const waistRange = 'M(below 37 in) F(below 31.5 in)'
  const pulseRange = '60-100 beats/min'
  const glucoseRangeFasting = '17-100 mg/dl'
  const glucoseRangeNonFasting = '125 mg/dl'
  const hgb = 'below 5.7%'
  const hip = 'M(94-105 cm) F(97-108)'

  
  const exportToCsv = () => {
    // Prepare CSV content
    const headers = [
      'Date', 'BP', 'Pulse', 'HT', 'WT', 'BMI', 'BSA', 'Temp', 'O2', 
      'Resp', 'Waist', 'Hip', 'Glucose', 'HgbA1c', 'Position'
    ];
  
    const csvContent = headers.join(',') + '\n' +
      vitalsTableData.map((item) => {
        return [
          moment(item?.appointmentDate).format('MM/DD/YYYY'),
          `${item?.bpUpper}/${item?.bpLower}`,
          item?.pulse,
          item?.height,
          item?.weight,
          item?.bmi,
          item?.bsa,
          item?.temperature,
          item?.o2Saturation,
          item?.respirationRate,
          item?.waistCircumference,
          item?.hipCircumference,
          item?.glucoseLevel,
          item?.hgba1c,
          item?.positionId
        ].map(field => `"${field}"`).join(',');
      }).join('\n');
  
    // Create download link
    const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'vitals_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
  };
  

  const handleTemperatureChange = (e) => {
    const value = e.target.value
    setTemperature(value)

    if (97.8 <= value && value <= 99.1) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        temperature: '',
      }))
    } else if (36.5 <= value && value <= 37.3) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        temperature: '',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        temperature: 'Temperature out of range.',
      }))
    }
  }

 


  const handleBpUpperChange = (e) => {
    const value = e.target.value
    setBpUpper(value)
    if (value < 90 || value > 160) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        bpUpper: 'Systolic BP out of range.',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        bpUpper: '',
      }))
    }
  }

  const handleBpLowerChange = (e) => {
    const value = e.target.value
    setBpLower(value)
    if (value > 120 || value < 80) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        bpLower: 'Diastolic BP out of range.',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        bpLower: '',
      }))
    }
  }

  const handleO2SaturationChange = (e) => {
    const value = e.target.value
    setO2Saturation(value)
    if (value < 95 || value > 100) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        o2Saturation: 'O2 Saturation out of range.',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        o2Saturation: '',
      }))
    }
  }

  const handlerespirationrateChange = (e) => {
    const value = e.target.value
    setRespirationRate(value)
    if (value < 12 || value > 18) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        respirationRate: 'Respiration rate out of range.',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        respirationRate: '',
      }))
    }
  }

  const handlepulseRangeChange = (e) => {
    const value = e.target.value
    setPulse(value)
    if (value < 60 || value > 100) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        pulse: 'Pulse out of range.',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        pulse: '',
      }))
    }
  }

  const handleGlucoseLevelChange = (e) => {
    const value = e.target.value
    setglucoseLevel(value)
    if (value < 17 || (value > 100 && value !== 125)) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        glucoseLevel: `Glucose Level out of range. Should be within ${glucoseRangeFasting} or ${glucoseRangeNonFasting} for non-fasting.`,
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        glucoseLevel: '',
      }))
    }
  }

  const handleHgba1cChange = (e) => {
    const value = e.target.value
    sethgba1c(value)
    if (value >= 5.7) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        hgba1c: `HgbA1c out of range. Should be ${hgb}.`,
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        hgba1c: '',
      }))
    }
  }

  const handleHipCircumferenceChange = (e) => {
    const value = e.target.value
    sethipCircumference(value)

    if (94 <= value && value <= 105) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        hipCircumference: '',
      }))
    } else if (97 <= value && value <= 108) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        hipCircumference: '',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        hipCircumference: 'Hip Circumference out of range.',
      }))
    }
  }

  const handleWaistCircumferenceChange = (e) => {
    const value = e.target.value
    setwaistCircumference(value)

    if (value <= 37) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        waistCircumference: '',
      }))
    } else if (value <= 31.5) {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        waistCircumference: '',
      }))
    } else {
      setmsgErrors((prevErrors) => ({
        ...prevErrors,
        waistCircumference: 'Waist Circumference out of range.',
      }))
    }
  }

  

  
  const getVitallist = async () => {
    try {
      let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

      if (getloginInfo !== null) {
        const Token = JSON.parse(getloginInfo).data.accessToken
        const Id = JSON.parse(getloginInfo).data.clinicId
        const header = {
          headers: {'x-access-token': Token},
        }

        const appointmentData = await axios.get(
          `${APIkey}/api/admin/getAppointmentById/${id}`,
          header
        )
        // console.log('appointment data', appointmentData.data.data)

        let appointmetDatabyId = appointmentData.data.data

        setpatientId(appointmetDatabyId.patientId)
        setVisitDate(appointmetDatabyId.appointmentDate)

       
        const TempretureList = await axios.get(`${APIkey}/api/admin/getTemperatureUnitList`, header)
        // console.log('temp-', TempretureList)

        setTempretureUnitList(
          TempretureList.data.data.map((i) => ({
            label: i.temperatureUnit,
            value: i.temperatureUnit,
            id: i._id,
          }))
        )

        const BpList = await axios.get(`${APIkey}/api/admin/getBPUnitList`, header)
        // console.log(BpList)

        setBpUnitList(
          BpList.data.data.map((i) => ({
            label: i.bpUnit,
            value: i.bpUnit,
            id: i._id,
          }))
        )

        const getHeightList = await axios.get(`${APIkey}/api/admin/getHeightUnitList`, header)
        // console.log(getHeightList)

        setheightUnitList(
          getHeightList.data.data.map((i) => ({
            label: i.heightUnit,
            value: i.heightUnit,
            id: i._id,
          }))
        )

        const HeightPositionList = await axios.get(
          `${APIkey}/api/admin/getHeightPositionList`,
          header
        )

        setHeightPositionList(
          HeightPositionList.data.data.map((i) => ({
            label: i.heightPosition,
            value: i.heightPosition,
            id: i._id,
          }))
        )

        const WeightPositionList = await axios.get(
          `${APIkey}/api/admin/getWeightPositionList`,
          header
        )

        setWeightPositionList(
          WeightPositionList.data.data.map((i) => ({
            label: i.weightPosition,
            value: i.weightPosition,
            id: i._id,
          }))
        )

        const WeightList = await axios.get(`${APIkey}/api/admin/getWeightUnitList`, header)

        setweightUnitList(
          WeightList.data.data.map((i) => ({
            label: i.weightUnit,
            value: i.weightUnit,
            id: i._id,
          }))
        )

        const o2saturationList = await axios.get(
          `${APIkey}/api/admin/getO2SaturationUnitList`,
          header
        )

        seto2saturationUnitList(
          o2saturationList.data.data.map((i) => ({
            label: i.o2StaturationUnit,
            value: i.o2StaturationUnit,
            id: i._id,
          }))
        )
        const waistList = await axios.get(`${APIkey}/api/admin/getWaistCircumferenceList`, header)

        setwaistCircumferenceUnitList(
          waistList.data.data.map((i) => ({
            label: i.waistCircumference,
            value: i.waistCircumference,
            id: i._id,
          }))
        )

        const glucoselevelList = await axios.get(
          `${APIkey}/api/admin/getGlucoseMeasureList`,
          header
        )

        setglucoseMeasureTypeList(
          glucoselevelList.data.data.map((i) => ({
            label: i.glucoseMeasure,
            value: i.glucoseMeasure,
            id: i._id,
          }))
        )

        const hipcircumferenceList = await axios.get(
          `${APIkey}/api/admin/getHipCircumferenceList`,
          header
        )

        sethipCircumferenceUnitList(
          hipcircumferenceList.data.data.map((i) => ({
            label: i.hipCircumference,
            value: i.hipCircumference,
            id: i._id,
          }))
        )

        const getDXcodeList = await axios.get(`${APIkey}/api/admin/getDxCodesPerBmiIdList`, header)

        setdxCodesPerBmiIdList(
          getDXcodeList.data.data.map((i) => ({
            label: i.dxCodesPerBmi,
            value: i.dxCodesPerBmi,
            id: i._id,
          }))
        )
        const getBpscreeningList = await axios.get(`${APIkey}/api/admin/getBpScreeningList`, header)
        setbpScreeningIdList(
          getBpscreeningList.data.data.map((i) => ({
            label: i.bpScreening,
            value: i.bpScreening,
            id: i._id,
          }))
        )
        const getBmiscreeningList = await axios.get(
          `${APIkey}/api/admin/getBmiScreeningList`,
          header
        )
        setbmiScreeningIdList(
          getBmiscreeningList.data.data.map((i) => ({
            label: i.bmiScreening,
            value: i.bmiScreening,
            id: i._id,
          }))
        )
        const getProviderList = await axios.get(
          `${APIkey}/api/admin/listUsersByClinicId/${Id}`,
          header
        )
        setproviderIdList(
          getProviderList.data.data.map((i) => ({
            value: `${i?.firstName} ${i?.lastName}`,
            label: `${i?.firstName} ${i?.lastName}`,

            id: i._id,
          }))
        )

        const getPositionList = await axios.get(`${APIkey}/api/admin/getPositionList`, header)
        setpositionIdList(
          getPositionList.data.data.map((i) => ({
            label: i.position,
            value: i.position,
            id: i._id,
          }))
        )
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getVitallist()
  }, [])

  useEffect(() => {
    if (!isChecked) {
      const now = new Date()
      setvitalDate(now.toISOString().split('T')[0]) // YYYY-MM-DD format
      setvitalTime(
        now.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: false})
      ) // HH:MM format
    }
  }, [isChecked])

  const getTemperature = (event) => {
    setTempretureUnit(event)
  }

  const getbpUnit = (event) => {
    setBpUnit(event)
  }

  const getO2Saturation = (event) => {
    seto2saturationUnit(event)
  }
  const getWaistCircumference = (event) => {
    setwaistCircumferenceUnit(event)
  }

  const getHeight = (event) => {
    setheightUnit(event)
  }

  const getWeight = (event) => {
    setweightUnit(event)
  }

  const getHeightposition = (event) => {
    setHeightPosition(event)
  }

  const getWeightposition = (event) => {
    setWeightPosition(event)
  }

  // const getnotes = (event) => {
  //   setnotes(event)
  // }

  const handleDateChange = (event) => {
    setvitalDate(event.target.value)
  }

  const handleTimeChange = (event) => {
    setvitalTime(event.target.value)
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  const getRespiration = (event) => {
    setRespirationRate(event)
  }

  const getPulse = (event) => {
    const {value} = event.target
    setPulse(value)
  }

  const getGlucose = (event) => {
    setglucoseMeasureType(event)
  }

  const getautocopy = (event) => {
    setautoCopyToDiagnosis(event)
  }

  // const getHgb = (event) => {
  //   sethgba1c(event)
  // }

  const getHip = (event) => {
    sethipCircumferenceUnit(event)
  }

  const getDxcode = (event) => {
    setdxCodesPerBmiId(event)
  }

  const getidealBodyWeight = (event) => {
    setidealBodyWeight(event)
  }

  const getBpscreening = (event) => {
    setbpScreeningId(event)

    console.log(event)
  }

  const getProvider = (event) => {
    setproviderId(event)
  }

  const getPosition = (event) => {
    setpositionId(event)
  }

  const getBmiscreening = (event) => {
    setbmiScreeningId(event)
  }

  const getbmi = (event) => {
    setBmi(event)
  }

  const getbsa = (event) => {
    setBsa(event)
  }

  
  const handleClear = () => {
    setVisitDate('');
    setTemperature('');
    setTempretureUnit('');
    setBpLower('');
    setBpUpper('');
    setBpUnit('');
    setO2Saturation('');
    seto2saturationUnit('');
    setRespirationRate('');
    setwaistCircumference('');
    setwaistCircumferenceUnit('');
    setPulse('');
    setHeight('');
    setheightUnit('');
    setHeightPosition('');
    setWeight('');
    setweightUnit('');
    setWeightPosition('');
    setglucoseLevel('');
    setglucoseMeasureType('');
    sethgba1c('');
    sethipCircumference('');
    sethipCircumferenceUnit('');
    setbpScreeningId(null);
    setbmiScreeningId(null);
    setdiagnosis('');
    setBmi('');
    setBsa('');
    setdxCodesPerBmiId('');
    setautoCopyToDiagnosis(false);
    setidealBodyWeight(false);
    setproviderId('');
    setpositionId('');
    setnotes('');
    // setVitalDate('');
    // setVitalTime('');
    setsendToReview(false);

    setErrors({});
    setmsgErrors({
      temperature: '',
      bpLower: '',
      bpUpper: '',
      o2Saturation: '',
      respirationRate: '',
      pulse: '',
      glucoseLevel: '',
      hgba1c: '',
      hipCircumference: '',
      waistCircumference: '',
    });
  };

  const addVitals = async (data) => {
    try {
      let getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo !== null) {
        const Token = JSON.parse(getLoginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token },
        };
        const response = await axios.post(`${APIkey}/api/admin/createPatientVitals`, data, header);
        console.log(response.data);

        if (response) {
          toast.success('Patient vital added successfully!', {
            position: 'top-right',
          });

          setAddData(true);
        }
      }
    } catch (err) {
      console.error('Error in addVitals:', err);
      toast.error('Failed to add patient vitals. Please try again later.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;
    let vitalErrors = {
      temperatureUnit: '',
      bpUnit: '',
      o2SaturationUnit: '',
      waistCircumferenceUnit: '',
      heightUnit: '',
      heightPosition: '',
      weightUnit: '',
      weightPosition: '',
      glucoseMeasureType: '',
      hipCircumferenceUnit: '',
      dxCodesPerBmiId: '',
      bpScreeningId: '',
      bmiScreeningId: '',
      providerId: '',
      positionId: '',
    };

    // Validate fields and set errors
    if (!temperatureUnit) {
      vitalErrors.temperatureUnit = 'Please select a temperature unit.';
      hasErrors = true;
    }
    if (!bpUnit) {
      vitalErrors.bpUnit = 'Please select a BP unit.';
      hasErrors = true;
    }
    if (!o2SaturationUnit) {
      vitalErrors.o2SaturationUnit = 'Please select an O2 saturation unit.';
      hasErrors = true;
    }
    if (!waistCircumferenceUnit) {
      vitalErrors.waistCircumferenceUnit = 'Please select a waist circumference unit.';
      hasErrors = true;
    }
    if (!heightUnit) {
      vitalErrors.heightUnit = 'Please select a height unit.';
      hasErrors = true;
    }
    if (!heightPosition) {
      vitalErrors.heightPosition = 'Please select a height position unit.';
      hasErrors = true;
    }
    if (!weightUnit) {
      vitalErrors.weightUnit = 'Please select a weight unit.';
      hasErrors = true;
    }
    if (!weightPosition) {
      vitalErrors.weightPosition = 'Please select a weight position unit.';
      hasErrors = true;
    }
    if (!glucoseMeasureType) {
      vitalErrors.glucoseMeasureType = 'Please select a glucose measure type.';
      hasErrors = true;
    }
    if (!hipCircumferenceUnit) {
      vitalErrors.hipCircumferenceUnit = 'Please select a hip circumference unit.';
      hasErrors = true;
    }
    if (!dxCodesPerBmiId) {
      vitalErrors.dxCodesPerBmiId = 'Please select a dx code per BMI unit.';
      hasErrors = true;
    }
    if (!bpScreeningId.length) {
      vitalErrors.bpScreeningId = 'Please select a BP screening unit.';
      hasErrors = true;
    }
    if (!bmiScreeningId.length) {
      vitalErrors.bmiScreeningId = 'Please select a BMI screening unit.';
      hasErrors = true;
    }
    if (!providerId) {
      vitalErrors.providerId = 'Please select a provider unit.';
      hasErrors = true;
    }
    if (!positionId) {
      vitalErrors.positionId = 'Please select a position unit.';
      hasErrors = true;
    }
    setErrors(vitalErrors);

    if (hasErrors) return;

    setLoading(true);

    let formData = {
      patientId: id,
      visitDate,
      temperature,
      temperatureUnit,
      bpLower,
      bpUpper,
      bpUnit,
      o2Saturation,
      o2SaturationUnit,
      respirationRate,
      waistCircumference,
      waistCircumferenceUnit,
      pulse,
      height,
      heightUnit,
      heightPosition,
      weight,
      weightUnit,
      weightPosition,
      glucoseLevel,
      glucoseMeasureType,
      hgba1c,
      hipCircumference,
      hipCircumferenceUnit,
      bpScreeningId,
      bmiScreeningId,
      diagnosis,
      bmi,
      bsa,
      dxCodesPerBmiId,
      autoCopyToDiagnosis,
      idealBodyWeight,
      providerId,
      positionId,
      notes,
      vitalDate,
      vitalTime,
      sendToReview,
    };

    console.log('Form submitted:', formData);

    try {
      await addVitals(formData);

      // Reset form fields or any relevant state here
      setVisitDate('');
      setTemperature('');
      setTempretureUnit('');
      setBpLower('');
      setBpUpper('');
      setBpUnit('');
      setO2Saturation('');
      seto2saturationUnit('');
      setRespirationRate('');
      setwaistCircumference('');
      setwaistCircumferenceUnit('');
      setPulse('');
      setHeight('');
      setheightUnit('');
      setHeightPosition('');
      setWeight('');
      setweightUnit('');
      setWeightPosition('');
      setglucoseLevel('');
      setglucoseMeasureType('');
      sethgba1c('');
      sethipCircumference('');
      sethipCircumferenceUnit('');
      setbpScreeningId([]);
      setbmiScreeningId([]);
      setdiagnosis('');
      setBmi('');
      setBsa('');
      setdxCodesPerBmiId('');
      setautoCopyToDiagnosis(false);
      setidealBodyWeight(false);
      setproviderId('');
      setpositionId('');
      setnotes('');
      // setVitalDate('');
      // setVitalTime('');
      setsendToReview(false);

      // Clear error messages
      setmsgErrors({
        temperature: '',
        bpLower: '',
        bpUpper: '',
        o2Saturation: '',
        respirationRate: '',
        pulse: '',
        glucoseLevel: '',
        hgba1c: '',
        hipCircumference: '',
        waistCircumference: '',
      });

      setAddData(true); // Assuming this triggers a reload or update in the parent component
    } catch (error) {
      console.error('Error adding patient vitals:', error);
      toast.error('Failed to add patient vitals. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  const getPatientVitals = async () => {
    try {
      let getLoginInfo = localStorage.getItem('appLoginInfo');
      if (getLoginInfo !== null) {
        const Token = JSON.parse(getLoginInfo).data.accessToken;
        const header = {
          headers: { 'x-access-token': Token },
        };
        const response = await axios.get(`${APIkey}/api/admin/getPatientVitals/${id}`, header);

        SetVitalsTableData(response?.data?.data);
      } else {
        console.error("No 'appLoginInfo' found in localStorage");
      }
    } catch (error) {
      console.error('Error in getPatientVitals:', error);
    }
  };

  useEffect(() => {
    getPatientVitals();
  }, [id , addData]);


  

  useEffect(() => {
    calculateBMI();
  }, [height, heightUnit, weight, weightUnit]);

  const calculateBMI = () => {
    let heightInMeters;
    let weightInKg;

    // Convert height to meters
    switch (heightUnit) {
      case 'cm':
        heightInMeters = height / 100;
        break;
      case 'm':
        heightInMeters = parseFloat(height);
        break;
      case 'ft':
        heightInMeters = height * 0.3048;
        break;
      case 'in':
        heightInMeters = height * 0.0254;
        break;
      default:
        heightInMeters = height / 100;
    }

    // Convert weight to kilograms
    switch (weightUnit) {
      case 'kg':
        weightInKg = weight;
        break;
      case 'lb':
        weightInKg = weight * 0.453592;
        break;
      case 'g':
        weightInKg = weight / 1000;
        break;
      case 'oz':
        weightInKg = weight * 0.0283495;
        break;
      default:
        weightInKg = weight;
    }

    if (!isNaN(heightInMeters) && heightInMeters > 0 && !isNaN(weightInKg) && weightInKg > 0) {
      // Calculate BMI
      const bmiValue = weightInKg / (heightInMeters * heightInMeters);
      setBmi(bmiValue.toFixed(2));

      // Calculate BSA
      const heightInCentimeters = height * 100; // Convert height to centimeters
      const bsaValue = Math.sqrt((heightInCentimeters * weightInKg) / 3600);
      setBsa(bsaValue.toFixed(2));
    } else {
      setBmi('');
      setBsa('');
    }
  };


  const isFieldOutOfRange = (field, value) => {
    switch (field) {
      case 'temperature':
        return value < 97.8 || value > 99.1;
      case 'bpUpper':
        return value < 90 || value > 160;
      case 'bpLower':
        return value > 120 || value < 80;
      case 'o2Saturation':
        return value < 95 || value > 100;
      case 'respirationRate':
        return value < 12 || value > 18;
      case 'pulse':
        return value < 60 || value > 100;
      case 'glucoseLevel':
        return value < 17 || (value > 100 && value !== 125);
      case 'hgba1c':
        return value >= 5.7;
      case 'hipCircumference':
        return !((94 <= value && value <= 105) || (97 <= value && value <= 108));
      case 'waistCircumference':
        return !(value <= 37 || value <= 31.5);
      default:
        return false;
    }
  };


 
  

  return (
    <div>
      <AppointmentPageHeader name='Vitals' />
      <PatientIdCard />

      <div className='card p-3 mt-1 '>
        <div className='vitalsCard1st'>
          <div>
            <button type='button' className='blueButton'>
              Review
            </button>
          </div>

        </div>

        <div className=' row mt-1 '>
          <div className=' col-md-6 col-lg-3 '>
            <div>
              <label className='form-label patient-info-title d-flex align-items-center' htmlFor='Temp'>
                Temperature: 
                <span className='smallRangeText'> {`(${tempRangeFahrenheit}) (${tempRangeCelsius})`}</span>
              </label>
              <div className='d-flex gap-2'>
                <div className='w-25'>
                <input
                  id='text'
                  type='number'
                  className={`SearchInputbox form-control ${  msgerrors.temperature ? 'text-danger' : '' }`}
                  style={{borderColor: msgerrors.temperature ? 'red' : ''}}
                  value={temperature}
                  onChange={handleTemperatureChange}
                />
                </div>
                <div className='w-75'>
                  <SearchableDropdown
                    options={temperatureUnitList}
                    InitialValue={temperatureUnit}
                    gettingData={getTemperature}
                  />
                </div>
              </div>
              {msgerrors.temperature && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.temperature}
                </span>
              )}
              <br></br>
              {errors.temperatureUnit && (
                <span className='form-label errMsg'>
                  {' '}
                  <i className='fa-solid fa-triangle-exclamation'></i>
                  &nbsp;{errors.temperatureUnit}
                </span>
              )}
            </div>
          </div>
          <div className=' col-md-6 col-lg-3 '>
            <div className=''>
              <label className='form-label patient-info-title ' htmlFor=''>
                BP Systolic/Diastolic:
                <span className='smallRangeText'>{`(${BpSystolic}) (${BpDiastolic})`}</span>
              </label>
              <div className='d-flex gap-2'>
                <div className='w-25'>

                <input
                  type='text'
                  placeholder='mmHg'
                  className='SearchInputbox form-control'
                  style={{borderColor: msgerrors.bpUpper ? 'red' : ''}}
                  value={bpUpper}
                  onChange={handleBpUpperChange}
                />
                </div>

                  <div className='w-25'>

                <input
                  type='text'
                  placeholder='mmHg'
                  className='SearchInputbox form-control'
                  style={{borderColor: msgerrors.bpLower ? 'red' : ''}}
                  value={bpLower}
                  onChange={handleBpLowerChange}
                />
                  </div>

              <div className='w-50'>

                <SearchableDropdown
                  options={bpUnitList}
                  InitialValue={bpUnit}
                  gettingData={getbpUnit}
                />
              </div>

              </div>
              {(msgerrors.bpUpper || msgerrors.bpLower) && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.bpUpper || msgerrors.bpLower}
                </span>
              )}
              <br></br>
              {errors.bpUnit && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;{errors.bpUnit}
                </span>
              )}
            </div>
          </div>

          <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title' htmlFor='O2'>
                O2 Saturation:  <span className='smallRangeText'>{`(${o2Range})`}</span>
              </label>
              <div className='d-flex gap-2'>
                <input
                  id='O2'
                  type='text'
                  placeholder='%'
                  className='SearchInputbox form-control'
                  style={{borderColor: msgerrors.o2Saturation ? 'red' : ''}}
                  value={o2Saturation}
                  onChange={handleO2SaturationChange}
                />
                <SearchableDropdown
                  options={o2SaturationUnitList}
                  InitialValue={o2SaturationUnit}
                  gettingData={getO2Saturation}
                />
              </div>
              {msgerrors.o2Saturation && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.o2Saturation}
                </span>
              )}
              <br></br>
              {errors.o2SaturationUnit && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {errors.o2SaturationUnit}
                </span>
              )}
            </div>
          </div>
          <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title'  htmlFor='Resp'>
                Respiration Rate:       <span className='smallRangeText'>{`(${respirationRange})`}</span>
              </label>
              <div className='  '>
                <input
                  id='Resp'
                  type='text'
                  placeholder='/min'
                  className='SearchInputbox form-control'
                  style={{borderColor: msgerrors.respirationRate ? 'red' : ''}}
                  value={respirationRate}
                  onChange={handlerespirationrateChange}
                />
              </div>
              {msgerrors.respirationRate && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.respirationRate}
                </span>
              )}
            </div>
          </div>
        
        </div>

        <div className=' row mt-1 '>
        <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title' htmlFor='Waist'>
                Waist Circumference:  <span className='smallRangeText'>{`${waistRange}`}</span>
              </label>
              <div className=' d-flex gap-2'>
                <input
                  type='text'
                  className='SearchInputbox form-control'
                  value={waistCircumference}
                  style={{borderColor: msgerrors.waistCircumference ? 'red' : ''}}
                  onChange={handleWaistCircumferenceChange}
                />

                <SearchableDropdown
                  options={waistCircumferenceUnitList}
                  InitialValue={waistCircumferenceUnit}
                  gettingData={getWaistCircumference}
                />
              </div>
              {msgerrors.waistCircumference && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.waistCircumference}
                </span>
              )}
              <br></br>
              {errors.waistCircumferenceUnit && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {errors.waistCircumferenceUnit}
                </span>
              )}
            </div>
          </div>
          <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title' htmlFor='Pulse'>
                Pulse: <span className='smallRangeText'>{`(${pulseRange})`} </span>
              </label>
              <div className='  '>
                <input
                  id='Pulse'
                  type='text'
                  placeholder='/min'
                  className='SearchInputbox form-control'
                  style={{borderColor: msgerrors.pulse ? 'red' : ''}}
                  value={pulse}
                  onChange={handlepulseRangeChange}
                />
              </div>
              {msgerrors.pulse && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.pulse}
                </span>
              )}
            </div>
          </div>
          <div className=' col-md-6 col-lg-3 '>
            <div>
              <label className='form-label patient-info-title' htmlFor='Temp'>
                Height:
              </label>
              <div className='d-flex gap-2'>
                <SearchableDropdown
                  options={heightUnitList}
                  InitialValue={heightUnit}
                  gettingData={getHeight}
                />

                <input
                  type='text'
                  className='SearchInputbox form-control'
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
                <SearchableDropdown
                  options={HeightPositionList}
                  InitialValue={heightPosition}
                  gettingData={getHeightposition}
                />
              </div>
              {errors.heightUnit && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;{errors.heightUnit}
                </span>
              )}<br></br>
              {errors.heightPosition && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i>&nbsp;{errors.heightPosition}
                </span>
              )}
            </div>
          </div>
          <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title' htmlFor=''>
                Weight:
              </label>
              <div className=' d-flex gap-2 '>
                <SearchableDropdown
                  options={weightUnitList}
                  InitialValue={weightUnit}
                  gettingData={getWeight}
                />
                <input
                  type='text'
                  placeholder=''
                  className='SearchInputbox form-control'
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
                <SearchableDropdown
                  options={WeightPositionList}
                  InitialValue={weightPosition}
                  gettingData={getWeightposition}
                />
              </div>
              {errors.weightUnit && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i>&nbsp;{errors.weightUnit}
                </span>
              )}<br></br>
              {errors.weightPosition && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i>&nbsp;{errors.weightPosition}
                </span>
              )}
            </div>
          </div>


        </div>

        <div className='row mt-1'>
        <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title' htmlFor='Glucose'>
                Glucose Level: <span className='smallRangeText'>(F: {glucoseRangeFasting}) (NF: {glucoseRangeNonFasting})</span>
              </label>
              <div className=' d-flex gap-2'>
                <input
                  id='text'
                  type='number'
                  className='SearchInputbox form-control'
                  style={{borderColor: msgerrors.glucoseLevel ? 'red' : ''}}
                  value={glucoseLevel}
                  onChange={handleGlucoseLevelChange}
                />

                <SearchableDropdown
                  options={glucoseMeasureTypeList}
                  InitialValue={glucoseMeasureType}
                  gettingData={getGlucose}
                />
              </div>
              {msgerrors.glucoseLevel && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.glucoseLevel}
                </span>
              )}
              <br></br>
              {errors.glucoseMeasureType && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {errors.glucoseMeasureType}
                </span>
              )}
            </div>
          </div>
          <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title' htmlFor='Waist'>
                HgbA1c : <span className='smallRangeText'>{`(${hgb})`}</span>
              </label>
              <div className='  '>
                <input
                  id='Temp'
                  type='number'
                  className='SearchInputbox form-control'
                  style={{borderColor: msgerrors.hgba1c ? 'red' : ''}}
                  value={hgba1c}
                  onChange={handleHgba1cChange}
                />
              </div>
              {msgerrors.hgba1c && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.hgba1c}
                </span>
              )}
            </div>
          </div>
          <div className=' col-md-6 col-lg-3'>
            <div>
              <label className='form-label patient-info-title' htmlFor=''>
                Hip Circumference:
                <span className='smallRangeText'>{`${hip}`}</span>
              </label>
              <div className=' d-flex gap-2'>
                <input
                  id='Temp'
                  type='number'
                  className='SearchInputbox form-control'
                  value={hipCircumference}
                  style={{borderColor: msgerrors.hipCircumference ? 'red' : ''}}
                  onChange={handleHipCircumferenceChange}
                />

                <SearchableDropdown
                  options={hipCircumferenceUnitList}
                  InitialValue={hipCircumferenceUnit}
                  gettingData={getHip}
                />
              </div>
              {msgerrors.hipCircumference && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {msgerrors.hipCircumference}
                </span>
              )}
              <br></br>
              {errors.hipCircumferenceUnit && (
                <span className='form-label errMsg'>
                  <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;
                  {errors.hipCircumferenceUnit}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className='row mx-1 mt-1 grayBox '>
          <div className='col-md-4 col-lg-4 '>
            <span>BMI:{bmi}</span>
          </div>

          <div className='col-md-4 col-lg-4 '>
            <span>BSA:{bsa}</span>
          </div>

          <div className='col-md-4 col-lg-4'>
            <span>
              Ideal Body Weight:     
                <img src={Icon9} alt='' title='This formula only applies to person 60 inches(153 cm) or taller' />
            </span>
          </div>
        </div>

        <div className=' row  mt-1'>
          <div className='col-md-4 col-lg-4'>
            <label className='form-label patient-info-title' htmlFor=''>
              Diagnosis:
            </label>
            <div>
              <input
                type='text'
                className='SearchInputbox form-control'
                value={diagnosis}
                onChange={(e) => setdiagnosis(e.target.value)}
              />
            </div>
          </div>

          <div className='col-md-4 col-lg-4'>
            <label className='form-label patient-info-title' htmlFor=''>
              Dx Codes per BMI:
            </label>
            <div>
              <SearchableDropdown
                options={dxCodesPerBmiIdList}
                InitialValue={dxCodesPerBmiId}
                gettingData={getDxcode}
              />
            </div>
            {errors.dxCodesPerBmiId && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;{errors.dxCodesPerBmiId}
              </span>
            )}
          </div>
          <div className='col-md-4 col-lg-4 ' >
            <div className='d-flex mx-1 form-check form-check-custom form-check-sm'>
              <input type='checkbox' className='mx-1 form-check-input' />
              <label
                className='form-check-label mx-1 '
                style={{color: 'black', fontWeight: '600'}}
                htmlFor=''
              >
                Auto Copy to Diagnosis
              </label>
              <div>{/* <img src={CopyDiagnosis} alt="" /> */}</div>
            </div>
          </div>
        </div>

        <div className=' mx-1 mt-1  grayBox  '>
          <label className='form-label  '>BP Screening / Follow Up Not Performed Reason:</label>

          <MultiSelectDropdown
            options={bpScreeningIdList}
            placeHolder='Select BP Screening'
            getMultiselectData={getBpscreening}
            InitialValue ={bpScreeningId}

            // gettingData={getBpscreening}
          />
          {errors.bpScreeningId && (
            <span className='form-label errMsg'>
              <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;{errors.bpScreeningId}
            </span>
          )}
        </div>

        <div className=' mx-1 mt-1  grayBox  '>
          <label className='form-label '>BMI Screening / Follow Up Not Performed Reason:</label>

          <MultiSelectDropdown
            options={bmiScreeningIdList}
            placeHolder='Select BMI Screening'
            getMultiselectData={getBmiscreening}
            InitialValue ={bmiScreeningId}
            // gettingData = {getBmiscreening}
          />
          {errors.bmiScreeningId && (
            <span className='form-label errMsg'>
              <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;{errors.bmiScreeningId}
            </span>
          )}
        </div>

        <div className=' row  mt-1'>
          <div className='col-md-4 col-lg-4'>
            <label className='form-label patient-info-title' htmlFor=''>
              Provider:
            </label>
            <div>
              <SearchableDropdown
                options={providerIdList}
                InitialValue={providerId}
                gettingData={getProvider}
              />
            </div>
            {errors.providerId && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;{errors.providerId}
              </span>
            )}
          </div>

          <div className='col-md-4 col-lg-4'>
            <label className='form-label patient-info-title' htmlFor=''>
              Position:
            </label>
            <div>
              <SearchableDropdown
                options={positionIdList}
                InitialValue={positionId}
                gettingData={getPosition}
              />
            </div>
            {errors.positionId && (
              <span className='form-label errMsg'>
                <i className='fa-solid fa-triangle-exclamation'></i> &nbsp;{errors.positionId}
              </span>
            )}
          </div>
          <div className='col-md-4 col-lg-4 ' style={{}}>
            <label className='form-label patient-info-title' htmlFor=''>
              Notes:
            </label>
            <div>
              <input
                type='text'
                className='form-control SearchInputbox'
                value={notes}
                onChange={(e) => setnotes(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='row mt-2 '>
          <div className='col-lg-6'>
            <div className='d-flex mx-1 align-items-center '>
              <input
                type='checkbox'
                className=''
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label
                className='form-check-label mx-1 '
                style={{color: 'black', fontWeight: '600'}}
                htmlFor=''
              >
                Record Historical Vital
              </label>
              <div className='d-flex gap-2'>
                <input
                  className='SearchInputbox form-control'
                  type='date'
                  value={vitalDate}
                  onChange={handleDateChange}
                  disabled={!isChecked}
                />
            
                <input
                  className='SearchInputbox form-control'
                  type='time'
                  value={vitalTime}
                  onChange={handleTimeChange}
                  disabled={!isChecked}
                />
              </div>
     
            </div>
          </div>
          <div className='col-lg-6'></div>
        </div>

        <div className=' row '>
          <div className='col d-flex justify-content-end d-grid gap-2'>
            <div>
              {loading ? (
                <div className='loader'></div>
              ) : (
                <button className='btn btn-primary btn-sm fw-bold ' onClick={handleSubmit}>
                  Save
                </button>
              )}
            </div>
            <div>
              <button className=' btn btn-light btn-sm fw-bold  border border-black' onClick={handleClear}>Clear</button>
            </div>
            <div>
            <button className='btn btn-light btn-sm fw-bold border border-black' onClick={exportToCsv}>
        Export Grid data
      </button>
            </div>
          </div>
        </div>
      </div>

      <div className='card mt-2'>
        <table className='CurveTable'>
          <thead>
            <tr className='tableHead'>
              <th>Date</th>
              <th>BP</th>
              <th>Pulse</th>
              <th>HT</th>
              <th>WT</th>
              <th>BMI</th>
              <th>BSA</th>
              <th>Temp</th>
              <th>O2</th>
              <th>Resp</th>
              <th>Waist</th>
              <th>Hip</th>
              <th>Glucose</th>
              <th>HgbA1c</th>
              <th>Position</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {vitalsTableData.map((item, index) => (
              <tr key={index}>
                <td>{moment(item?.appointmentDate).format('MM/DD/YYYY')}</td>
                <td style={{ color: isFieldOutOfRange('bpUpper', item?.bpUpper) || isFieldOutOfRange('bpLower', item?.bpLower) ? 'red' : 'inherit' }}>
                  {item?.bpUpper}/{item?.bpLower}
                </td>
                <td style={{ color: isFieldOutOfRange('pulse', item?.pulse) ? 'red' : 'inherit' }}>{item?.pulse}</td>
                <td>{item?.height}</td>
                <td>{item?.weight}</td>
                <td>{item?.bmi}</td>
                <td>{item?.bsa}</td>
                <td style={{ color: isFieldOutOfRange('temperature', item?.temperature) ? 'red' : 'inherit' }}>{item?.temperature}</td>
                <td style={{ color: isFieldOutOfRange('o2Saturation', item?.o2Saturation) ? 'red' : 'inherit' }}>{item?.o2Saturation}</td>
                <td style={{ color: isFieldOutOfRange('respirationRate', item?.respirationRate) ? 'red' : 'inherit' }}>{item?.respirationRate}</td>
                <td style={{ color: isFieldOutOfRange('waistCircumference', item?.waistCircumference) ? 'red' : 'inherit' }}>{item?.waistCircumference}</td>
                <td style={{ color: isFieldOutOfRange('hipCircumference', item?.hipCircumference) ? 'red' : 'inherit' }}>{item?.hipCircumference}</td>
                <td style={{ color: isFieldOutOfRange('glucoseLevel', item?.glucoseLevel) ? 'red' : 'inherit' }}>{item?.glucoseLevel}</td>
                <td style={{ color: isFieldOutOfRange('hgba1c', item?.hgba1c) ? 'red' : 'inherit' }}>{item?.hgba1c}</td>
                <td>{item?.positionId}</td>
                <td>
                  <ul className='PageHeaderList'>
                    <li>icon1</li>
                    <li>icon2</li>
                    <li>icon3</li>
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      <div className='PatientChartCardFooter'>
        <span>Total Numbers of Records: {vitalsTableData.length}</span>
      </div>
    </div>
    </div>
  );
};
export default PatientVitals
