import React, { useEffect, useState } from 'react'
import SearchableDropdown from './SearchableDropdown'
import inputText from "../../../ReUsableComponent/ReyClinicalinputs.json"
import axios from 'axios';

function NameCompo(props) {


  
    


    const APIkey = process.env.REACT_APP_SERVERAPI;

    const getloginInfo: string | null = localStorage.getItem('loginInfo') ;


    const [PrefixData, setPrefixData] = useState<any[] | undefined>([]);

    const [SuffixData, setSuffixData] = useState<any[] | undefined>([]);


    useEffect(() => {

        const getprefixSuffixList = async () => {
            try {

                // if (getloginInfo !== null) {

                //     const Token = JSON.parse(getloginInfo).data.accessToken;


                //     const header = {
                //         headers: { 'x-access-token': Token }
                //     };

                //     const prefixList = await axios.get(`${APIkey}/api/admin/getClinicPrefixList`, header);



                //     setPrefixData(prefixList.data.data.map(i => ({
                //         label: i.prefixName,
                //         value: i.prefixName,
                //         id: i._id
                //     })));

                //     const suffixList = await axios.get(`${APIkey}/api/admin/getClinicSuffixList`, header);


                //     setSuffixData(suffixList.data.data.map(i => ({
                //         label: i.suffixName,
                //         value: i.suffixName,
                //         id: i._id
                //     })));


                     
                // } else {
                //     console.log("No login info found in localStorage");
                // }

                
                if (getloginInfo !== null) {

                    const Token = JSON.parse(getloginInfo).data.accessToken;


                    const header = {
                        headers: { 'x-access-token': Token }
                    };

                    const prefixList = await axios.get(`${APIkey}/api/admin/getClinicPrefixList`, header);



                    setPrefixData(prefixList.data.data.map(i => ({
                        label: i.prefixName,
                        value: i.prefixName,
                        id: i._id
                    })));

                    const suffixList = await axios.get(`${APIkey}/api/admin/getClinicSuffixList`, header);


                    setSuffixData(suffixList?.data?.data?.map(i => ({
                        label: i.suffixName,
                        value: i.suffixName,
                        id: i._id
                    })));
                } else {
                    console.log("No login info found in localStorage");
                }
            } catch (err) {
                console.error("Error:", err);
            }

        }

        getprefixSuffixList();

    }, [])




    const getprefix = (e) => {
        props.getPrefixData(e)
    }

    const getsuffix = (e) => {
        props.getSuffixData(e)
    }
    const firstName = (e) => {

        props.getfName(e.target.value)
    }
    const lastName = (e) => {

        props.getlName(e.target.value)
    }
    const middleName = (e) => {
        props.getmName(e.target.value)
    }


    return (
        <>
            <div className={`col-md-6 col-lg-${props.colno} `}>
                <div className='d-flex w-100 gap-2 mb-4'>
                            <div className='w-25'>
                                <label htmlFor="" className=' patient-info-title'>{inputText.prefix.label}</label>
                                <SearchableDropdown
                                    options={PrefixData}
                                    InitialValue={props.prefixValue}
                                    gettingData={getprefix} />
                            </div>
                 
                    <div className={props.suffixprefixhide !== 1 ? 'w-75' : "w-100"}>
                        <label htmlFor="" className='patient-info-title required '>{inputText.lastName.label}</label>
                        <input type="text"
                            value={props.lastNameValue}
                            maxLength={50}
                            className='form-control SearchInputbox'
                            onChange={lastName} />
                        {props.lastNameErr && (
                            <span className='form-label errMsg'>
                                {/* {inputText.lastName.ErrMsg} */}
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                &nbsp;
                                {props.lastNameErr}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className={`col-md-6 col-lg-${props.colno} mb-4`}>
                <div className=' '>
                    <label htmlFor="" className='patient-info-title required'>{inputText.firstName.label}</label>
                    <input type="text"
                        value={props.firstNameValue}
                        maxLength={50}
                        className='form-control SearchInputbox'
                        onChange={firstName} />
                    {props.firstNameErr && (
                        <span className='form-label errMsg'>
                            <i className="fa-solid fa-triangle-exclamation"></i>
                            &nbsp;
                            {/* {inputText.firstName.ErrMsg} */}
                            {props.firstNameErr}
                        </span>
                    )}
                </div>

            </div>

            <div className={`col-md-6 col-lg-${props.colno} mb-4`}>
                <div className='d-flex w-100 gap-2'>

                    <div className={props.suffixprefixhide !== 1 ? 'w-75' : "w-100"}>

                        <label htmlFor="" className='patient-info-title'>{inputText.MiddleName.label}</label>
                        <input
                            type="text"
                            value={props.middleNameValue}
                            onChange={middleName}
                            maxLength={50}
                            className='form-control SearchInputbox' />
                        {props.middleNameErr && (
                            <span className='form-label errMsg'>
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                &nbsp;
                                {/* {inputText.firstName.ErrMsg} */}
                                {props.middleNameErr}
                            </span>
                        )}
                    </div>

              
                            <div className='w-25 '>
                                <label htmlFor="" className='patient-info-title'>{inputText.Suffix.label}</label>
                                <SearchableDropdown
                                    options={SuffixData}
                                    InitialValue={props.suffixValue}
                                    gettingData={getsuffix} />
                            </div>
             
                </div>

            </div>


        </>

    )
}

export default NameCompo