import React from 'react'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

function PhoneInputCompo(props) {


    const reversestr = (number , code) => {

        if (typeof number === 'string') {

            var splitString = number.split("");

            
            // var reverseArray = splitString.reverse();

            // var getreverse10 = reverseArray.slice(0, 10);
            var getThe10digit = splitString.slice(code.length)

            var getTheNumber = getThe10digit.join("")

            // console.log(getTheNumber);
            

            // var getfirst10 = getreverse10.reverse();
            // var getafter10 = getreversecode.reverse();

            // var getTheNumber = getfirst10.join("");
    
         return {
            getTheNumber,
            gettheCode: `+${code}`
         }
        }
    }


    const getNumber = (n , country) => {
        props.getTheNumber(reversestr(n , country.dialCode))
    }
    
    return (
        <>
            <PhoneInput
                country={'us'}
                value={props.contactnoValue}
                onChange={getNumber}
          
            />
        </>
    )
}

export default PhoneInputCompo