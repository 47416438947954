import React from 'react'

function PatientProblemlist() {
  return (
    <div>
        <h1>

        PatientProblemlist
        </h1>
        </div>
  )
}

export default PatientProblemlist