import React from 'react';
import CryptoConverter from './CryptoConverter';
import moment from 'moment';
import PaginationCompo from './PaginationCompo';

interface Column {
  key: string[];
  header: string;
  isEncrypted?: boolean;
  isMasked?: boolean;
  isDate?: boolean;
  isPhone?: boolean;
  isAction?: boolean;
  ActionComponent?: React.FC<{id: string}>;
}

interface CommonTableProps {
  data: any[];
  totalCount: number;
  perPage: number;
  setPerPage: (perPage: number) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  columns: Column[];
  searchQuery?: string;
  setQuery: (query: string) => void;
  onRowClick?: (id: string) => void;
  maskedInputClassName?: string;
}

const CommonTable: React.FC<CommonTableProps> = ({
  data,
  totalCount,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  columns,
  searchQuery,
  setQuery,
  onRowClick,
}) => {
  console.log(data);

  const dobfun = (dob: string) => moment(dob).format('DD/MM/yyyy');

  const handlePageChange = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className=' pb-2 d-flex justify-content-between'>
        <div className='input-group' style={{width: '130px'}}>
          <span className='input-group-text' style={{height: '35px', width: '55px'}}>
            Show
          </span>
          <select
            className='form-select'
            onChange={(e) => setPerPage(parseInt(e.target.value))}
            defaultValue={perPage}
            style={{height: '35px', width: '50px'}}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='40'>40</option>
            <option value='50'>50</option>
          </select>
        </div>
        <div>
          <input
            type='text'
            className='form-control  SearchInputbox'
            placeholder='Search'
            value={searchQuery}
            onChange={(e) => setQuery(e.target.value)}
            style={{}}
          />
        </div>
      </div>

      <div className='table-container mt-2 table-responsive ' style={{maxHeight: '480px'}}>
        <table className='table table-hover text-center table-striped fullCurve w-100'>
          <thead className='table-dark tableHead headerRow'>
            <tr>
              {columns.map((col) => (
                <th key={col.key[0]} className='text-center'>
                  <span>{col.header}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row._id} className='tableBody mx-3'>
                {columns.map((col) => (
                  <td key={col.key[0]}>
                    {col.key?.length > 1 ? (
                      row[col.key[0]] && (
                        <span
                          style={col.key[0] === 'mrn' ? {color: '#2972a5', cursor: 'pointer'} : {}}
                          onClick={
                            col.key[0] === 'mrn' && onRowClick
                              ? () => onRowClick(row._id)
                              : undefined
                          }
                        >
                          {row[col.key[0]][col.key[1]]}
                        </span>
                      )
                    ) : col.isEncrypted ? (
                      <span
                        style={col.key[0] === 'mrn' ? {color: '#2972a5', cursor: 'pointer'} : {}}
                        onClick={
                          col.key[0] === 'mrn' && onRowClick ? () => onRowClick(row._id) : undefined
                        }
                      >
                        {CryptoConverter(row[col.key[0]])}
                      </span>
                    ) : col.isDate ? (
                      <span
                        style={col.key[0] === 'mrn' ? {color: '#2972a5', cursor: 'pointer'} : {}}
                        onClick={
                          col.key[0] === 'mrn' && onRowClick ? () => onRowClick(row._id) : undefined
                        }
                      >
                        {dobfun(row[col.key[0]])}
                      </span>
                    ) : col.isAction && col.ActionComponent ? (
                      <col.ActionComponent id={row._id} />
                    ) : (
                      <span
                        style={col.key[0] === 'mrn' ? {color: '#2972a5', cursor: 'pointer'} : {}}
                        onClick={
                          col.key[0] === 'mrn' && onRowClick ? () => onRowClick(row._id) : undefined
                        }
                      >
                        {row[col.key[0]]}
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* {data && totalCount > perPage ? ( */}
      <div className='container-fluid d-flex justify-content-between'>
        <div className='d-flex align-item-center py-2 '>
          <span>{totalCount ? `Total ${totalCount} entries` : `No records found`}</span>
        </div>
        <PaginationCompo
          totalData={totalCount}
          perPage={perPage}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
      {/* // ) : (
      //   totalCount > perPage && (
      //     <PaginationCompo
      //       totalRecords={totalCount}
      //       recordsPerPage={perPage}
      //       currentPage={currentPage}
      //       onPageChange={handlePageChange}
      //     />
      //   )
      // )} */}
    </div>
  );
};

export default CommonTable;
