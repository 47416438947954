import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SearchableDropdown from '../../ReusablePages/ReusableAppointment/SearchableDropdown'
import AddressComponent from '../../../../ReUsableComponent/AddressComponent'
import ContactComponent from '../../../../SuperAdmin/Pages/ReausableComponent/ContactComponent'
import NameCompo from '../../../../SuperAdmin/Pages/ReausableComponent/NameCompo'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { EmailRegExp, ZipRegEx, phoneNoRegexp } from '../../../../ReUsableComponent/regEx'
import NameCompoForClinic from '../../../../SuperAdmin/Pages/ReausableComponent/NameCompoForClinic'


function AdditionInfoPopupsEdit(props) {

    const handleClose = () => {
        props.close()
    }

    const APIkey = process.env.REACT_APP_SERVERAPI;

    const { id } = useParams()

    console.log("props", props);



    const [allAddressTypeList, setAllAddressTypeList] = useState([])
    const [allPreferredContactMethodList, setAllPreferredContactMethodList] = useState([])
    const [allRelationwithPatientList, setAllRelationwithPatientList] = useState([])
    const [permissionList, setpermissionList] = useState([])
    const [additionalPermissionId, setAdditionalPermissionId] = useState<any>([])


    const [addressTypeId, setAddressType] = useState()


    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [cityId, setCity] = useState();
    const [stateId, setState] = useState();
    const [countryId, setCountry] = useState();
    const [zipCode, setZipcode] = useState<string | undefined>();


    const [emailAddress, setEmail] = useState('');
    const [mobileCountryCode, setMobileCountryCode] = useState();
    const [mobileNo, setMobileNo] = useState();
    // const  countryCode1s = cellNumber.

    const [homeNo, setHomeNo] = useState();
    const [homeCountryCode, setHomeCountryCode] = useState();

    const [preferredContactMethodId, setPreferredContactMethodId] = useState();
    const [notes, setNotes] = useState();

    const [prefixId, setprefix] = useState();
    const [firstName, setfirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setmiddleName] = useState('');
    const [suffixId, setsuffix] = useState(null);

    const [realtionshipId, setrealtionshipId] = useState()


    const [errAddressLine1, setErrAddressLine1] = useState('')
    const [errAddressLine2, setErrAddressLine2] = useState('')
    const [errCity, setErrCity] = useState('')
    const [errState, setErrState] = useState('')
    const [errcountry, setErrcountry] = useState('')
    const [errZipcode, setErrZipcode] = useState('')

    const [errEmail, setErrEmail] = useState('')
    const [errCellPhone, setErrCellPhone] = useState('')
    const [errHomePhone, setErrHomePhone] = useState('')

    const [errLastName, setErrLastName] = useState('')
    const [errFirstName, setErrFirstName] = useState('')
    const [errMiddleName, setErrMiddleName] = useState('')
    const [errRelationShipId, setErrRelationShipId] = useState('')
    const [errAddresstype, setErrAddresstype] = useState('')






    useEffect(() => {
        const getallList = async () => {

            try {
                let getloginInfo = localStorage.getItem('appLoginInfo');

                if (getloginInfo !== null) {
                    const Token = JSON.parse(getloginInfo)?.data?.accessToken;
                    const clinicID = JSON.parse(getloginInfo)?.data?.clinicId;


                    const header = {
                        headers: { 'x-access-token': Token }
                    }

                    const getAddressTypeList = await axios.get(`${APIkey}/api/admin/getAddressTypeList/${clinicID}`, header);

                    setAllAddressTypeList(getAddressTypeList?.data?.data?.map(i => (

                        {
                            label: i?.addressType,
                            value: i?.addressType,
                            id: i?._id
                        })))

                    const getPreferredContactMethodList = await axios.get(`${APIkey}/api/admin/getPreferredContactMethodList/${clinicID}`, header);

                    setAllPreferredContactMethodList(getPreferredContactMethodList.data.data.map(i => (
                        {
                            label: i?.preferredContactMethod,
                            value: i?.preferredContactMethod,
                            id: i?._id

                        })))

                    const getrelationwithPatientList = await axios.get(`${APIkey}/api/admin/getRelationshipList/${clinicID}`, header);

                    setAllRelationwithPatientList(getrelationwithPatientList.data.data.map(i => (
                        {
                            label: i?.relationship,
                            value: i?.relationship,
                            id: i?._id
                        })))

                    const getPermissionList = await axios.get(`${APIkey}/api/admin/getAdditionalInfoPermissionList/${clinicID}/${props.popUpNo}`, header);

                    setpermissionList(getPermissionList.data.data)

                    const getAdditionalinfobyId = await axios.get(`${APIkey}/api/admin/getPatientAdditionalInfoById/${props.AdditionalInfoId}`, header);

                    let getinfobyId = getAdditionalinfobyId.data.data;
                    console.log(getinfobyId);

                    setAddressType(getinfobyId.addressTypeId)
                    setAddressLine1(getinfobyId.addressLine1)
                    setAddressLine2(getinfobyId.addressLine2)
                    setCountry(getinfobyId.countryId)
                    setState(getinfobyId.stateId)
                    setCity(getinfobyId.cityId)
                    setZipcode(getinfobyId?.zipCode)
                    setEmail(getinfobyId.emailAddress)
                    setMobileCountryCode(getinfobyId.mobileCountryCode)
                    setMobileNo(getinfobyId.mobileNo)
                    setHomeCountryCode(getinfobyId.homeCountryCode)
                    setHomeNo(getinfobyId.homeNo)
                    setPreferredContactMethodId(getinfobyId.preferredContactMethodId)
                    setNotes(getinfobyId.notes)
                    setrealtionshipId(getinfobyId.realtionshipId)
                    setprefix(getinfobyId.prefixId)
                    setLastName(getinfobyId.lastName)
                    setfirstName(getinfobyId.firstName)
                    setmiddleName(getinfobyId.middleName)
                    setsuffix(getinfobyId.suffixId)
                    setAdditionalPermissionId(getinfobyId.additionalPermissionId)
                }
                else {

                    console.log("No login info found in localStorage");

                }
            }
            catch (err) {
                console.log(err);
            }


        }
        getallList()
    }, [])



    const getTheAddresstype = (e) => {
        setAddressType(e);
    }


    const getAddress1 = (e) => {
        setAddressLine1(e);


    }
    const getAddress2 = (e) => {
        setAddressLine2(e);
    }

    const getCity = (e) => {
        setCity(e);

    }
    const getState = (e) => {
        setState(e);

    }

    const getCountry = (e) => {
        setCountry(e)
    }
    const getZipcode = (e) => {
        setZipcode(e)
    }



    const getTheEmail = (e) => {
        setEmail(e.target.value);
    }

    const getCellNumber = (e) => {
        setMobileNo(e.getTheNumber);
        setMobileCountryCode(e.gettheCode)
    }

    const getHomeNumber = (e) => {
        setHomeNo(e.getTheNumber);
        setHomeCountryCode(e.gettheCode)

    }

    const gethePreferredContactMethod = (e) => {


        setPreferredContactMethodId(e)
    }
    const getTheNotes = (e) => {
        setNotes(e.target.value)
    }



    const getprefix = (e) => {
        setprefix(e);
    }

    const getFirstName = (e) => {
        setfirstName(e);
    }
    const getLastName = (e) => {
        setLastName(e);
    }
    const getMiddleName = (e) => {
        setmiddleName(e);
    }

    const getsuffix = (e) => {
        setsuffix(e);
    }

    const getTherealtionshipId = (e) => {
        setrealtionshipId(e)
    }

    const gettheCheckBoxValue = (id, checked) => {
        if (checked) {
            setAdditionalPermissionId([...additionalPermissionId, id]);
        } else {
            setAdditionalPermissionId(additionalPermissionId.filter(item => item !== id));
        }
    }



    const updateAdditionalInfo = async (data) => {


        try {
            let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;

                const header = {
                    headers: { 'x-access-token': Token }
                }

                const response = await axios.post(`${APIkey}/api/admin/updatePatientAdditionalInfoById/${props.AdditionalInfoId}`, data, header);

                console.log(response);

                handleClose();

            }
            else {
                console.log("No login info found in localStorage");
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    const validation = (value) => {
        const errors: { [key: string]: string } = {};


        if (props.popUpNo == props?.listOfAdditionalInfo[0]._id  ) {

            if (!value.addressTypeId) {
                errors.addressTypeId = "Please select address type"
            }


            if (!value.addressLine1 || value.addressLine1.trim() === '') {
                errors.addressLine1 = 'Please enter address line 1 ';
            } else {
                if (value.addressLine1.length >= 101) {
                    errors.addressLine1 = 'Please enter address line 1 less then or equal to 100 characters'
                }
            }


            if (value.addressLine2 && value.addressLine2.length >= 101) {
                errors.addressLine2 = 'Please enter address line 2 less than or equal to 100 characters';
            }


            if (!value.countryId) {
                errors.countryId = 'Please select country'
            }
            if (!value.stateId) {
                errors.stateId = 'Please select state'
            }
            if (!value.cityId) {
                errors.cityId = 'Please select state'
            }




            if (!value.zipCode) {
                errors.zipCode = 'Please enter zip ';
            }
            else if (!ZipRegEx.test(value.zipCode)) {
                errors.zipCode = 'Please enter valid zip';
            }


            if (!value.emailAddress || value.emailAddress.trim() === '') {
                errors.emailAddress = 'Please enter email';
            }
            else {
                if (!EmailRegExp.test(value.emailAddress)) {
                    errors.emailAddress = 'Please enter valid email';
                }
                else if (value.emailAddress.length >= 251) {
                    errors.emailAddress = 'Please enter email less than or equal to 250 characters';
                }
            }


            if (!value.mobileNo) {
                errors.mobileNo = 'Please enter cell phone  ';
            }
            else {
                if (!phoneNoRegexp.test(`${value.mobileCountryCode}${value.mobileNo}`)) {
                    errors.mobileNo = 'Please enter valid cell phone ';
                }

            }


            if (value.homeNo && !phoneNoRegexp.test(`${value.homeCountryCode}${value.homeNo}`)) {
                errors.homeNo = 'Please enter valid home phone ';
            }



        }
        else {




            if (!value.lastName || value.lastName.trim() === '') {
                errors.lastName = 'Please enter last name';
            } else {
                // if(!nameRegEx.test(value.lastName)){
                //     errors.lastName = 'Please enter valid last name';
                // }
                // else 
                if (value.lastName.length >= 51) {
                    errors.lastName = 'Please enter last Name less then or equal to 10 characters'
                }

            }



            if (!value.firstName || value.firstName.trim() === '') {
                errors.firstName = 'Please enter first name';
            } else {

                if (value.firstName.length >= 51) {
                    errors.firstName = 'Please enter first Name less then or equal to 50 characters'
                }
            }



            if (value.middleName && value.middleName.length >= 51) {
                errors.middleName = 'Please enter  middle name less than or equal to 50 characters';
            }


            if (!value.addressLine1 || value.addressLine1.trim() === '') {
                errors.addressLine1 = 'Please enter address line 1 ';
            } else {
                if (value.addressLine1.length >= 101) {
                    errors.addressLine1 = 'Please enter address line 1 less then or equal to 100 characters'
                }
            }


            if (value.addressLine2 && value.addressLine2.length >= 101) {
                errors.addressLine2 = 'Please enter address line 2 less than or equal to 100 characters';
            }


            if (!value.countryId) {
                errors.countryId = 'Please select country'
            }
            if (!value.stateId) {
                errors.stateId = 'Please select state'
            }
            if (!value.cityId) {
                errors.cityId = 'Please select state'
            }




            if (!value.zipCode) {
                errors.zipCode = 'Please enter zip ';
            }
            else if (!ZipRegEx.test(value.zipCode)) {
                errors.zipCode = 'Please enter valid zip';
            }



            if (!value.realtionshipId) {
                errors.realtionshipId = 'Please select relation with patient'
            }

            if (!value.emailAddress || value.emailAddress.trim() === '') {
                errors.emailAddress = 'Please enter email';
            }
            else {
                if (!EmailRegExp.test(value.emailAddress)) {
                    errors.emailAddress = 'Please enter valid email';
                }
                else if (value.emailAddress.length >= 251) {
                    errors.emailAddress = 'Please enter email less than or equal to 250 characters';
                }
            }

            if (!value.mobileNo) {
                errors.mobileNo = 'Please enter cell phone  ';
            }
            else {
                if (!phoneNoRegexp.test(`${value.mobileCountryCode}${value.mobileNo}`)) {
                    errors.mobileNo = 'Please enter valid cell phone ';
                }

            }



            if (value.homeNo && !phoneNoRegexp.test(`${value.homeCountryCode}${value.homeNo}`)) {
                errors.homeNo = 'Please enter valid home phone ';
            }




        }

        return errors
    }

    const handleSubmit = () => {

        let data = {
            patientInfoTypeId: props.popUpNo,
            patientId: id,
            addressTypeId,
            addressLine1,
            addressLine2,
            cityId,
            stateId,
            countryId,
            zipCode,

            emailAddress,
            homeCountryCode,
            homeNo,
            mobileCountryCode,
            mobileNo,

            preferredContactMethodId,
            notes,

            suffixId,
            lastName,
            firstName,
            middleName,
            prefixId,
            realtionshipId,
            additionalPermissionId
        }

        console.log(data);


        let err = validation(data)

        if (Object.keys(err).length === 0) {
            updateAdditionalInfo(data)
            setErrAddresstype(err.addressTypeId)
            setErrAddressLine1(err.addressLine1)
            setErrAddressLine2(err.addressLine2)
            setErrcountry(err.countryId)
            setErrState(err.stateId)
            setErrCity(err.cityId)
            setErrZipcode(err.zipCode)
            setErrRelationShipId(err.realtionshipId)
            setErrLastName(err.lastName)
            setErrFirstName(err.firstName)
            setErrEmail(err.emailAddress)
            setErrCellPhone(err.mobileNo)
            setErrHomePhone(err.homeNo)
        }

        else {
            setErrAddresstype(err.addressTypeId)
            setErrAddressLine1(err.addressLine1)
            setErrAddressLine2(err.addressLine2)
            setErrcountry(err.countryId)
            setErrState(err.stateId)
            setErrCity(err.cityId)
            setErrZipcode(err.zipCode)
            setErrRelationShipId(err.realtionshipId)
            setErrLastName(err.lastName)
            setErrFirstName(err.firstName)
            setErrEmail(err.emailAddress)
            setErrCellPhone(err.mobileNo)
            setErrHomePhone(err.homeNo)
        }
    }


    console.log(props.popUpNo == props?.listOfAdditionalInfo[0]._id );
    

    return (
        <div>
            <Modal show={props.open} onHide={handleClose} size='xl' centered>
                <Modal.Header
                    className='h-25px dialoge d-flex align-items-center pb-3  '
                    closeButton >

                    <div className='title-header'>
                        {props.headerName}
                    </div>

                </Modal.Header>

                <Modal.Body className='p-2 dialoge'>

                    <div className='card p-3  '>

                        {

                            (props.popUpNo == props?.listOfAdditionalInfo[0]._id ) && (
                                <>
                                    <div className='row  '>
                                        <div className='col-md-6 col-lg-4 mb-4'>
                                            <label htmlFor="" className='patient-info-title  required'>Address Type:</label>
                                            <SearchableDropdown
                                                options={allAddressTypeList}
                                                gettingData={getTheAddresstype}
                                                InitialValue={addressTypeId}
                                            />
                                            {
                                                errAddresstype && (
                                                    <>
                                                        <span className='form-label errMsg'>
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                            &nbsp;
                                                            {/* {inputText.email.ErrMsg} */}
                                                            {errAddresstype}
                                                        </span>

                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className='row  '>
                                        <AddressComponent
                                            col={4}
                                            address1value={addressLine1}
                                            address2value={addressLine2}
                                            countryValue={countryId}
                                            stateValue={stateId}
                                            cityValue={cityId}
                                            zipcodevalue={zipCode}

                                            Address1get={getAddress1}
                                            Address2get={getAddress2}
                                            Cityget={getCity}
                                            StateGet={getState}
                                            countryget={getCountry}
                                            zipcodeget={getZipcode}

                                            address1Err={errAddressLine1}
                                            address2Err={errAddressLine2}
                                            cityErr={errCity}
                                            stateErr={errState}
                                            countryErr={errcountry}
                                            zipErr={errZipcode}

                                        />

                                    </div>
                                    <div className='row'>
                                        <ContactComponent
                                            col={4}
                                            emailValue={emailAddress}
                                            contactno1Value={`${mobileCountryCode}${mobileNo}`}
                                            contactno3Value={`${homeCountryCode}${homeNo}`}

                                            getTheEmail={getTheEmail}
                                            getTheCellNumber={getCellNumber}
                                            getTheHomeNumber={getHomeNumber}

                                            emailErr={errEmail}
                                            cellNoErr={errCellPhone}
                                            homeNoErr={errHomePhone}
                                        />
                                    </div>

                                </>

                            )}


                        {
                            (props.popUpNo !== props?.listOfAdditionalInfo[0]._id ) && (
                                <>
                                    <div className='row  '>
                                        <div className='col-md-6 col-lg-4 mb-4'>
                                            <label htmlFor="" className='patient-info-title required '>Relation With Patient:</label>
                                            <SearchableDropdown
                                                options={allRelationwithPatientList}
                                                InitialValue={realtionshipId}
                                                gettingData={getTherealtionshipId} />
                                            {
                                                errRelationShipId && (
                                                    <>
                                                        <span className='form-label errMsg'>
                                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                                            &nbsp;
                                                            {/* {inputText.email.ErrMsg} */}
                                                            {errRelationShipId}
                                                        </span>

                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <NameCompoForClinic
                                            colno={4}
                                            prefixValue={prefixId}
                                            lastNameValue={lastName}
                                            firstNameValue={firstName}
                                            middleNameValue={middleName}
                                            suffixValue={suffixId}
                                            getPrefixData={getprefix}
                                            getSuffixData={getsuffix}
                                            getfName={getFirstName}
                                            getlName={getLastName}
                                            getmName={getMiddleName}

                                            lastNameErr={errLastName}
                                            firstNameErr={errFirstName}
                                            middleNameErr={errMiddleName}

                                        />
                                    </div>

                                    <div className='row'>
                                        <ContactComponent
                                            col={4}
                                            emailValue={emailAddress}
                                            contactno1Value={`${mobileCountryCode}${mobileNo}`}
                                            contactno3Value={`${homeCountryCode}${homeNo}`}

                                            getTheEmail={getTheEmail}
                                            getTheCellNumber={getCellNumber}
                                            getTheHomeNumber={getHomeNumber}

                                            emailErr={errEmail}
                                            cellNoErr={errCellPhone}
                                            homeNoErr={errHomePhone}
                                        />
                                    </div>

                                    <div className='row  '>
                                        <AddressComponent
                                            col={4}

                                            address1value={addressLine1}
                                            address2value={addressLine2}
                                            countryValue={countryId}
                                            stateValue={stateId}
                                            cityValue={cityId}
                                            zipcodevalue={zipCode}

                                            Address1get={getAddress1}
                                            Address2get={getAddress2}
                                            Cityget={getCity}
                                            StateGet={getState}
                                            countryget={getCountry}
                                            zipcodeget={getZipcode}

                                            address1Err={errAddressLine1}
                                            address2Err={errAddressLine2}
                                            cityErr={errCity}
                                            stateErr={errState}
                                            countryErr={errcountry}
                                            zipErr={errZipcode}
                                        />

                                    </div>
                                </>
                            )
                        }


                        <div className='row'>
                            <div className='col-md-6 col-lg-4 mb-4'>
                                <label htmlFor="" className='patient-info-title '>Preferred contact Method:</label>
                                <SearchableDropdown
                                    gettingData={gethePreferredContactMethod}
                                    InitialValue={preferredContactMethodId}
                                    options={allPreferredContactMethodList} />

                            </div>
                            <div className='col-md-6 col-lg-4 mb-4'>
                                <label htmlFor="" className='patient-info-title '>Note:</label>
                                <input
                                    onChange={getTheNotes}
                                    value={notes}
                                    type="text"
                                    className='form-control SearchInputbox'

                                />
                            </div>
                        </div>

                        {/* {

                            props.popUpNo == 1 && (
                                <div className='d-flex gap-6'>
                                    <div className=' d-flex align-items-center ' >
                                        <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                            <input
                                                className=' '
                                                type='checkbox'
                                                // onChange={getexcludeFromPatientStatement}
                                                // checked={excludeFromPatientStatement}
                                                id='flexCheckDefault1'
                                            />
                                            <label className='patient-info-title' htmlFor='flexCheckDefault1'>I permit the healthcare facility to bill at the alternate address</label>

                                        </div>
                                    </div>

                                    <div className=' d-flex align-items-center' >
                                        <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                            <input
                                                className=' '
                                                type='checkbox'
                                                // value={phiexpemtedFromReport}
                                                // onChange={getphiexpemtedFromReport}
                                                // checked={phiexpemtedFromReport}
                                                id='flexCheckDefault2'
                                            />
                                            <label className='patient-info-title' htmlFor='flexCheckDefault2'>I understand that the alternative address might be utilized in case of an emergency</label>


                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {

                            props.popUpNo == 2 && (
                                <div className='d-flex gap-6'>
                                    <div className=' d-flex align-items-center ' >
                                        <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                            <input
                                                className=' '
                                                type='checkbox'
                                                // onChange={getexcludeFromPatientStatement}
                                                // checked={excludeFromPatientStatement}
                                                id='flexCheckDefault1'
                                            />
                                            <label className='patient-info-title' htmlFor='flexCheckDefault1'>I acknowledge that I have provided Next of Kin information</label>

                                        </div>
                                    </div>

                                    <div className=' d-flex align-items-center' >
                                        <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                            <input
                                                className=' '
                                                type='checkbox'
                                                // value={phiexpemtedFromReport}
                                                // onChange={getphiexpemtedFromReport}
                                                // checked={phiexpemtedFromReport}
                                                id='flexCheckDefault2'
                                            />
                                            <label className='patient-info-title' htmlFor='flexCheckDefault2'>Enable Portal Login for Patient</label>


                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            (props.popUpNo == 3) && (
                                <>
                                    <div className='d-flex gap-6'>
                                        <div className=' d-flex align-items-center ' >
                                            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                                <input
                                                    className=' '
                                                    type='checkbox'
                                                    // onChange={getexcludeFromPatientStatement}
                                                    // checked={excludeFromPatientStatement}
                                                    id='flexCheckDefault1'
                                                />
                                                <label className='patient-info-title' htmlFor='flexCheckDefault1'>I give authority to grantor to make healthcare decision </label>

                                            </div>
                                        </div>

                                        <div className=' d-flex align-items-center' >
                                            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                                <input
                                                    className=' '
                                                    type='checkbox'
                                                    // value={phiexpemtedFromReport}
                                                    // onChange={getphiexpemtedFromReport}
                                                    // checked={phiexpemtedFromReport}
                                                    id='flexCheckDefault2'
                                                />
                                                <label className='patient-info-title' htmlFor='flexCheckDefault2'>I give allowance to access the medical records</label>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-6'>
                                        <div className=' d-flex align-items-center ' >
                                            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                                <input
                                                    className=' '
                                                    type='checkbox'
                                                    // onChange={getexcludeFromPatientStatement}
                                                    // checked={excludeFromPatientStatement}
                                                    id='flexCheckDefault3'
                                                />
                                                <label className='patient-info-title' htmlFor='flexCheckDefault3'>If applicable, specify any financial Powers granted to grantor  </label>

                                            </div>
                                        </div>

                                        <div className=' d-flex align-items-center' >
                                            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                                <input
                                                    className=' '
                                                    type='checkbox'
                                                    // value={phiexpemtedFromReport}
                                                    // onChange={getphiexpemtedFromReport}
                                                    // checked={phiexpemtedFromReport}
                                                    id='flexCheckDefault4'
                                                />
                                                <label className='patient-info-title' htmlFor='flexCheckDefault4'>Enable Portal Login for Patient</label>


                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (props.popUpNo == 4 || props.popUpNo == 5 || props.popUpNo == 6) && (
                                <>
                                    <div className='d-flex gap-6'>
                                        <div className=' d-flex align-items-center ' >
                                            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                                <input
                                                    className=' '
                                                    type='checkbox'
                                                    // onChange={getexcludeFromPatientStatement}
                                                    // checked={excludeFromPatientStatement}
                                                    id='flexCheckDefault1'
                                                />
                                                <label className='patient-info-title' htmlFor='flexCheckDefault1'>In the event of a medical emergency, call the authorized emergency contact</label>

                                            </div>
                                        </div>

                                        <div className=' d-flex align-items-center' >
                                            <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                                <input
                                                    className=' '
                                                    type='checkbox'
                                                    // value={phiexpemtedFromReport}
                                                    // onChange={getphiexpemtedFromReport}
                                                    // checked={phiexpemtedFromReport}
                                                    id='flexCheckDefault2'
                                                />
                                                <label className='patient-info-title' htmlFor='flexCheckDefault2'>Enable Portal Login for Patient</label>


                                            </div>
                                        </div>
                                    </div>

                                </>
                            )
                        } */}


                        {
                            permissionList.map((i: any) => (
                                <div className=' d-flex align-items-center ' key={i._id}>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm d-felx gap-2'>
                                        <input
                                            className=''
                                            type='checkbox'
                                            onChange={(e) => gettheCheckBoxValue(i._id, e.target.checked)}
                                            checked={additionalPermissionId.includes(i._id)}
                                            id={`flexCheckDefault${i?._id}`}

                                        />
                                        <label className='patient-info-title' htmlFor={`flexCheckDefault${i?._id}`}>{i?.additionalInfoPermission}</label>

                                    </div>
                                </div>
                            ))
                        }

                    </div>

                    <div className=' p-3 d-flex gap-2' style={{ float: 'right' }}>
                        <button
                            onClick={handleSubmit}
                            className='btn btn-sm btn-primary fw-bold fs-4'>
                            Save
                        </button>
                        <button onClick={handleClose} className='biggrayButton'>
                            Close
                        </button>
                    </div>

                </Modal.Body>





            </Modal>
        </div>
    )
}

export default AdditionInfoPopupsEdit