import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'
import SearchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown'
import SearchableGroup from '../../../ReUsableComponent/SearchableGroup'
import axios from 'axios'
import moment from 'moment'

function EditAppointment({ open, closeEditAppointment, editAppointmentId, editAppPatientId }) {

    // console.log(editAppointmentId);

    const primaryID = "65e1bf9efaa2c2d3a1c82151";
    const secandaryID = "65e1c060faa2c2d3a1c82153";
    const tertiaryID = "65e1c087faa2c2d3a1c82154";


    const APIkey = process.env.REACT_APP_SERVERAPI;

    const [withList, setwithList] = useState([])
    const [posCodeList ,setPosCodeList] = useState([]);

    const [resourceData, setResourceData] = useState([])
    const [appointmentStatusData, setAppointmentStatusData] = useState([])
    const [clinicNameList, setClinicNameList] = useState([])
    const [visitReasonData, setVisitReasonData] = useState([])
    const [appointmentModeData, setAppointmentModeData] = useState([])
    const [patientInsuranceList, setPatientInsuranceList] = useState([])
    const [patientPrimaryList, setPatientPrimaryList] = useState([])
    const [patientSecondaryList, setPatientSecondaryList] = useState([])
    const [patientTerteryList, setPatientTerteryList] = useState([])


    const [appointmentDateshow, setAppointmentDateshow] = useState()
    const [appointmentTimeshow, setAppointmentTimeshow] = useState()
    const [patientFirstName, setPatientFirstName] = useState()
    const [patientLastName, setPatientLastName] = useState()
    const [patientMiddleName, setPatientMiddleName] = useState()
    const [patientDob, setPatientDob] = useState()

    const [appointmentDate, setAppointmentDate] = useState<string | undefined>()
    const [appointmentTime, setAppointmentTime] = useState<string | undefined>()
    const [clinicId, setClinicName] = useState<string | undefined>()
    const [designationId, setdesignation] = useState()
    const [userId, setUserId] = useState()
    const [reasonForVisit, setVisitReason] = useState()
    const [visitLength, setVisitLength] = useState()

    const [appointmentStatusId, setAppointmentStatus] = useState()
    const [patientInsuranceId, setPatientInsurance] = useState()
    const [notes, setNotes] = useState()
    const [colorCode, setColordiv] = useState()

    const [resourceId, setResource] = useState<any>() 

    const [ primaryid , setPrimaryid] = useState<any>()
    const [ secondaryid , setSecondaryid] = useState<any>()
    const [ tertiaryid , setTetiaryid] = useState<any>()
    const [ posId , setPosId] = useState<any>()
    

    //Errmsg Start here ...
    const [errDesignation, setErrDesignation] = useState<string>()
    const [appointmentDateErr, setAppointmentDateErr] = useState<string>()
    const [appointmentTimeErr, setAppointmentTimeErr] = useState<string>()
    const [clinicNameErr, setClinicNameErr] = useState<string>()
    const [userIdErr, setUserIdErr] = useState<string>()
    const [appointmentStatusErr, setappointmentStatusErr] = useState<string>()
    const [visitLengthErr, setVisitLengthErr] = useState<string>()
    

    useEffect(() => {
        const getDataByAPI = async () => {
            try {
                const getloginInfo = localStorage.getItem('appLoginInfo');

                if (getloginInfo) {
                    const parsedLoginInfo = JSON.parse(getloginInfo);
                    const Token = parsedLoginInfo.data.accessToken;
                    const Id = parsedLoginInfo.data.clinicId;

                    const header = {
                        headers: { 'x-access-token': Token }
                    };

                    const [
                        appointmentModeList,
                        appointmentStatusList,
                        appointmentTypeList,
                        visitReasonList,
                        resourceList,
                        clinicNameData,
                        withList,
                        userList,
                        posList,
                        patientInsuranceData,
                        databyId
                    ] = await Promise.all([
                        axios.get(`${APIkey}/api/admin/getAppointmentModeList/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/getAppointmentStatusList/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/getAppointmentTypeList/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/getReasonForVisitList/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/getResourceList/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/getGroupIdWiseClinic/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/getDesignationList/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/listUsersByClinicId/${Id}`, header),
                        axios.get(`${APIkey}/api/admin/getPosList`, header),
                        axios.get(`${APIkey}/api/admin/getPatientInsuranceByPatientId/${editAppPatientId}`, header),
                        axios.get(`${APIkey}/api/admin/getAppointmentById/${editAppointmentId}`, header)
                    ]);

                    const appointmentModeData = appointmentModeList.data.data.map(i => ({
                        label: i.appointmentMode,
                        value: i.appointmentMode,
                        id: i._id
                    }));

                    const appointmentStatusData = appointmentStatusList.data.data.map(i => ({
                        label: i.appointmentStatus,
                        value: i.appointmentStatus,
                        id: i._id
                    }));

                    const visitReasonData = visitReasonList.data.data.map(i => ({
                        label: i?.reason,
                        value: i?.reason,
                        id: i._id
                    }));

                    const resourceData = resourceList?.data?.data?.map(i => ({
                        label: i.resource,
                        value: i.resource,
                        id: i._id
                    }));

                    const clinicNameList = clinicNameData?.data?.data?.map(i => ({
                        label: i.clinicName,
                        value: i.clinicName,
                        id: i._id
                    }));

            const posData = posList?.data?.data?.map(i => ({
                label: i.posCode,
                value: i.posCode,
                id: i._id
            }));
       
            

                    const withListData = withList.data.data.map(i => ({
                        label: i.designation,
                        options: userList.data.data
                            .filter(j => j.designationId === i._id)
                            .map(j => ({
                                value: `${j.firstName} ${j.lastName} - ${i.designation}`,
                                label: `${j.firstName} ${j.lastName}`,
                                Did: j.designationId,
                                Uid: j._id
                            }))
                    }));

                    const patientPrimaryList = patientInsuranceData.data.data
                        .filter(i => i.layerOfCoverageDetails._id === primaryID)
                        .map(i => ({
                            label: i.insurancePlanName,
                            value: i.insurancePlanName,
                            id: i._id
                        }));

                    const patientSecondaryList = patientInsuranceData.data.data
                        .filter(i => i.layerOfCoverageDetails._id === secandaryID)
                        .map(i => ({
                            label: i.insurancePlanName,
                            value: i.insurancePlanName,
                            id: i._id
                        }));

                    const patientTerteryList = patientInsuranceData.data.data
                        .filter(i => i.layerOfCoverageDetails._id === tertiaryID)
                        .map(i => ({
                            label: i.insurancePlanName,
                            value: i.insurancePlanName,
                            id: i._id
                        }));

                    const databyApi = databyId?.data?.data;
                    const rId = databyApi?.resourceId;

                console.log("UseEffect : " ,  databyApi?.reasonForVisit);
                
                    

                    setAppointmentModeData(appointmentModeData);
                    setAppointmentStatusData(appointmentStatusData);
                    setVisitReasonData(visitReasonData);
                    setResourceData(resourceData);
                    setClinicNameList(clinicNameList);
                    setPosCodeList(posData);
                    setwithList(withListData);
                    setPatientPrimaryList(patientPrimaryList);
                    setPatientSecondaryList(patientSecondaryList);
                    setPatientTerteryList(patientTerteryList);
                    setAppointmentDateshow(databyApi?.appointmentDate);
                    setAppointmentTimeshow(databyApi?.appointmentTime);
                    setPatientFirstName(databyApi?.patientDetails?.firstName);
                    setPatientLastName(databyApi?.patientDetails?.lastName);
                    setPatientMiddleName(databyApi?.patientDetails?.middleName);
                    setPatientDob(databyApi?.patientDetails?.dob);
                    setAppointmentDate(databyApi?.appointmentDate);
                    setAppointmentTime(databyApi?.appointmentTime);
                    setClinicName(databyApi?.clinicId);
                    setdesignation(databyApi?.designationId);
                    setUserId(databyApi?.userId);
                    setVisitReason(databyApi?.reasonForVisit);
                    setVisitLength(databyApi?.visitLength);
                    setResource(rId);
                    setAppointmentStatus(databyApi?.appointmentStatusId);
                    setUserId(databyApi?.userId);
                    setPatientInsurance(databyApi?.patientInsuranceId);
                    setNotes(databyApi?.notes);
                    setColordiv(databyApi?.colorCode);
                    setPosId(databyApi?.posId);
                    
            
                } else {
                    console.log("No login info found in localStorage");
                }
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        getDataByAPI();
    }, []);

    


    const handleUpdate = () => {
        closeEditAppointment()
    }


    const getTheAppointment = (e) => {
        setAppointmentDate(e.target.value);
    }


    const getTheAppointmentTime = (e) => {
        // console.log(e.target.value);
        setAppointmentTime(e.target.value)
    }

    const getTheClinicName = (e) => {
        setClinicName(e)
        // console.log(e);

    }

    const getThePosCode =(e)=> {
        setPosId(e)
    }


    const gettingWith = (data) => {
        setdesignation(data.Did)
        setUserId(data.Uid)
    }

    const getTheVisitReason = async (id) => {
        
        setVisitReason(id)

        try {
            let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;

                const clinicId = JSON.parse(getloginInfo).data.clinicId;

                const header = {
                    headers: { 'x-access-token': Token }
                }


                const visitReasonList = await axios.get(`${APIkey}/api/admin/getReasonForVisitById/${clinicId}/${id}`, header);

                // console.log(visitReasonList.data.data);

                let data = visitReasonList.data.data;

                if (data) {
                    setVisitLength(data.time)
                    setColordiv(data.color)
                }

            }
            else {
                console.log("No login info found in localStorage");
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTheVisitLength =(e)=> {
        setVisitLength(e.target.length)
    }

    const getTheResource = (e) => {

        console.log("get the Resource : " ,e);
        
        setResource(e);
    }

    const getThePrimaryInsurance =(e)=> {
        setPrimaryid(e) 
    }

    const getTheSecondaryInsurance =(e)=> {
        setSecondaryid(e)
    }

    const getTheTertiaryInsurance =(e)=> {
        setTetiaryid(e)
    }

    const getTheNotes = (e) => {
        setNotes(e.target.value)
    }
    
    const getTheAppointmentStatus =(e)=> {
        setAppointmentStatus(e)
    }

    const formateDateShow = (d) => moment(d).format('L')
    const getDay = (d) => moment(d).format('dddd')
    const dateforfield = (d) => moment(d).format('yyyy-MM-DD')



    const today = new Date().toISOString().split('T')[0];

    //validation 
    function validation(value: any): { [key: string]: string } {
        const errors: { [key: string]: string } = {};

        if (!value.appointmentDate) {
            errors.appointmentDate = "Please select valid date"
        }

        if (!value.appointmentTime) {
            errors.appointmentTime = "Please select valid time"
        }

        if (!value.clinicId) {
            errors.clinicId = "Please select clinic name"
        }


        if(!value.userId){
            errors.userId = "Please select with";

        }

        if(!value.appointmentStatusId){
            errors.appointmentStatusId ="PLease select appointment status"
        }

        if(!value.visitLength){
            errors.visitLength = "Please enter visit length"
        }


        return errors;
    }

    const handleUpdateAppointment = async(data) => {

        console.log(data);
        
        try {
            let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;
                const header = {
                    headers: { 'x-access-token': Token }
                }
                const response = await axios.post(`${APIkey}/api/admin/updateAppointmentById/${editAppointmentId}`, data, header);
                console.log(response);

                if(response){
                    closeEditAppointment()
                }

            }
            else {
                console.log("No login info found in localStorage");
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleSubmit = () => {


        let data = {
            appointmentDate,
            appointmentTime,
            clinicId,
            designationId,
            userId,
            visitLength,
            appointmentStatusId,
            patientInsuranceId,
            colorCode,
            resourceId,
            notes,
            reasonForVisit , 
            posId
        }


        // console.log(data);


        let err = validation(data)

        if (Object.keys(err).length === 0) {

            console.log("submit ... ");

            handleUpdateAppointment(data)

            setAppointmentDateErr('');
            setAppointmentTimeErr('');  
            setClinicNameErr('')
            setUserIdErr('')
            setappointmentStatusErr('')
            setVisitLengthErr('')

        }
        else {
            setAppointmentDateErr(err.appointmentDate);
            setAppointmentTimeErr(err.appointmentTime);
            setClinicNameErr(err.clinicId)
            setUserIdErr(err.userId)
            setappointmentStatusErr(err.appointmentStatusId)
            setVisitLengthErr(err.appointmentStatusId)
            

        }





    }



    // console.log(withList);



    return (
        <>
            <Modal
                show={open}
                onHide={handleUpdate}
                centered
                size='xl'
                className='' >

                <Modal.Header

                    className='h-25px dialoge d-flex align-items-center pb-3  '
                    closeButton>

                    <div className='fs-2 fw-bold '>
                        Edit Appointment on {getDay(appointmentDateshow)}, {formateDateShow(appointmentDateshow)}, {appointmentTimeshow && appointmentTimeshow}
                    </div>

                </Modal.Header>
                <Modal.Body className='p-2 dialoge' >
                    <div className='card p-3 mb-2 '>
                        <div className='row  '>
                            <div className='col-md-6 col-lg-6'>

                                <span className='patient-info-title'>
                                    Patient Name: {patientFirstName && patientFirstName}  {patientMiddleName && patientMiddleName} {patientLastName && patientLastName}
                                </span>

                            </div>

                            <div className='col-md-6 col-lg-6'>

                                <span className='patient-info-title'>
                                    DOB:  {formateDateShow(patientDob)}
                                </span>

                            </div>

                        </div>
                    </div>

                    <div className='card '>
                        <div className='PatientChartCardHeader'>
                            <span>Booking Details</span>
                        </div>
                        <div className='p-3 row'>
                            <div className=' col-md-12  col-lg-4'>

                                <div className='mb-2'>
                                    <label htmlFor="" className='patient-info-title required'>{inputText.onDay.label}</label>
                                    <input
                                        type="Date"
                                        min={today}
                                        value={dateforfield(appointmentDate)}
                                        onChange={getTheAppointment}
                                        className='form-control SearchInputbox' />
                                    {appointmentDateErr && (
                                        <span className='form-label errMsg'>
                                            {/* {inputText.lastName.ErrMsg} */}
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {appointmentDateErr}
                                        </span>
                                    )}
                                </div>
                                <div className='mb-2'>
                                    <label htmlFor="" className='patient-info-title required'>{inputText.At.label}</label>
                                    <input
                                        type="time"
                                        value={appointmentTime}
                                        onChange={getTheAppointmentTime}
                                        className='form-control SearchInputbox' />
                                    {appointmentTimeErr && (
                                        <span className='form-label errMsg'>
                                            {/* {inputText.lastName.ErrMsg} */}
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {appointmentTimeErr}
                                        </span>
                                    )}
                                </div>

                                <div className='mb-2 d-flex gap-2'>
                                    <div className='w-75'>

                                        <label htmlFor="" className='patient-info-title required'>{inputText.ClinicName.label}</label>
                                        <SearchableDropdown
                                            gettingData={getTheClinicName}
                                            InitialValue={clinicId}
                                            options={clinicNameList} />
                                        {clinicNameErr  && (
                                            <span className='form-label errMsg'>
                                                {/* {inputText.lastName.ErrMsg} */}
                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                &nbsp;
                                                {clinicNameErr}
                                            </span>
                                        )}
                                    </div>
                                    <div className='w-25'>

                                        <label htmlFor="" className='patient-info-title required'>POS Code:</label>
                                        <SearchableDropdown
                                        gettingData={getThePosCode}
                                        options={posCodeList}
                                        InitialValue={posId}
                                        />
                                    </div>

                                </div>
                                <div className='mb-2'>
                                    <SearchableGroup
                                        label={inputText?.with.label}
                                        required={true}
                                        gettingData={gettingWith}
                                        InitialValue={userId}
                                        placeHolder={inputText.with.placeholder}
                                        options={withList} />

                                    {
                                        userIdErr &&
                                        <span className='form-label errMsg'>
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {inputText.with.ErrMsg}
                                        </span>}
                                </div>

                                <div className='mb-2 d-flex gap-2'>
                                    <div className='w-75'>

                                        <label htmlFor="" className='patient-info-title '>{inputText.VisitReason.label}</label>
                                        <SearchableDropdown
                                            options={visitReasonData}
                                            gettingData={getTheVisitReason}
                                            InitialValue={reasonForVisit}
                                        />
                                    </div>
                                    <div className='w-25'>

                                        <label htmlFor="" className='patient-info-title required'>Time:</label>
                                        <input
                                        onChange={getTheVisitLength}
                                            value={visitLength}
                                            className='form-control SearchInputbox'
                                            type='text' />
                                             {
                                        visitLengthErr &&
                                        <span className='form-label errMsg'>
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {visitLengthErr}
                                        </span>}
                                    </div>

                                </div>

                                <div className='mb-2'>
                                    <SearchableDropdown
                                        label={inputText.Resource.label}
                                        gettingData={getTheResource}
                                        placeHolder={inputText.Resource.placeholder}
                                        options={resourceData}
                                        InitialValue={resourceId}
                                    />
                                </div>

                            </div>
                            <div className=' col-md-12 col-lg-4'>

                                <div className='mb-2'>
                                    <SearchableDropdown
                                        label={"Appointment Status:"}
                                        required={true}
                                        gettingData={getTheAppointmentStatus}
                                        placeHolder={'Select'}
                                        options={appointmentStatusData}
                                        InitialValue={appointmentStatusId}
                                    />
               {
                                        appointmentStatusErr &&
                                        <span className='form-label errMsg'>
                                            <i className="fa-solid fa-triangle-exclamation"></i>
                                            &nbsp;
                                            {appointmentStatusErr}
                                        </span>}
                                </div>

                                <div className='mb-2'>
                                    <label htmlFor="" className='patient-info-title'>Referral Provider:</label>
                                    <input type="text" className='form-control SearchInputbox' />
                                </div>

                                <div className='mb-2'>
                                    <SearchableDropdown
                                        label={"Primary:"}
                                        //   required={true}
                                        InitialValue={primaryid}
                                        gettingData={getThePrimaryInsurance}
                                        placeHolder={'Select'}
                                        options={patientPrimaryList}
                                        
                                    />
                                </div>
                                <div className='mb-2'>
                                    <SearchableDropdown
                                        label={"Secondary:"}
                                        //   required={true}
                                        gettingData={getTheSecondaryInsurance}
                                        InitialValue={secondaryid}
                                        placeHolder={'Select'}
                                        options={patientSecondaryList}
                                    />
                                </div>
                                <div className='mb-2'>
                                    <SearchableDropdown
                                        label={"Tertiary:"}
                                        //   required={true}
                                        gettingData={getTheTertiaryInsurance}
                                        placeHolder={'Select'}
                                        InitialValue ={tertiaryid}
                                        options={patientTerteryList}
                                    />
                                </div>

                            </div>
                            <div className=' col-md-12 col-lg-4 '>
                                <div className='mb-3 d-flex align-items-center h-45px '>
                                    <div className='w-50 d-flex align-items-center gap-1'>
                                        <input type="checkbox" id='selfPay' />
                                        <label htmlFor="selfPay" className='patient-info-title '>Self Pay</label>
                                    </div>
                                    <div className='w-50 d-flex align-items-center gap-1 '>
                                        <input type="checkbox" id='Billable' />
                                        <label htmlFor="Billable" className='patient-info-title '> Billable</label>
                                    </div>
                                </div>

                                <div className='mt-2 mb-2'>
                                    <label htmlFor="" className='patient-info-title'>Expected Copay ($):</label>
                                    <div className='w-100 d-flex align-items-center gap-1'>
                                        <div className='w-50 d-flex gap-1'>

                                            <input type="text" className='form-control SearchInputbox' name="" id="" />

                                            <button className='blueButtonSmall'>
                                                <i className="fa-solid fa-pen"></i>
                                            </button>
                                        </div>
                                        <div className='w-50'>
                                            <span className='patient-info-title'>

                                                View Outstanding Copay
                                            </span>
                                        </div>

                                    </div>
                                </div>

                                <div className='mt-2 mb-2'>
                                    <label htmlFor="" className='patient-info-title'>Exp. Allowable ($):</label>
                                    <div className='w-100 d-flex align-items-center gap-1'>
                                        <div className='w-50 d-flex gap-2'>

                                            <input type="text" className='form-control SearchInputbox' name="" id="" />

                                            <div className='d-flex align-items-center'>
                                                <i className="fa-solid fa-arrows-rotate" style={{ fontSize: '25px', color: 'black' }}></i>
                                            </div>
                                        </div>
                                        <div className='w-50'>

                                        </div>

                                    </div>
                                </div>
                                <div className='mt-2 mb-2'>
                                    <label htmlFor="" className='patient-info-title'>Pre. Authorization#:</label>
                                    <div className='  align-items-center gap-1 ' style={{ position: "relative", width: '100%' }}>


                                        <input type="text" className='form-control SearchInputbox' name="" id="" />

                                        <div style={{
                                            position: 'absolute',
                                            right: '5px',
                                            top: '5px',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer'
                                        }}>

                                            <i className="fa-solid fa-magnifying-glass" style={{ color: "black", fontSize: "20px" }}></i>
                                        </div>

                                        <div className='w-50'>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-3 row'>
                            <div className='col-lg-12 col-md-12'>
                                <label htmlFor="note" className='patient-info-title '>{inputText.notes.label}</label>
                                <textarea
                                    value={notes}
                                    onChange={getTheNotes}
                                    className='form-control SearchInputbox'
                                    name=""
                                    id="note" ></textarea>
                            </div>
                        </div>
                    </div>


                    <div className=' p-3 ' style={{ float: 'right' }}>
                        <button
                            onClick={handleSubmit}
                            className='btn btn-sm btn-primary'>
                            Update Appointment
                        </button>
                    </div>

                </Modal.Body>
                <Modal.Footer 
                 className='dialoge p-1'
                ></Modal.Footer>
                  

            </Modal>
        </>
    )
}

export default EditAppointment