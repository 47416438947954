import React, {useState} from 'react';

function PaginationCompo(props) {
  const handlePageChange = (pageNumber: number): void => {
    props.handlePageChange(pageNumber);
  };

  const goToFirstPage = () => {
    props.handlePageChange(1);
  };

  const goToLastPage = () => {
    const lastPage = Math.ceil(props.totalData / props.perPage);
    props.handlePageChange(lastPage);
  };

  return (
    <>
      {/* {props.totalData > props.perPage && ( */}
        <div>
          <nav aria-label='Page navigation example'>
            <ul className='pagination justify-content-center'>
              <li className={`page-item ${props.currentPage === 1 ? 'disabled' : ''}`}>
                <button className='page-link' onClick={goToFirstPage}>
                  &lt;&lt;
                </button>
              </li>
              <li className={`page-item ${props.currentPage === 1 ? 'disabled' : ''}`}>
                <button
                  className='page-link'
                  onClick={() => handlePageChange(props.currentPage - 1)}
                >
                  &lt;
                </button>
              </li>
              {Array.from(Array(Math.ceil(props.totalData / props.perPage)).keys()).length >= 6
                ? Array.from(Array(Math.ceil(props.totalData / props.perPage)).keys()).map(
                    (number) => {
                      if (
                        number === 0 ||
                        number === Math.ceil(props.totalData / props.perPage) - 1 ||
                        number === props.currentPage - 1 ||
                        number === props.currentPage ||
                        number === props.currentPage + 1
                      ) {
                        return (
                          <li
                            key={number}
                            className={`page-item ${
                              props.currentPage === number + 1 ? 'active' : ''
                            }`}
                          >
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(number + 1)}
                            >
                              {number + 1}
                            </button>
                          </li>
                        );
                      } else if (
                        number === props.currentPage - 2 ||
                        number === props.currentPage + 2
                      ) {
                        return (
                          <li key={number} className='page-item disabled'>
                            <button className='page-link' disabled>
                              ...
                            </button>
                          </li>
                        );
                      }
                      return null;
                    }
                  )
                : Array.from(Array(Math.ceil(props.totalData / props.perPage)).keys()).map(
                    (number) => (
                      <li
                        key={number}
                        className={`page-item ${props.currentPage === number + 1 ? 'active' : ''}`}
                      >
                        <button className='page-link' onClick={() => handlePageChange(number + 1)}>
                          {number + 1}
                        </button>
                      </li>
                    )
                  )}
              <li
                className={`page-item ${
                  props.currentPage >= Math.ceil(props.totalData / props.perPage) ? 'disabled' : ''
                }`}
              >
                <button
                  className='page-link'
                  onClick={() => handlePageChange(props.currentPage + 1)}
                >
                  &gt;
                </button>
              </li>
              <li
                className={`page-item ${
                  props.currentPage >= Math.ceil(props.totalData / props.perPage) ? 'disabled' : ''
                }`}
              >
                <button className='page-link' onClick={goToLastPage}>
                  &gt;&gt;
                </button>
              </li>
            </ul>
          </nav>
        </div>
      {/* )} */}
    </>
  );
}

export default PaginationCompo;
