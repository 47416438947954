import { useEffect, useState } from 'react';
import Select from 'react-select'



function SerchableDropdown(props) {
  const [data, setData] = useState(null); // Initialize data state to null


  useEffect(() => {
    // Set the initial value when props.InitialValue changes
    if (props.InitialValue !== undefined && props.InitialValue !== null && data === null) {
      setData(props.InitialValue);
    }
  }, [props.InitialValue, data]);


  const elsepart = []

  // const option = props.options ? props.options.map((i) => {


  //   return {
  //     value: i.value,
  //     label: <span style={{textOverflow:'ellipsis' , overflow:"hidden"}} title={i.label}>{i.label}</span>,
  //     id : i.id
  //   }
  // }) : elsepart


  // useEffect(() => {

  //   // Check if there's only one option and set it as default value
  //   if (props.options.length === 1) {

  //     setData(props.options[0].id)
  //     props.gettingData(props.options[0].id);

  //   }
  // }, [props.options, props]);


  function handelChange(e) {
    setData(e.Uid)
    props.gettingData(e)
  }




  const matchValue = (data, value) => {
    if (data && value) {

      let m = props.options.map(i => i.options.filter(j => j.Uid == data))
      let n = m.filter(i => i[0])[0][0]

      return n;
    } else {
      return null;
    }

  }




  return (

    <div className='w-100'>
      {
        props.label &&
        <label
          className={props.required ? 'patient-info-title required ' : 'patient-info-title'}
          htmlFor="">{props.label}</label>

      }
      <Select
        className='h-28px react-select-styled'
        classNamePrefix='react-select'
        options={props.options}
        placeholder={props.placeHolder}
        maxMenuHeight={150}
        onChange={handelChange}
        value={matchValue(data, props.InitialValue)}
        styles={{

          control: (provided, state) => ({
            ...provided,
            borderRadius: "5px",
            background: '#fff',
            borderColor: '#dbdfe9',
            minHeight: '30px',
            height: '30px',
            fontWeight: "500",
            color: "#99a1b7 !important"

          }),

          valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
          }),

          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
          option: (provided, state) => ({
            ...provided,
            overflow: 'hidden', // Apply overflow hidden
            textOverflow: 'ellipsis', // Apply ellipsis
            maxWidth: '100%', 
            fontSize: '12px',
            padding: '1px',
            height: '30px',// Set font size to 12px for options
            display: "flex",
            alignItems: "center",
            color: 'black',
            fontWeight: '600',
            paddingLeft: "4px"
          })
        }
        }
      />
    </div>
  )
}

export default SerchableDropdown
