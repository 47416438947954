import { useEffect, useState } from 'react'
// import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
// import {useFormik} from 'formik'
// import {requestPassword} from '../../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import axios from 'axios'
import { Modal } from 'react-bootstrap'


// const forgotPasswordSchema = Yup.object().shape({
//   email: Yup.string()
//   .email('Wrong email format')
//   .min(3, 'Minimum 3 symbols')
//   .max(50, 'Maximum 50 symbols')
//   .required('Email is required'),
// })

export function RetrieveUsername() {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [invalid, setinvalid] = useState<undefined | boolean>(false);
  const [openconfirmPopup, setOpenconfirmPopup] = useState(false);

  const navigate = useNavigate()

  const APIKey = process.env.REACT_APP_SERVERAPI;

  // const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: forgotPasswordSchema,
  //   onSubmit: (values, {setStatus, setSubmitting}) => {
  //     setLoading(true)
  //     setHasErrors(undefined)
  //     setTimeout(() => {
  //       requestPassword(values.email)
  //         .then(({data: {result}}) => {
  //           setHasErrors(false)
  //           setLoading(false)
  //         })
  //         .catch(() => {
  //           setHasErrors(true)
  //           setLoading(false)
  //           setSubmitting(false)
  //           setStatus('The login detail is incorrect')
  //         })
  //     }, 1000)
  //   },
  // })

  const handleSubmit = async (e) => {

    e.preventDefault();
    try {
      setLoading(true)
      setinvalid(false)

      if (email) {

        let data = { email }

        const result = await axios.post(`${APIKey}/api/auth/shaleen/forgotUsername`, data);

        console.log("response : ", result);
        if (result) {
          setLoading(false)
          setOpenconfirmPopup(true)
        }
      }
      else {
        setError(true)
        setLoading(false);
      }

    }
    catch (error) {
      console.log(error);
      setLoading(false);
      setinvalid(true);
      
    }

  }


  const closeModal = () => {
    setOpenconfirmPopup(false);
    // navigate('/login')

  }

  useEffect(() => {

    if (openconfirmPopup) {
      const timeoutId = setTimeout(() => {
        closeModal();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [openconfirmPopup]);
  return (
    <div>
      <div className="card custom-card-width-login">
        <div className="card-body">
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={handleSubmit}
          >
            <div className='text-center mb-10'>
              {/* begin::Title */}
              {/* <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1> */}
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/ReyCLinicalLogoSvg.svg')}
                className='h-20px h-lg-30px app-sidebar-logo-default mb-6'
              />
              <h1 className='text-dark fw-bolder mb-6 title-center'>Retrieve your Username</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-500 fw-semibold fs-6 title-center'>
                Enter the email associated to your account and we'll send an email with your username
              </div>
              {/* end::Link */}
            </div>

            {/* begin::Title */}
            {invalid  && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}
            {openconfirmPopup  && (
        <div className='mb-lg-15 alert alert-success'>
          <div className='alert-text font-weight-bold'>
            <span>
          Username Recovery Email Sent Successfully. 
            </span>
          </div>
        </div>
      )}

            {/* {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )} */}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
              <input
                type='email'
                value={email}
                placeholder=''
                autoComplete='off'
                onChange={(e) => setEmail(e.target.value)}
                // {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  // {'is-invalid': formik.touched.email && formik.errors.email},
                  // {
                  //   'is-valid': formik.touched.email && !formik.errors.email,
                  // }
                )}
              />
              {error && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>Please enter an Email</span>
            </div>
          </div>
        )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            {
              loading ?
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                 <span></span> <div className="loader"></div>
                </div>
                :
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' >
                    <span className='indicator-label'>Submit</span>
                    {/* {loading && (
            <span className='indicator-progress'>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )} */}
                  </button>
                  <Link to='/login'>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-light'
                    // disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
            }
            {/* end::Form group */}
          </form>
        </div>
      </div>
      {/* <div className='image-back-forgot'>
<img  src={toAbsoluteUrl('/media/auth/backgoundoflogin.png')} className='image-back-width-forgot' />
</div> */}

      {/* {
        (openconfirmPopup) && (

          <Modal
            show={openconfirmPopup}
            // onHide={handelClose}
            className=''
            size='sm'
            centered
          >
            <Modal.Header className=' dialoge px-4 pt-3 pb-1 '>

              <div
                className='title-header'>
                Email Sent
              </div>

            </Modal.Header>
            <Modal.Body className='dialoge m-0 p-2'>

              <div className='card p-4'>
                <div className='mb-2'>

                  <span className='normalLater'>
                    Username Recovery Email Sent Successfully.
                  </span>
                </div>
                <div>
                  <span className='title-header'>

                  </span>
                </div>
              </div>

            </Modal.Body>
            <Modal.Footer
              className='dialoge p-1'
            ></Modal.Footer>
          </Modal>

        )
      } */}
    </div>
  )
}
