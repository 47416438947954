import React, { useEffect, useState } from 'react'
import SearchableDropdown from '../ReausableComponent/SearchableDropdown'
import { useNavigate, useParams } from 'react-router-dom'
import NameCompo from '../ReausableComponent/NameCompo'
import inputText from '../../../ReUsableComponent/ReyClinicalinputs.json'
import ModalImage from 'react-modal-image';

import ContactComponent from '../ReausableComponent/ContactComponent'
import AddressComponent from '../../../ReUsableComponent/AddressComponent'
import {
    ZipRegEx,
    EmailRegExp,
    clinicNameRegExp,
    AddressRegExp,
    nameRegEx,
    phoneNoRegexp,
    extNoRegExp
} from '../../../ReUsableComponent/regEx'
import axios from 'axios'
import ClinicValidation from './ClinicValidation'
import SearchableDropdownForGroupId from './SearchableDropdownForGroupId'
import { toast } from 'react-toastify'
import CancelConfirmation from '../../../ReUsableComponent/CancelConfirmation'

function UpdateClinic() {


    const APIkey = process.env.REACT_APP_SERVERAPI;


    const { id } = useParams();
    const navigate = useNavigate()

    const [cancelPopUp, setCancelPopUp] = useState<any>(false)


    const [groupOption, setGroupOption] = useState([])


    const [clinicName, setClinicName] = useState<string | undefined>('');
    const [status, setStatus] = useState()

    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [cityId, setCity] = useState();
    const [stateId, setState] = useState();
    const [countryId, setCountry] = useState();
    const [zipcodeId, setZipcode] = useState<string | undefined>();

    const [prefixId, setprefix] = useState();
    const [firstName, setfirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setmiddleName] = useState('');
    const [suffixId, setsuffix] = useState();
    const [email, setEmail] = useState('');
    const [contactNumber1, setcellNumber] = useState();
    const [countryCode1, setcountryCode1] = useState();

    const [contactNumber2, setworkNumber] = useState();
    const [countryCode2, setcountryCode2] = useState();

    const [groupId, setgroupId] = useState();
    const [ext, setExt] = useState();
    const [referenceId, setReferby] = useState();

    const [logoDetails, setLogoDetails] = useState<any>();

    const [clinicWebsite, setClinicWebsite] = useState<any>();
    const [clinicLogo, setClinicLogo] = useState<any>();





    //error Msg ,, 
    const [errClinicName, setErrClinicName] = useState('')
    const [errAddressLine1, setErrAddressLine1] = useState('')
    const [errAddressLine2, setErrAddressLine2] = useState('')
    const [errCity, setErrCity] = useState('')
    const [errState, setErrState] = useState('')
    const [errcountry, setErrcountry] = useState('')
    const [errZipcode, setErrZipcode] = useState('')
    const [errLastName, setErrLastName] = useState('')
    const [errFirstName, setErrFirstName] = useState('')
    const [errMiddleName, setErrMiddleName] = useState('')
    const [errEmail, setErrEmail] = useState('')
    const [errCellPhone, setErrCellPhone] = useState('')
    const [errWorkPhone, setErrWorkPhone] = useState('')
    const [errExt, setErrExt] = useState('')

    const [errLogo, setErrLogo] = useState('')
    const [errClinicWebsite, setErrClinicWebsite] = useState('')







    useEffect(() => {

        const getTheDatabyID = async (getbyId) => {
            try {
                let getloginInfo: string | null = localStorage.getItem('loginInfo')

                if (getloginInfo !== null) {

                    const Token = JSON.parse(getloginInfo).data.accessToken;
                    const header = {
                        headers: { 'x-access-token': Token }
                    }

                    const aClinicData = await axios.get(`${APIkey}/api/admin/getClinicById/${getbyId}`, header);

                    if (aClinicData) {
                        //   console.log("get the data ",aClinicData.data.data);
                        const Data = aClinicData?.data?.data
                        console.log(Data);


                        setStatus(Data.status)
                        setClinicName(Data.clinicName)
                        setAddressLine1(Data.addressLine1)
                        setAddressLine2(Data.addressLine2)
                        setCity(Data.cityId)
                        setState(Data.stateId)
                        setCountry(Data.countryId)
                        setZipcode(Data.zipcodeId)

                        setprefix(Data.prefixId)
                        setLastName(Data.lastName)
                        setfirstName(Data.firstName)
                        setmiddleName(Data.middleName)
                        setsuffix(Data?.suffixId)

                        setEmail(Data.email)
                        setcountryCode1(Data.countryCode1)
                        setcellNumber(Data.contactNumber1)
                        setcountryCode2(Data.countryCode2)
                        setworkNumber(Data.contactNumber2)
                        setExt(Data.ext)

                        setReferby(Data.referenceId)
                        setgroupId(Data.groupId)
                        setClinicLogo(Data.clinicLogo);
                        
                    }


                }
                else {
                    console.log("No login info found in localStorage");
                }
            }
            catch (err) {
                console.log(err);

            }
        }

        getTheDatabyID(id)
    }, [])

    useEffect(() => {
        const getGroup = async () => {

            try {
                let getloginInfo: string | null = localStorage.getItem('loginInfo')

                if (getloginInfo !== null) {

                    const Token = JSON.parse(getloginInfo).data.accessToken;
                    const Id = JSON.parse(getloginInfo).data.clinicId

                    const header = {
                        headers: { 'x-access-token': Token }
                    }

                    const groupList = await axios.get(`${APIkey}/api/admin/listClinic`, header);

                    setGroupOption(groupList.data.data.map(i => (
                        {
                            label: i.clinicName,
                            value: i.clinicName,
                            id: i._id
                        })))


                }
                else {
                    console.log("No login info found in localStorage");
                }
            }
            catch (err) {
                console.log(err);
            }


        }
        getGroup();

    }, [])



    const getClinicName = (e) => {
        setClinicName(e.target.value);
    }
    const getAddress1 = (e) => {
        setAddressLine1(e);


    }
    const getAddress2 = (e) => {
        setAddressLine2(e);
    }

    const getCity = (e) => {
        setCity(e);

    }
    const getState = (e) => {
        setState(e);

    }

    const getCountry = (e) => {
        setCountry(e)
    }
    const getZipcode = (e) => {
        setZipcode(e)
    }




    const getprefix = (e) => {
        setprefix(e);
    }

    const getFirstName = (e) => {
        setfirstName(e);
    }
    const getLastName = (e) => {
        setLastName(e);
    }
    const getMiddleName = (e) => {
        setmiddleName(e);
    }

    const getsuffix = (e) => {
        setsuffix(e);
    }

    const getTheEmail = (e) => {
        setEmail(e.target.value);
    }

    const getCellNumber = (e) => {
        setcellNumber(e.getTheNumber);
        setcountryCode1(e.gettheCode)


    }

    const getWorkNumber = (e) => {
        setworkNumber(e.getTheNumber);
        setcountryCode2(e.gettheCode)

    }

    const getExt = (e) => {
        setExt(e);


    }

    const getReferby = (e) => {
        setReferby(e)
    }
    const getGroupId = (e) => {
        setgroupId(e)
    }


    const handleLogoChange = (e) => {
        const file = e.target.files[0];


        setLogoDetails(file);
        if (file) {
            const reader = new FileReader();

            reader?.readAsDataURL(file);
            reader.onloadend = () => {
                setClinicLogo(reader?.result);
            };
        }
    }



    const option = [
        {
            label: "Please Select ",
            value: "Please Select",
            id: '65cf2ac3109643668a9f67f4'
        }
    ]




    const editClinic = async (data, id) => {

        console.log(data);



        try {
            let getloginInfo: string | null = localStorage.getItem('loginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;
                const header = {
                    headers: { 'x-access-token': Token }
                }
                const response = await axios.post(`${APIkey}/api/admin/updateClinicById/${id}`, data, header);
                console.log(response);

                if (response) {

                    navigate("/clinics")

                    toast.success('Clinic updated successfully!', {
                        position: 'top-right'
                    });
                }
            }
            else {
                console.log("No login info found in localStorage");
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    const domainName = (inputSting) => {

        if (inputSting != null) {
            const lowercasedString = inputSting.toLowerCase();

            const modifiedString = lowercasedString.split(' ')

            return modifiedString[0]
        }
    }


    const saveData = () => {

        let listofData = {
            clinicName,
            status,
            addressLine1,
            addressLine2,
            countryId,
            stateId,
            cityId,
            zipcodeId,
            prefixId,
            lastName,
            firstName,
            middleName,
            suffixId,
            countryCode1,
            contactNumber1,
            countryCode2,
            contactNumber2,
            email,
            ext,
            referenceId,
            groupId,
            domain: domainName(clinicName) ,  
            clinicLogo,
            clinicWebsite,
            logoDetails, 
            editSide : true
        }

        console.log(listofData);


        let err = ClinicValidation(listofData);

        console.log(err);


        if (Object.keys(err).length === 0) {



            editClinic(listofData, id);

            console.log(" form submitted Successfully ...");



            setErrClinicName(err.clinicName)

            setErrAddressLine1(err.addressLine1)
            setErrAddressLine2(err.addressLine2)
            setErrcountry(err.countryId)
            setErrState(err.stateId)
            setErrCity(err.cityId)
            setErrZipcode(err.zipcodeId)

            setErrLastName(err.lastName)
            setErrFirstName(err.firstName)
            setErrMiddleName(err.middleName)
            setErrEmail(err.email)
            setErrCellPhone(err.contactNumber1)
            setErrWorkPhone(err.contactNumber2)
            setErrExt(err.ext)
            setErrLogo(err.logoErr)
            setErrClinicWebsite(err.websiteErr)
        }
        else {
            setErrClinicName(err.clinicName)

            setErrAddressLine1(err.addressLine1)
            setErrAddressLine2(err.addressLine2)
            setErrcountry(err.countryId)
            setErrState(err.stateId)
            setErrCity(err.cityId)
            setErrZipcode(err.zipcodeId)

            setErrLastName(err.lastName)
            setErrFirstName(err.firstName)
            setErrMiddleName(err.middleName)
            setErrEmail(err.email)
            setErrCellPhone(err.contactNumber1)
            setErrWorkPhone(err.contactNumber2)
            setErrExt(err.ext)
            setErrLogo(err.logoErr)
            setErrClinicWebsite(err.websiteErr)
        }


    }


    console.log(suffixId);



    const statusList = [
        {
            label: 'Active',
            value: 'Active',
            id: 1
        },
        {
            label: 'Inactive',
            value: 'Inactive',
            id: 0
        }
        // ,
        // {
        //     label: 'Deleted',
        //     value: 'Deleted',
        //     id: 2
        // },
    ]


    const getThestatus = (e) => {
        setStatus(e)


    }

    const closeCancel = () => {
        setCancelPopUp(false)
    }





    return (
        <>
            <div className='px-4'>
                <div className='PageHeader'>
                    <div className='PageHeaderTitle'>
                        <span>
                            Edit Clinic
                        </span>
                    </div>
                </div>
                <div className='card mt-3  p-3'>
                    <div>

                        <div className='PageHeader'>
                            <div className='fs-3 fw-bold'>
                                <span>
                                    Clinic Information:
                                </span>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-6  col-lg-4'>
                                <label htmlFor="" className='patient-info-title required'>{inputText.ClinicName.label}</label>
                                <input type="text"
                                    className='form-control SearchInputbox'
                                    value={clinicName}
                                    onChange={getClinicName}
                                    readOnly
                                />
                                {
                                    errClinicName &&

                                    <span className='form-label errMsg'>
                                        {/* {inputText.ClinicName.ErrMsg} */}
                                        {errClinicName}
                                    </span>}
                            </div>
                            <div className='col-md-6  col-lg-4  '>

                                <div className={clinicLogo ? 'd-flex justify-content-between  gap-2' : "w-100"}>
                                    <div className={clinicLogo ? 'w-75' : "w-100"}>
                                        <label htmlFor="" className='form-lable patient-info-title required'>Clinic Logo:</label>
                                        <label className={errLogo ? "uploadErrbtn  d-flex align-items-center justify-content-center patient-info-title" : ' uploadbtn  d-flex align-items-center justify-content-center patient-info-title'} >
                                            {logoDetails ?
                                                <div className=' d-flex align-items-center justify-content-center gap-2'>
                                                    <label htmlFor="upload-photo1" style={{ color: "#0027FF", textDecoration: "underLine", cursor: "pointer" }}>
                                                        browse
                                                    </label>
                                                    {logoDetails?.name}

                                                </div>
                                                :
                                                <div className=''>
                                                    <i className="fa fa-cloud-upload mx-2" aria-hidden="true"></i>
                                                    Upload Your Clinic Logo &nbsp;
                                                    <label htmlFor="upload-photo1" style={{ color: "#0027FF", textDecoration: "underLine", cursor: "pointer" }}>
                                                        browse
                                                    </label>
                                                </div>

                                            }
                                            <input
                                                type="file"
                                                onChange={handleLogoChange}
                                                id='upload-photo1' accept="image/*" style={{ display: 'none' }} />
                                        </label>
                                    </div>
                                    {

                                        clinicLogo &&
                                        <div className='w-25 form-control '   >
                                            <ModalImage
                                                small={clinicLogo}
                                                large={clinicLogo}
                                                alt=""
                                                hideDownload={true} // Optionally hide download button
                                                hideZoom={true}     // Optionally hide zoom button
                                            />
                                        </div>
                                    }

                                </div>

                                {
                                    errLogo &&
                                    <span className='form-label errMsg'>
                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                        &nbsp;
                                        {errLogo}
                                    </span>
                                }





                            </div>


                            <div className='col-md-6 col-lg-4 '>
                                <label htmlFor="" className='patient-info-title '>Clinic Website:</label>
                                <input type="text"
                                    value={clinicWebsite}
                                    className='form-control SearchInputbox'
                                    maxLength={100}
                                    onChange={(e) => setClinicWebsite(e.target.value)}
                                />
                                {
                                    errClinicWebsite &&
                                    <span className='form-label errMsg'>
                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                        &nbsp;
                                        {errClinicWebsite}
                                    </span>
                                }
                            </div>
                        </div>


                        <div className='row '>
                            <div className='col-md-6 col-lg-4 mb-4'>
                                <label
                                    htmlFor=""
                                    className='patient-info-title '>Status:</label>
                                <SearchableDropdown
                                    placeHolder={inputText.ClinicName.placeholder}
                                    gettingData={getThestatus}
                                    InitialValue={status}
                                    options={statusList} />
                            </div>
                        </div>
                        <div className='row'>

                            <AddressComponent
                                col={'4'}
                                address1value={addressLine1}
                                address2value={addressLine2}
                                countryValue={countryId}
                                stateValue={stateId}
                                cityValue={cityId}
                                zipcodevalue={zipcodeId}

                                Address1get={getAddress1}
                                Address2get={getAddress2}
                                Cityget={getCity}
                                StateGet={getState}
                                countryget={getCountry}
                                zipcodeget={getZipcode}


                                address1Err={errAddressLine1}
                                address2Err={errAddressLine2}
                                cityErr={errCity}
                                stateErr={errState}
                                countryErr={errcountry}
                                zipErr={errZipcode}
                            />

                        </div>

                    </div>
                    <div>

                        <div className='PageHeader mt-3'>
                            <div className='fs-3 fw-bold'>
                                <span>
                                    Contact Person:
                                </span>
                            </div>
                        </div>

                        <div className='row'>

                            <NameCompo
                                colno={'4'}
                                prefixValue={prefixId}
                                lastNameValue={lastName}
                                firstNameValue={firstName}
                                middleNameValue={middleName}
                                suffixValue={suffixId}
                                getPrefixData={getprefix}
                                getSuffixData={getsuffix}
                                getfName={getFirstName}
                                getlName={getLastName}
                                getmName={getMiddleName}

                                lastNameErr={errLastName}
                                firstNameErr={errFirstName}
                                middleNameErr={errMiddleName}
                            />

                        </div>


                        <div className='row'>

                            <ContactComponent
                                col={'4'}
                                adminfield={1}
                                emailValue={email}
                                contactno1Value={`${countryCode1}${contactNumber1}`}
                                contactno2Value={`${countryCode2}${contactNumber2}`}
                                contactno3Value={''}
                                faxValue={''}


                                extValue={ext}

                                getTheEmail={getTheEmail}
                                getTheCellNumber={getCellNumber}
                                getTheWorkNumber={getWorkNumber}
                                getTheExt={getExt}

                                emailErr={errEmail}
                                cellNoErr={errCellPhone}
                                workNoErr={errWorkPhone}
                                extErr={errExt}

                            />
                        </div>


                        <div className='row mt-2'>
                            <div className='col-md-6  col-lg-4'>
                                <label htmlFor="" className='patient-info-title '>{inputText.referby.label}</label>
                                <SearchableDropdownForGroupId
                                    options={option}
                                    InitialValue={referenceId}
                                    gettingData={getReferby} />
                            </div>
                            <div className='col-md-6  col-lg-4'>
                                <label htmlFor="" className='patient-info-title '>{inputText.groupId.label}</label>
                                <SearchableDropdownForGroupId options={groupOption} InitialValue={groupId} gettingData={getGroupId} />
                            </div>
                        </div>




                        <div className='d-flex mt-2 justify-content-end'>
                            <div className='d-flex gap-2'>
                                <div>
                                    <button onClick={() => saveData()} className='btn btn-primary'>
                                        Save
                                    </button>
                                </div>
                                <div>
                                    <button
                                        onClick={() => setCancelPopUp(true)}
                                        className='btn btn-secondary '>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {

                cancelPopUp && (
                    // console.log(cancelPopUp);
                    <CancelConfirmation
                        openCancel={cancelPopUp}
                        closeCancel={closeCancel}
                        Routes='/clinics'
                    />
                )
            }
        </>
    )
}

export default UpdateClinic
