import React from 'react'
import Modal from 'react-bootstrap/Modal'

function DeleteModel({openDelete , closedelete , deleteID ,deleteFunction , deletedEntity}) {


    const  handleClosedelete =() => {
        closedelete()
    }

    const  hadleDelete = (id)=> {
            // console.log("this is handle Delete function ... ", id);
                    deleteFunction(id)
    }


  return (

    <Modal show={openDelete} onHide={handleClosedelete} size='lg' centered>
    <Modal.Header className='fs-2 fw-bold px-4 py-1 dialoge' >
     
    Delete Confirmation
    
    </Modal.Header>
    <Modal.Body className='fs-4 px-3 py-1 fw-bold dialoge' >
      <div className='card p-4 '>
    Are you sure you want to delete {deletedEntity} ?
      </div>
    </Modal.Body>

    <Modal.Footer className='p-2 dialoge'>
      <div className='d-flex gap-2 '>

      <button onClick={() => hadleDelete(deleteID)}  className='bigredButton' >
      Save
      </button>
      <button onClick={handleClosedelete} className='biggrayButton' >
        Close
      </button>
      </div>
    </Modal.Footer>
  </Modal>
  )
}

export default DeleteModel