import React from 'react'

function PatientTabFinancials() {
  return (
    <div className='card px-2 py-2'>
        <h1>
        Financials
        </h1>
        </div>
  )
}

export default PatientTabFinancials