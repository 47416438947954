import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import SearchableGroup from '../../../ReUsableComponent/SearchableGroup'
import axios from 'axios';
import CancelConfirmation from '../../../ReUsableComponent/CancelConfirmation';
import { check } from 'prettier';
import { actions } from 'react-table';


function ManagePermission() {

    const APIkey = process.env.REACT_APP_SERVERAPI;


    const { clinicId, userId } = useParams()

    const navigate = useNavigate();

    const [permissionList, setPermissionList] = useState<any>([])
    const [permissionUserList, setPermissionUserList] = useState([])

    const [permissionUserId, setpermissionUserId] = useState<any>()

    const [cancelPopUp, setCancelPopUp] = useState<any>(false)


    const [permissionData, setPermissionData] = useState<any>({})





    useEffect(() => {

        const getPermissionList = async () => {

            try {
                let getloginInfo: string | null = localStorage.getItem('loginInfo')

                if (getloginInfo !== null) {

                    const Token = JSON.parse(getloginInfo)?.data?.accessToken;
                    const header = {
                        headers: { 'x-access-token': Token }
                    }

                    const getpermissionList = await axios.get(`${APIkey}/api/admin/getPermissionList`, header);

                    setPermissionList(getpermissionList?.data?.data);

                    const getTheUserByID = await axios.get(`${APIkey}/api/admin/getUserById/${clinicId}/${userId}`, header);

                    setPermissionData(getTheUserByID?.data?.data?.permissions);


                    const withList = await axios.get(`${APIkey}/api/admin/getDesignationList/${clinicId}`, header);

                    const userList = await axios.get(`${APIkey}/api/admin/listUsersByClinicId/${clinicId}`, header)

                    setPermissionUserList(withList.data.data.map(i => (
                        {
                            label: i.designation,
                            options: userList.data.data.filter(j => j.designationId === i._id).map(j => {
                                return {

                                    value: `${j.firstName} ${j.lastName} - ${i.designation}`,
                                    label: `${j.firstName} ${j.lastName}`,
                                    Did: j.designationId,
                                    Uid: j._id
                                };
                            })
                        })))

                }
                else {
                    console.log("No login info found in localStorage");
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        getPermissionList();

    }, [])

    const closeCancel = () => {
        setCancelPopUp(false)
    }


    const getTheUser = (e) => {
        setpermissionUserId(e.Uid);
    }





    const handleSelectAllCheckboxChange = (action) => {


        const allChecked = permissionList.every((permission: any) =>
           permissionData &&  permissionData[permission.value]?.includes(action)
        );



        permissionList.forEach((permission: any) => {
            console.log(permission);

            const permissionId = permission.value;
            let currentPermission = permissionData[permissionId] || [];

            // If all checkboxes in the column are checked, remove the column from the permission's array
            // If any checkbox in the column is unchecked, add the column to the permission's array

            currentPermission = allChecked ? currentPermission?.filter((id: any) => id !== action) : currentPermission.includes(action) ? [...currentPermission] : [...currentPermission, action];

            setPermissionData((prevPerm: any) => ({ ...prevPerm, [permissionId]: currentPermission }))

        });


        // setPermissionData(updatedPermissionData);
    };






    const handleCheckChange = (module, action) => {
        // Update permission data state accordingly
        let updatedPermissionData = { ...permissionData };

        if (action === 4) {
            // If "Select All" checkbox is clicked

            if (updatedPermissionData[module]?.length === 4) {
                // If permissionData[module] already exists, clear it first
                delete updatedPermissionData[module];
            }
            else if (updatedPermissionData[module]) {
                const existingPermissions = updatedPermissionData[module];
                const allPermissions = [0, 1, 2, 3];
                const missingPermissions = allPermissions.filter(perm => !existingPermissions.includes(perm));
                updatedPermissionData[module] = [...existingPermissions, ...missingPermissions];
            }
            else {
                // Otherwise, set all permissions for the modul   
                updatedPermissionData[module] = [0, 1, 2, 3];
            }
        } else {
            // If individual permission checkbox is clicked
            if (updatedPermissionData[module]) {
                updatedPermissionData[module] = updatedPermissionData[module].includes(action) ?
                    updatedPermissionData[module].filter(item => item !== action) :
                    [...updatedPermissionData[module], action];
            } else {
                updatedPermissionData[module] = [action];
            }

        }

        setPermissionData(updatedPermissionData);
    };





    const isChecked = (action) => permissionList?.length > 0 ? permissionList.every((permission) => permissionData && permissionData[permission.value]?.includes(action)) : false



    const saveTheData = async () => {
        let data = { permissions: permissionData }
        try {
            let getloginInfo: string | null = localStorage.getItem('loginInfo')

            if (getloginInfo !== null) {

                const Token = JSON.parse(getloginInfo).data.accessToken;
                const header = {
                    headers: { 'x-access-token': Token }
                }

                const response = await axios.post(`${APIkey}/api/admin/updateUserById/${clinicId}/${userId}`, data, header);

                if (response) {

                    navigate('/user')

                    toast.success('User Permissions manage successfully!', {
                        position: 'top-right'
                    });
                }
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    return (
        <>

            <div className='px-5 fw-bold'>
                <div className='PageHeader '>
                    <div className='PageHeaderTitle'>
                        <span className='' >
                            Manage Permission
                        </span>
                    </div>
                </div>
                <div className='card mt-3 p-3'>

                    <div className='row  align-items-center  p-4'>
                        <div className=' col-lg-2 col-md-6 col-sm-12'>

                            <input
                                type="checkbox"
                                id='copy'
                            />
                            &nbsp;
                            <label htmlFor='copy' className='patient-info-title'>
                                Copy permission of user
                            </label>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div>
                                <SearchableGroup
                                    InitialValue={permissionUserId}
                                    gettingData={getTheUser}
                                    options={permissionUserList}
                                />
                            </div>

                        </div>

                    </div>


                    <div className='p-4'>
                        <table className='w-100 fullCurve'>
                            <thead className=''>
                                <tr className='tableHead'>
                                    <th>&nbsp; Modules</th>
                                    <th>All</th>
                                    <th>View</th>
                                    <th>Add</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className='tableBody'>
                                    <td></td>
                                    <td className='' >
                                        {/* <div className='d-flex align-items-center gap-1'>
                                            <input
                                                type="checkbox"
                                                onChange={() => handleSelectAllCheckboxChange(4)}
                                                name=""
                                                
                                                id="all"
                                            /><label className='patient-info-title' htmlFor='all'>Select All</label>

                                        </div> */}
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center gap-1'>
                                            <input
                                                type="checkbox"
                                                name=""
                                                checked={isChecked(0)}
                                                onChange={() => handleSelectAllCheckboxChange(0)}
                                                id="view" /><label className='patient-info-title' htmlFor='view'>Select All</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center gap-1'>
                                            <input
                                                onChange={() => handleSelectAllCheckboxChange(1)}
                                                type="checkbox"
                                                checked={isChecked(1)}
                                                name="" id="add" /><label className='patient-info-title' htmlFor='add'>Select All</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center gap-1'>
                                            <input
                                                onChange={() => handleSelectAllCheckboxChange(2)}
                                                type="checkbox"
                                                checked={isChecked(2)}
                                                name="" id="edit" /><label className='patient-info-title' htmlFor='edit'>Select All</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center gap-1'>
                                            <input
                                                onChange={() => handleSelectAllCheckboxChange(3)}
                                                type="checkbox"
                                                checked={isChecked(3)}
                                                name="" id="delete" /><label className='patient-info-title' htmlFor='delete'>Select All</label>
                                        </div>
                                    </td>
                                </tr>
                                {
                                    permissionList?.map((i: any) => (
                                        <tr key={i?._id} className='tableBody'>
                                            <td> &nbsp; {i?.name}</td>
                                            <td className=''>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <input
                                                        type="checkbox"
                                                        name={`all_${i.value}`}
                                                        id={`all_${i.value}`}
                                                        checked={permissionData[i.value]?.includes(0) &&
                                                            permissionData[i.value]?.includes(1) &&

                                                            permissionData[i.value]?.includes(2) &&
                                                            permissionData[i.value]?.includes(3)}
                                                        onChange={() => handleCheckChange(i.value, 4)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <input
                                                        type="checkbox"
                                                        name={`view_${i.value}`}
                                                        id={`view_${i.value}`}
                                                        checked={permissionData[i.value]?.includes(0)}
                                                        onChange={() => handleCheckChange(i.value, 0)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <input
                                                        type="checkbox"
                                                        name={`add_${i.value}`}
                                                        id={`add_${i.value}`}
                                                        checked={permissionData[i.value]?.includes(1)}
                                                        onChange={() => handleCheckChange(i.value, 1)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <input
                                                        type="checkbox"
                                                        name={`edit_${i.value}`}
                                                        id={`edit_${i.value}`}
                                                        checked={permissionData[i.value]?.includes(2)}
                                                        onChange={() => handleCheckChange(i.value, 2)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center gap-1'>
                                                    <input
                                                        type="checkbox"
                                                        name={`delete_${i.value}`}
                                                        id={`delete_${i.value}`}
                                                        checked={permissionData[i.value]?.includes(3)}
                                                        onChange={() => handleCheckChange(i.value, 3)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>

                    <div className='d-flex mt-3 justify-content-end p-4'>
                        <div className='d-flex gap-2'>
                            <div>
                                <button
                                    onClick={saveTheData}
                                    className='btn btn-primary'>
                                    Save
                                </button>
                            </div>

                            <div>
                                <button
                                    onClick={() => setCancelPopUp(true)}
                                    className='btn btn-secondary'>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

                <ToastContainer />

            </div>

            {

                cancelPopUp && (
                    <CancelConfirmation
                        openCancel={cancelPopUp}
                        closeCancel={closeCancel}
                        Routes='/user'
                    />
                )
            }
        </>
    )
}

export default ManagePermission 