import { EmailRegExp, SSNRegExp, ZipRegEx, extNoRegExp, nameRegEx, phoneNoRegexp } from './regEx';
import inputText from '../ReUsableComponent/ReyClinicalinputs.json'

function validation(value: any): { [key: string]: string } {
  const errors: { [key: string]: string } = {};

  // console.log(value);
  


  if (!value.lastName || value.lastName.trim() === '') {
    errors.lastName = inputText.lastName.ErrMsg ;
  }else{
    // if(!nameRegEx.test(value.lastName)){
    //     errors.lastName = 'Please enter valid last name';
    // }
    // else 
    if(value.lastName.length >= 51){
        errors.lastName =  inputText.lastName.limitErrMsg
    }
  }


  if (!value.firstName || value.firstName.trim() === '') {
    errors.firstName = inputText.firstName.ErrMsg;
  }else{
    // if(!nameRegEx.test(value.firstName)){
    //     errors.firstName = 'Please enter valid first name';
    // }
    // else 
    if(value.firstName.length >= 51){
        errors.firstName =inputText.firstName.limitErrMsg
    }
  }

  // if (value.middleName && value.middleName.length >= 51) {
  //   errors.middleName = 'Please enter a middle name less than or equal to 50 characters';
  // }


  
  

  if(!value.ssn || value.ssn.trim() === '' ){
    errors.ssn = inputText.SSN.ErrMsg ;
  }
  else{
    if(!SSNRegExp.test(value.ssn)){
    errors.ssn = inputText.SSN.validErrMsg;
    }
  }


  if (!value.genderId) {
        errors.gender = inputText.Sex.ErrMsg
  }
  if (!value.dob) {
        errors.dob = inputText.dateOfBirth.ErrMsg
  }


  

  if (!value.addressLine1 || value.addressLine1.trim() === '') {
    errors.addressLine1 = inputText.Address1.ErrMsg ;
  }
  // else{
  // if(value.addressLine1.length >= 101){
  //       errors.addressLine1 = 'Please enter address line 1 less then or equal to 100 characters'
  //   }
  // }

  // if (value.addressLine2 && value.addressLine2.length >= 101) {
  //   errors.addressLine2 = 'Please enter address line 2 less than or equal to 100 characters';
  // }


  if (!value.countryId) {
    errors.countryId = inputText.country.ErrMsg
}
  if (!value.stateId) {
    errors.stateId = inputText.state.ErrMsg
}
  if (!value.cityId) {
    errors.cityId = inputText.city.ErrMsg
}



  
if(!value.zipcodeId || value.zipcodeId.trim() === '' ){
    errors.zipcodeId = inputText.zip.ErrMsg ;
  }
  else{
    if(value.zipcodeId.length <= 4){
    errors.zipcodeId = inputText.zip.validErrMsg ;
    }
  }


  
if(!value.emailAddress || value.emailAddress.trim() === '' ){
    errors.emailAddress = inputText.email.ErrMsg ;
  }
  else{
    if(!EmailRegExp.test(value.emailAddress)){
    errors.emailAddress = inputText.email.validErrMsg ;
    }
    // else if(value.emailAddress.length >= 251){
    //     errors.emailAddress = 'Please enter email less than or equal to 251 characters';

    // }
  } 




if(!value.mobileNo || value.mobileNo.trim() === '' ){
    errors.mobileNo = inputText.cellNo.ErrMsg ;
  }
  else{
    if(!phoneNoRegexp.test(`${value.mobileCountryCode}${value.mobileNo}`)){
    errors.mobileNo = inputText.cellNo.validErrMsg ;
    }
   
  }




  if (value.homeNo && !phoneNoRegexp.test(`${value.homeCountryCode}${value.homeNo}`)) {
    errors.homeNo = inputText.homeNo.validErrMsg ;
    }
   
  if (value.workPhoneNo && !phoneNoRegexp.test(`${value.workCountryCode}${value.workPhoneNo}`)) {
    errors.workPhoneNo = inputText.WorkPhone.ErrMsg;
    }
   
  
  // if (value.workExtNo && value.workExtNo <=2 ) {
  //   errors.workExtNo = inputText.ex.ErrMsg ;
  //   }
   
  



  // Add more validation rules as needed

  return errors;
}

export default validation;